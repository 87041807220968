import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import ENV from './ENV';
import axios from 'axios';

const AdminPayoutReport = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [payoutlist, setPayoutList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("get_payouts_reports", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])

    const calculate = (num, _num) => {
        return (num + _num)
    }


    useEffect(() => {

    }, [])

    
    const OutStadingPayouts = payoutlist?.reduce((amount, item) => amount = (Number(item.dincome) + Number(item.uincome) + Number(item.mincome)) + amount, 0).toFixed(2);
    const TotalPayouts = payoutlist?.reduce((amount, item) => amount = (Number(item.pamount)) + amount, 0).toFixed(2);
    const TotalPayoutFee = payoutlist?.reduce((amount, item) => amount = (Number(item.fee)) + amount, 0).toFixed(2);

    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Payout Reports</h1>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Main row */}
                        <div className="row">
                            <div className='col-sm-12'>
                                <div style={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 10, gap: 10}}>
                                    <div style={{flex: 1, padding: 5, borderRadius: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#17a2b8", color: "#fff", fontSize: 20}}>
                                        <span>Outstanding Payout Balance</span>
                                        <span>${Number(OutStadingPayouts-TotalPayouts).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{flex: 1, padding: 5, borderRadius: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "rgb(230, 151, 82)", color: "#fff", fontSize: 20}}>
                                        <span>Total Service Fee Collections</span>
                                        <span>${Number(TotalPayoutFee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{flex: 1, padding: 5, borderRadius: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#dc3545", color: "#fff", fontSize: 20}}>
                                        <span>Total Payouts</span>
                                        <span>${Number(TotalPayouts).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Fullname</th>
                                                <th>Earning</th>
                                                <th>Total Payout</th>
                                                <th>Last Payout</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                [...payoutlist].map((item, i)=>{
                                                    if ((Number(item.dincome) + Number(item.uincome) + Number(item.mincome)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") > 0) {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{item.user_id}</td>
                                                                <td>{item.fullname}</td>
                                                                <td>${(Number(item.dincome) + Number(item.uincome) + Number(item.mincome)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                <td style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                                                    <span>${Number(item.pamount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                    <span style={{fontSize: 12}}>Fee: ${Number(item.fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item?.listpayout?.map((items, index, array)=>{
                                                                            if (array.length - 1 === index) {
                                                                                return <div style={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>${Number(items.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                                            }
                                                                        })
                                                                    }
                                                                </td>
                                                                <td>${((Number(item.dincome) + Number(item.uincome) + Number(item.mincome))-(Number(item.pamount)+Number(item.fee))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {/* {
                                        payoutlist.map((item) => {
                                            return (
                                                <div style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection:"column", width: "100%", border:"1px solid #ddd" }}>
                                                    <div style={{width:"100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border:"1px solid #ddd" }}>
                                                        <div style={{width:"100%", display:"flex", flexDirection:"column" }}>
                                                            <div style={{ width: null, padding:5, fontWeight:600, backgroundColor:"#0092b9", color:"#fff" }}>Date Of {item.date_approved}</div>
                                                            {
                                                                item?.list?.map((_item)=>{
                                                                    return (
                                                                        <div style={{padding:5, width:"100%", display:"flex", flexDirection:"column", fontSize:12, justifyContent:"flex-start", border:"1px solid #ddd"}}>
                                                                            <div>{_item.date_payout} - {_item.date_approveds}</div>
                                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:10}}>
                                                                                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:0}}>
                                                                                    <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                        <div style={{fontWeight:"bold", fontSize:18}}>{_item.payout_method}</div>
                                                                                    </div>
                                                                                    <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                        <div>[{_item.user_id}]</div>
                                                                                        <div><span style={{fontWeight:"bold", textDecoration:"underline"}}>{_item.fullname}</span></div>
                                                                                    </div>
                                                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                                                        <div>Account Name: <span style={{fontWeight:"bold"}}>{_item.account_name}</span></div>
                                                                                        <div>Account Number: <span style={{fontWeight:"bold"}}>{_item.account_number}</span></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:10}}>
                                                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                                                                                        <div>Amount: <span style={{fontWeight:"bold"}}>${_item.amount}</span></div>
                                                                                        <div>tax: <span style={{fontWeight:"bold"}}>${_item.fee}</span></div>
                                                                                        <div style={{textDecoration:"underline"}}>Sub-Total: <span style={{fontWeight:"bold"}}>${Number(Number(_item.amount)+Number(_item.fee)).toFixed(2)}</span></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }                                            
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                        <div></div>
                                                        <div style={{padding:5, display:"flex", flexDirection:"column"}}>
                                                            <span style={{fontWeight:"bold", fontSize:12}}>Total Payout: ${item.totalAmount}</span>
                                                            <span style={{fontWeight:"bold", fontSize:12}}>Total Tax: ${item.totalFee}</span>
                                                            <span style={{fontWeight:"bold", textDecoration:"underline"}}>Overall Payout: ${item.overall}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    } */}
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );
}

export default AdminPayoutReport;
