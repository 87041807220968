import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import ENV from './ENV';
import axios from 'axios';

const AdminPayinSummary = (props) => {
    // console.log(props.userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [payinlist, setPayinList] = useState([])
    const [cashinlist, setCashinList] = useState([])


    const payout_request_list = async () => {

        var formData = new FormData();
        formData.append("payin_summary", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayinList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }

    useMemo(async () => {
        if (props.type !== 'cashin') {
            var formData = new FormData();
            formData.append("payin_summary", 1);
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                setPayinList(res.data)
                console.log(res.data)
            }).catch((err) => {

            });
        }
    }, [ENV, props.type])

    useMemo(async () => {
        if (props.type === 'cashin') {
            var formData = new FormData();
            formData.append("CashinList", 1);
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                // url: 'http://192.168.1.13/drinkgood/process.php',
                data: formData,
            }).then((res) => {
                setCashinList(res.data)
                console.log(res.data)
            }).catch((err) => {
    
            });
        }
    }, [ENV, props.type])





    const CancelRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("cancel_payout_request", 1);
        formData.append("user_id", item.user_id);
        formData.append("id", item.id);
        formData.append("amount", item.amount);
        formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            payout_request_list()
            // setBalance(res.data[0].balance)
            // payout_list()
        }).catch((err) => {

        });

    }

    const ApprovedRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("approve_payout_request", 1);
        formData.append("user_id", item.user_id);
        formData.append("id", item.id);
        formData.append("ref_number", null);
        // formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            payout_request_list()
            // setBalance(res.data[0].balance)
            // payout_list()
        }).catch((err) => {

        });
    }

    const calculate = (num, _num) => {
        return (num + _num)
    }


    useEffect(() => {

    }, [])

    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                {
                                    props.type === 'cashin' ?
                                    <h1 className="m-0">Cash-in</h1>
                                    :
                                    <h1 className="m-0">Pay-in Summary</h1>
                                }
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Main row */}
                        <div className="row">
                            <div className='col-sm-12'>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                    {
                                        props.type === 'cashin' ?
                                        <div style={{ flex: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{flex: 1, width: "100%"}}>
                                                {
                                                    cashinlist.filter((i)=>i.type === 'cashin' && i.status === 'pending').map((item) => {
                                                        return (
                                                            <div style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection: "column", width: "100%", border: "1px solid #ddd" }}>
                                                                <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border: "1px solid #ddd" }}>
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                                        <div style={{ width: null, padding: 5, fontWeight: 600, backgroundColor: "#0092b9", color: "#fff" }}>{item.date_payout}</div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div style={{ flex: 1, padding: 5}}>
                                                                        <div>Name: {item.fullname}</div>
                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                            <div>Amount</div>
                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 20 }}>
                                                                                <div style={{width: 200}}>
                                                                                    <div style={{fontWeight: "bolder"}}>USD {Number(item.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                                                    <div style={{fontWeight: "bolder"}}>PHP {Number(item.amount*item.rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                                                </div>
                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                                                    <span>Proof of Payment</span>
                                                                                    <a href={item.selfie_image}>Show Picture</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ padding: 5, display: "flex", flexDirection: "column", gap: 5 }}>
                                                                        <button onClick={async () => {

                                                                            var formData = new FormData();
                                                                            formData.append("cashin_process", 1);
                                                                            formData.append("value", JSON.stringify({ ...item, status: "declined" }));

                                                                            await axios({
                                                                                method: "post",
                                                                                url: ENV.DOMAIN + 'process.php',
                                                                                // url: 'http://192.168.1.13/drinkgood/process.php',
                                                                                data: formData,
                                                                            }).then((res) => {
                                                                                console.log(res.data)
                                                                            }).catch((err) => {

                                                                            });
                                                                        }} className='btn btn-danger'>Decline</button>

                                                                        <button onClick={async()=>{
                                                                            var formData = new FormData();
                                                                            formData.append("cashin_process", 1);
                                                                            formData.append("value", JSON.stringify({...item, status: "approved"}));

                                                                            await axios({
                                                                                method: "post",
                                                                                url: ENV.DOMAIN + 'process.php',
                                                                                // url: 'http://192.168.1.13/drinkgood/process.php',
                                                                                data: formData,
                                                                            }).then((res) => {
                                                                                alert(JSON.stringify(res.data))
                                                                                console.log(res.data)
                                                                            }).catch((err) => {

                                                                            });
                                                                        }} className='btn btn-success'>Approve</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div style={{flex: 1, width: "100%"}}>
                                                {
                                                    cashinlist.filter((i)=>i.type === 'cashin' && i.status === 'approved').map((item) => {
                                                        return (
                                                            <div style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection: "column", width: "100%", border: "1px solid #ddd" }}>
                                                                <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border: "1px solid #ddd" }}>
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                                        <div style={{ width: null, padding: 5, fontWeight: 600, backgroundColor: "#0092b9", color: "#fff" }}>{item.date_payout}</div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div style={{ flex: 1, padding: 5}}>
                                                                        <div>Name: {item.fullname}</div>
                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                            <div>Amount</div>
                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 20 }}>
                                                                                <div style={{width: 200}}>
                                                                                    <div style={{fontWeight: "bolder"}}>USD {Number(item.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                                                    <div style={{fontWeight: "bolder"}}>PHP {Number(item.amount*item.rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                                                </div>
                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                                                    <span>Proof of Payment</span>
                                                                                    <a href={item.selfie_image}>Show Picture</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                            :
                                            payinlist.map((item) => {
                                                // if (item.status == 'approved') {
                                                return (
                                                    <div style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection: "column", width: "100%", border: "1px solid #ddd" }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border: "1px solid #ddd" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                                <div style={{ width: null, padding: 5, fontWeight: 600, backgroundColor: "#0092b9", color: "#fff" }}>Date Of {item.date_used}</div>
                                                                {
                                                                    item?.list?.map((_item) => {
                                                                        return (
                                                                            <div style={{ padding: 5, width: "100%", display: "flex", flexDirection: "column", fontSize: 12, justifyContent: "flex-start", border: "1px solid #ddd" }}>
                                                                                <div>{_item.date_used}</div>
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                                                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 0 }}>
                                                                                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                                                            <div>[{_item.user_id}]</div>
                                                                                            <div><span style={{ fontWeight: "bold", textDecoration: "underline" }}>{_item.fullname}</span></div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                                                            <div>[{_item.sponsorid}]</div>
                                                                                            <div><span style={{ fontWeight: "bold", textDecoration: "underline" }}>{_item.sponsorname}</span> (Sponsored By)</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                                                            <div>[{_item.userid_activate}]</div>
                                                                                            <div><span style={{ fontWeight: "bold", textDecoration: "underline" }}>{_item.username_activate}</span> (Activated By)</div>
                                                                                        </div>
                                                                                        {/* <div style={{display:"flex", flexDirection:"column"}}>
                                                                                            <div>Account Name: <span style={{fontWeight:"bold"}}>{_item.fullname}</span></div>
                                                                                        </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <div></div>
                                                            <div style={{ padding: 5, display: "flex", flexDirection: "column" }}>
                                                                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Overall Payin: ${item.overall}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                // }
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );
}

export default AdminPayinSummary;
