import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';
import CodeUseFor from './CodeUseFor';
import { useHistory, useParams, Link } from 'react-router-dom/cjs/react-router-dom';

const AvailableCode = (props) => {

    const [visible, setVisible] = useState(false);
    
    const [status, setStatus] = useState('open')

    const [search, setSearch] = useState('')
    const SearchData = (data, value, status)  => {
        if (value!='') {
            data = data.filter((i) => (i?.code.toLowerCase().includes(value.trim().toLowerCase())) && (i?.owner_id == localStorage.getItem('user_id')));
        } else {
            data = data.filter((i) => (i?.status == status) && (i?.owner_id == localStorage.getItem('user_id')));
        }
        return data;
    }
    
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')

    useMemo(()=>{
        if (props.type === 'activation') {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [props])

    return (
        <>
            <Sidebar
            icons={
                <div style={{width: '100%', fontSize: 20, fontWeight: "bolder"}}>Activation</div>
            }
             visible={visible} blockScroll={true} showCloseIcon={true} position="bottom" style={{ minHeight: 500, borderRadius: 0 }} onHide={() => props.history.goBack()}>
                <div>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", gap:5, alignItems:"center", padding:10}}>
                        <button onClick={()=>setStatus('open')} className='btn btn-info btn-xs'>Available</button>
                        <button onClick={()=>setStatus('close')} className='btn btn-danger btn-xs'>Used</button>
                    </div>
                    {
                        props.codelist.length > 0 ?
                            <>
                                {
                                    SearchData(props.codelist, search, status).map((item, key) => {
                                        if (item.status == 'open') {
                                            return (
                                                <div key={key} style={{display:"flex", flexDirection:"row", justifyContent:"space-between", backgroundColor: item.hold == 1 ? "#f0d7aa" : "", alignItems:"center", padding:5, border:"1px solid #ddd", borderLeft:"0px", borderRight:"0px"}}>
                                                    <div>
                                                        {/* <div>
                                                            <span style={{fontSize:10, fontWeight:500, color:item.paid == 0 ? "red" : "green", }}>
                                                                {item.paid == 0 ? "Unpaid" : "Paid"}
                                                            </span>
                                                        </div> */}
                                                        <div>
                                                            <span style={{fontSize:10, fontWeight:500, fontSize: 15}}>
                                                                {item.amount == '70.69091' ? "Php 3,888" : null}
                                                                {item.amount == '36.14545' ? "Php 1,988" : null}
                                                            </span>
                                                        </div>
                                                        <div style={{fontSize:18, fontWeight:800}}>{item.code}</div>
                                                        <div style={{fontSize:12}}>{item.date_time}</div>
                                                    </div>
                                                    <div>
                                                        {
                                                            item.hold == 0 ?
                                                            <CodeUseFor item={item} userInfo={props.userInfo} loadCodeList={props.loadCodeList} />
                                                            :
                                                            <button style={{border:"none", border:"1px solid #ddd", borderRadius:5, backgroundColor:"#de783d"}} className='btn btn-sm btn-info'>Hold</button>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", padding:5, border:"1px solid #ddd", borderLeft:"0px", borderRight:"0px"}}>
                                                    <div>
                                                        {/* <div>
                                                            <span style={{fontSize:10, fontWeight:500, color:item.paid == 0 ? "red" : "green", }}>
                                                                {item.paid == 0 ? "Unpaid" : "Paid"}
                                                            </span>
                                                        </div> */}
                                                        <div>
                                                            <span style={{fontSize:10, fontWeight:500, fontSize: 15}}>
                                                                {item.amount == '70.69091' ? "Php 3,888" : null}
                                                                {item.amount == '36.14545' ? "Php 1,988" : null}
                                                            </span>
                                                        </div>
                                                        <div style={{fontSize:18, fontWeight:800, textDecoration:"line-through"}}>{item.code}</div>
                                                        <div style={{fontSize:12}}>Name: <span style={{fontWeight:500}}>{item.fullname}</span></div>
                                                        <div style={{fontSize:12}}>Used: {item.date_used}</div>
                                                        <div style={{fontSize:12}}>{item.date_time}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </>
                            :
                            <>Loading...</>
                    }
                </div>
                {/* <div style={{position:"fixed", bottom:0, width:"100%", height:40}}>
                    <button onClick={()=>setVisible(false)}>Close</button>
                </div> */}
            </Sidebar>
            {
                [...props.codelist].filter((i) => i.owner_id === localStorage.getItem('user_id')).map((i) => i).slice(0, 10).length > 0 ?
                    props.type === 'activation' ?
                        <Link style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12, borderRight: "1px solid #ddd" }}>
                            <span className='pi pi-bolt'></span>
                            <span>Activation</span>
                        </Link>
                        :
                        <Link to={"/profile/code/activation"} style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12, borderRight: "1px solid #ddd" }}>
                            <span className='pi pi-bolt'></span>
                            <span>Activation</span>
                        </Link>
                    // <div onClick={() => setVisible(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                    //     Activation <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span style={{fontSize:12, color:"#17a2b8"}}>{SearchData(codelist, search, 'open').length}</span><span className='pi pi-chevron-right'></span></span>
                    // </div>
                    :
                    <span style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12, borderRight: "1px solid #ddd" }}>Unavailable</span>
            }
        </>
    );
}

export default AvailableCode;
