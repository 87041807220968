import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import CopyToClipboard from 'react-copy-to-clipboard';
import MatrixMenu from './extra/MatrixMenu';
import PUCApplication from './component/pickupcenter/PUCApplication';
import MatrixCodeDorm from './component/pickupcenter/MatrixCodeForm';
import LeftNavSwiper from './component/swipe/LeftNavSwiper';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

import { FaShoppingCart } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { FaAlignJustify } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegThumbsUp } from "react-icons/fa";



import { Sidebar } from 'primereact/sidebar';



const Products = (props) => {
    const history = useHistory()
    const { pid, type } = useParams();
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';


    const [Copied, setCopied] = useState({ id: '', status: false });

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }


    const [usdRate, setUSDRate] = useState(0);

    useMemo(async () => {
        // var formData = new FormData();
        // formData.append("admin_dashboard", 1);
        await axios({
            method: "GET",
            url: 'https://api.exchangerate-api.com/v4/latest/USD',
            // data: formData,
        }).then((res) => {
            // console.log(res.data.rates.PHP)
            setUSDRate(res.data.rates.PHP)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);



    const [application, setApplication] = useState([]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_application", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setApplication(res.data)
            // console.log(res.data)
        });
    }, [ENV]);

    const [cartlist, setCartList] = useState([]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("cart_list", 1);
        formData.append("inputvalue", JSON.stringify({ user_id: localStorage.getItem('user_id') }));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/product.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            setCartList(res.data)
            console.log(res.data)
        });
    }, [ENV, type]);


    const [product, setProduct] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("product_shop", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/product.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            setProduct([...res.data])
            console.log(res.data)
        });
    }, [ENV]);

    const [productlikes, setProductLikes] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("product_likes_fetch", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            // url: ENV.DOMAIN + 'sample.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            setProductLikes(res.data)
        });
    }, [ENV]);

    // console.log(userInfo)

    // const ClickCopied = async (user_id) => {
    //     await navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id)
    //     alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    // }



    const [leftright, setLeftRight] = useState('Left')
    const refArea = useRef()

    let touchArea = document.getElementById("touch-area");
    let mouseX, initialX = 0;
    let mouseY, initialY = 0;
    let isSwiped;

    let events = {
        mouse: {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
        },
        touch: {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
        }
    }

    let deviceType = "";

    // Detect touch device

    const isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (error) {
            deviceType = "mouse";
            return false;
        }
    }

    isTouchDevice();

    // console.log(events[deviceType].down)

    let rectLeft = 0; //touchArea.getBoundingClientRect([0]).left;
    let rectTop = 0; //touchArea.getBoundingClientRect([0]).top;
    // useEffect(()=>{
    //     if (props.userInfo) {
    rectLeft = touchArea?.getBoundingClientRect().left;
    rectTop = touchArea?.getBoundingClientRect().top;
    //     }
    // }, [])

    const getXY = (e) => {
        mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
        mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    }

    // isTouchDevice();

    // console.log(touchArea)


    touchArea?.addEventListener(events[deviceType].down, (event) => {
        isSwiped = true;
        getXY(event);
        initialX = mouseX;
        initialY = mouseY;
    });

    let diffX = 0;
    let diffY = 0;

    touchArea?.addEventListener(events[deviceType].move, (event) => {
        if (!isTouchDevice()) {
            event.preventDefault();
        }
        if (isSwiped) {
            getXY(event);
            diffX = mouseX - initialX;
            diffY = mouseY - initialY;
            if (Math.abs(diffY) > Math.abs(diffX)) {
                // console.log(diffY > 0 ? "Down" : "Up")
                // output.innerText = diffY > 0 ? "Down" : "Up"
            } else {
                // console.log(diffX > 0 ? "Right" : "Left")
                // if((diffY > 0 ? "Down" : "Up") !== "Down" || (diffY > 0 ? "Down" : "Up") !== "Up"){
                // setLeftRight(diffX > 0 ? "Right" : "Left")
                // }
                // output.innerText = diffX > 0 ? "Right" : "Left"
                if (leftright !== 'Right') {
                    if (Math.abs(diffX) <= 50) {
                        setLeftRight(diffX > 0 ? "Right" : "Left")
                    }
                }
            }
        }
    });

    touchArea?.addEventListener(events[deviceType].up, (event) => {
        isSwiped = false;
    });

    touchArea?.addEventListener("mouseleave", (event) => {
        isSwiped = false;
    });

    window.onload = () => {
        isSwiped = false;
    }


    const [qty, setQty] = useState(0)
    const UpdateQty = async (operator, num, item) => {
        var formData = new FormData();
        formData.append("updateqty_cart", 1);
        formData.append("value", JSON.stringify({ id: item.id, user_id: localStorage.getItem('user_id'), "operator": operator }));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/product.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // setProductLikes(res.data)
            setCartList(res.data)
            // if (res.data === 'success') {
            //     alert("")
            // }
        });
    }

    const [removeitem, setRemoveItem] = useState({ remove_dialog: false })
    const [placeitem, setPlaceItem] = useState({ place_dialog: false })

    const addtoCart = async () => {
        console.log(pid, type)
        var formData = new FormData();
        formData.append("add_tocart", 1);
        formData.append("value", JSON.stringify({ id: pid, user_id: localStorage.getItem('user_id') }));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/product.php',
            // url: ENV.DOMAIN + 'sample.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // setProductLikes(res.data)
            if (res.data === 'success') {
                alert("")
            }
        });
    }

    const LikeThis = async () => {
        var formData = new FormData();
        formData.append("product_likes", 1);
        formData.append("value", JSON.stringify({ id: pid, user_id: localStorage.getItem('user_id') }));
        await axios({
            method: "post",
            // url: ENV.DOMAIN + 'sample.php',
            // url: 'http://192.168.1.13/drinkgood/product.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // setProductLikes(res.data)
            // console.log(res.data)
            setProduct(res.data)
        });
    }

    const DisLikeThis = async () => {
        var formData = new FormData();
        formData.append("product_dislikes", 1);
        formData.append("value", JSON.stringify({ id: pid, user_id: localStorage.getItem('user_id') }));
        await axios({
            method: "post",
            // url: ENV.DOMAIN + 'sample.php',
            // url: 'http://192.168.1.13/drinkgood/product.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            // setProductLikes(res.data)
            // console.log(res.data)
            setProduct(res.data)
        });
    }

    const removeCart = async () => {
        console.log(removeitem.id, localStorage.getItem('user_id'))
        var formData = new FormData();
        formData.append("remove_cart", 1);
        formData.append("value", JSON.stringify({ id: removeitem.id, user_id: localStorage.getItem('user_id') }));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/product.php',
            url: ENV.DOMAIN + 'product.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setCartList([...res.data])
            setRemoveItem(prev => ({...prev, remove_dialog: false }))
            // if (res.data === 'success') {
            //     alert("")
            // }
        });
    }

    const [placeorderdetails, setPlaceOrderDetails] = useState({});

    const PlaceMyOrder = async () => {
        
        console.log(removeitem.id)
    }

    if (sessionStorage.getItem('session_page')) {

    } else {
        window.location.href = '/dashboard';
        return (<>{sessionStorage.getItem('session_page')}Redirecting...</>);
    }

    return (
        <div className="wrapper" ref={refArea} id='touch-area'>
            <LeftNavSwiper userInfo={props.userInfo} leftright={leftright} setLeftRight={setLeftRight} tab="products" />
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{ flex: 1, height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                            {
                                type === 'details' || type === 'cart' ?
                                    <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                        <Link to={`/products`}>
                                            <span><BiArrowBack size={24} color='rgb(181, 165, 77)' /></span>
                                        </Link>
                                    </div>
                                    :
                                    type === 'buy' ?
                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                            <Link to={`/products`}>
                                                <span><BiArrowBack size={24} color='rgb(181, 165, 77)' /></span>
                                            </Link>
                                        </div>
                                        :
                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                            <div onClick={() => setLeftRight('Right')} style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <span className='pi pi-bars'></span>
                                            </div>
                                        </div>
                            }
                            <div style={{ flex: 3, paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{ width: 45, height: 45 }} />
                                <div style={{ display: "flex", flexDirection: "column", color: "green", lineHeight: 1 }}>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute" }}>Mirabile</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7 }}>&</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12 }}>Proventus
                                        <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5 }}>Co.</span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ flex: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 5, padding: 5 }}>
                                {
                                    type === 'cart' ?
                                        <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                            <FaShoppingCart color='rgb(181, 165, 77)' />
                                        </div>
                                        :
                                        <Link to={`/products/cart`}>
                                            <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaShoppingCart color='rgb(181, 165, 77)' />
                                            </div>
                                        </Link>
                                }
                                <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bell'></span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid" style={{ padding: 0 }}>
                        <div className="row" style={{ padding: 0, paddingBottom: 10 }}>
                            {/* /.col */}
                            <div className="col-md-12" style={{ padding: 0 }}>
                                {
                                    type === 'buy' ?
                                        pid === 'activation' ?
                                            <div style={{ flex: 1, padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                                                <div style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", padding: 5 }}>
                                                    <span style={{ fontWeight: "bolder", fontSize: 20 }}>Buy Activation</span>
                                                </div>
                                                <div>
                                                    <span>Price</span>
                                                    <select className='form-control'>
                                                        <option>3888</option>
                                                        <option>1988</option>
                                                    </select>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                                                    <div style={{ flex: 1 }}>
                                                        <span>Quantity</span>
                                                        <input type='number' placeholder='0' className='form-control' />
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <span>Payment Method</span>
                                                        <select className='form-control'>
                                                            <option>...</option>
                                                            <option>Wallet Balance</option>
                                                            <option>GCash</option>
                                                            <option>GoTyme</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <div>
                                                        <label style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10}}>
                                                            <input type='radio' name='category' />
                                                            <span>Container</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10}}>
                                                            <input type='radio' name='category' />
                                                            <span>Gallons</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10}}>
                                                            <input type='radio' name='category' />
                                                            <span>Liter Pack</span>
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div>
                                                    <span>Wallet Balance: <span>{Number(props.userInfo.balance * usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                </div>
                                                <div>
                                                    <span>Proof of Payment</span>
                                                    <input type='file' />
                                                </div>
                                                <div>
                                                    <button className='btn btn-warning btn-block'>Complete Order</button>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ flex: 1, padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                                                <div style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", padding: 5 }}>
                                                    <span style={{ fontWeight: "bolder", fontSize: 20 }}>Buy Matrix</span>
                                                </div>
                                                <div>
                                                    <span>Price</span>
                                                    <select className='form-control'>
                                                        <option>748</option>
                                                        <option>698</option>
                                                    </select>
                                                </div>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                                                    <div style={{ flex: 1 }}>
                                                        <span>Quantity</span>
                                                        <input type='number' placeholder='0' className='form-control' />
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <span>Payment Method</span>
                                                        <select className='form-control'>
                                                            <option>...</option>
                                                            <option>Wallet Balance</option>
                                                            <option>GCash</option>
                                                            <option>GoTyme</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <label style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <input type='radio' name='category' />
                                                            <span>Container</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <input type='radio' name='category' />
                                                            <span>Gallons</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <input type='radio' name='category' />
                                                            <span>Liter Pack</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>Wallet Balance: <span>{Number(props.userInfo.balance * usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                </div>
                                                <div>
                                                    <span>Proof of Payment</span>
                                                    <input type='file' />
                                                </div>
                                                <div>
                                                    <button className='btn btn-warning btn-block'>Complete Order</button>
                                                </div>
                                            </div>
                                        :
                                        Number(pid) && type === 'details' ?
                                            <div style={{ flex: 1 }}>
                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                    <div style={{ width: "100%", padding: 10 }}>
                                                        <div style={{ width: "100%", borderRadius: 10, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                            <img src={ENV.DOMAIN + [...product].filter((i) => i.id === pid)[0]?.images} style={{ width: '100%', height: '100%', borderRadius: 10 }} />
                                                        </div>
                                                        <div>
                                                            <span style={{ fontWeight: "bolder", fontSize: 20 }}>
                                                                {[...product].filter((i) => i.id === pid)[0]?.product_name}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ display: "flex", gap: 5, flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                                    {
                                                                        [...product].filter((i) => Number(i.id) === Number(pid)).map((i)=>i)[0]?.likes.filter((i)=>Number(i.user_id) === Number(localStorage.getItem('user_id'))).map((i)=>i).length > 0 ?
                                                                            <span onClick={() => DisLikeThis()}>
                                                                                <FaRegThumbsUp color='blue' size={24} />
                                                                            </span>
                                                                            :
                                                                            <span onClick={() => LikeThis()}>
                                                                                <FaRegThumbsUp color='black' size={24} />
                                                                            </span>
                                                                    }
                                                                    <span>
                                                                        {
                                                                            [...product].filter((i) => Number(i.id) === Number(pid)).map((i)=>i)[0]?.likes?.length
                                                                        }
                                                                        {
                                                                            // JSON.stringify([...product].filter((i) => Number(i.id) === Number(pid)).map((i)=>i)[0]?.likes.filter((i)=>Number(i.user_id) === Number(localStorage.getItem('user_id'))).map((i)=>i))
                                                                        }
                                                                    </span>
                                                                    <div style={{ display: "flex", justifyContent: "center", transform: `translateY(-10)px` }}>
                                                                        {
                                                                            [...product].filter((i) => Number(i.id) === Number(pid)).map((i)=>i)[0]?.likes.map((item, key) => {
                                                                                return (
                                                                                    // to={"/products/details/1/"+key}
                                                                                    <Link>
                                                                                        <img src='http://192.168.1.13:3001/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg' key={key} style={{ width: 30, height: 30, zIndex: key, borderRadius: 100, border: "1px solid #ddd", backgroundColor: "#fff", transform: `translateX(${-key * 15}px)`, boxShadow: "0px 1px 2px #000" }} />
                                                                                    </Link>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <span></span>
                                                                <div>
                                                                    <button onClick={() => addtoCart()} style={{ width: 150, backgroundColor: "#3459a8", color: "#fff", height: 30, borderRadius: 5, border: "none" }}>Add to Cart</button>
                                                                </div>
                                                            </div>
                                                            {/* <div>
                                                                <div>
                                                                    <span>Details</span>
                                                                </div>

                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            type === 'cart' ?
                                                <div style={{ flex: 1 }}>
                                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                        <div style={{ flex: 1, backgroundColor: "#fff", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)" }}>
                                                            <div style={{ padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <span style={{ fontWeight: "bolder", color: "#686868" }}>
                                                                    {/* <Link to={`/products/cart`}>
                                                                    <div style={{ height: 40, width: 40, color: "rgb(104, 104, 104)", border: "1px solid #b5a54d", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                                        <FaAlignJustify size={18} />
                                                                    </div>
                                                                </Link> */}
                                                                </span>
                                                                <span style={{ fontWeight: "bolder", color: "#686868", display: "flex", gap: 10 }}><span>Wallet Bal</span> <span>{Number(props.userInfo.balance * usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                            </div>
                                                            <div style={{ flex: 1, width: "100%", height: 50, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                                <Link to={`/products/cart`} style={{ color: pid !== 'process' && pid !== 'ship' && pid !== 'receive' ? "#007bff" : "rgb(104, 104, 104)", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12, borderRight: "1px solid #ddd" }}>
                                                                    <span className='pi pi-shopping-cart'></span>
                                                                    <span>Cart</span>
                                                                </Link>
                                                                <Link to={"/products/cart/process"} style={{ color: pid === 'process' ? "#007bff" : "rgb(104, 104, 104)", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12, borderRight: "1px solid #ddd" }}>
                                                                    <span className='pi pi-clock'></span>
                                                                    <span>To Process</span>
                                                                </Link>
                                                                <Link to={"/products/cart/ship"} style={{ color: pid === 'ship' ? "#007bff" : "rgb(104, 104, 104)", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12, borderRight: "1px solid #ddd" }}>
                                                                    <span className='pi pi-box'></span>
                                                                    <span>To Ship</span>
                                                                </Link>
                                                                <Link to={"/products/cart/receive"} style={{ color: pid === 'receive' ? "#007bff" : "rgb(104, 104, 104)", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12 }}>
                                                                    <span className='pi pi-truck'></span>
                                                                    <span>To Receive</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                            {
                                                                type === 'cart' && pid === 'process' ?
                                                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 5, paddingTop: 5 }}>
                                                                        {
                                                                            [...cartlist].map(() => {
                                                                                return (
                                                                                    <div style={{ flex: 1, padding: "0px 5px" }}>
                                                                                        <div style={{ boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", backgroundColor: "#fff", borderRadius: 10 }}>
                                                                                            <div style={{ flex: 1, padding: "10px 10px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                    <span style={{ fontSize: 10 }}>Waiting... Pickup Center will arrange</span>
                                                                                                    <span style={{ fontSize: 10 }}>to Ship</span>
                                                                                                </div>
                                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                    <span style={{ fontSize: 10 }}>Order ID:</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                                                                <div style={{ flex: 1, border: "0px solid #ddd", height: 100, display: "flex", justifyContent: "center", alignItems: "flex-start", padding: 5 }}>
                                                                                                    <img src="/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg" style={{ width: 80, height: 80, borderRadius: 10 }} />
                                                                                                </div>
                                                                                                <div style={{ flex: 3, border: "0px solid #ddd", padding: 5 }}>
                                                                                                    <div>
                                                                                                        <span style={{ fontWeight: "bolder", fontSize: 12 }}>Product Name</span>
                                                                                                    </div>
                                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                                                                        <div style={{ lineHeight: 1 }}>
                                                                                                            <div>
                                                                                                                <span style={{ fontSize: 10 }}>Category</span>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <span style={{ fontSize: 10 }}>Set</span>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <span style={{ fontSize: 10 }}>+2 Matrix</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span style={{ fontSize: 10 }}>x2</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div style={{ borderTop: "1px solid #fff" }}>
                                                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                            <span style={{ fontSize: 10, fontWeight: "bolder" }}>Delivery Details</span>
                                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                                <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                    <span>Delivery Fee</span> <span>0.00</span>
                                                                                                                </span>
                                                                                                                <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                    <span>Total</span> <span>0.00</span>
                                                                                                                </span>
                                                                                                                <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                    <span>Sub-Total</span> <span>0.00</span>
                                                                                                                </span>
                                                                                                                <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                    <span>Payment Method</span> <span>GCash</span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    :
                                                                    type === 'cart' && pid === 'ship' ?
                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 5, paddingTop: 5 }}>
                                                                            {
                                                                                [...cartlist].map(() => {
                                                                                    return (
                                                                                        <div style={{ flex: 1, padding: "0px 5px" }}>
                                                                                            <div style={{ boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", backgroundColor: "#fff", borderRadius: 10 }}>
                                                                                                <div style={{ flex: 1, padding: "10px 10px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                        <span style={{ fontSize: 10 }}>Pickup Center</span>
                                                                                                        <span style={{ fontSize: 10 }}>to Receive</span>
                                                                                                    </div>
                                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                        <span style={{ fontSize: 10 }}>Order ID:</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                                                                    <div style={{ flex: 1, border: "0px solid #ddd", height: 100, display: "flex", justifyContent: "center", alignItems: "flex-start", padding: 5 }}>
                                                                                                        <img src="/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg" style={{ width: 80, height: 80, borderRadius: 10 }} />
                                                                                                    </div>
                                                                                                    <div style={{ flex: 3, border: "0px solid #ddd", padding: 5 }}>
                                                                                                        <div>
                                                                                                            <span style={{ fontWeight: "bolder", fontSize: 12 }}>Product Name</span>
                                                                                                        </div>
                                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                                                                            <div style={{ lineHeight: 1 }}>
                                                                                                                <div>
                                                                                                                    <span style={{ fontSize: 10 }}>Category</span>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <span style={{ fontSize: 10 }}>Set</span>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <span style={{ fontSize: 10 }}>+2 Matrix</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <span style={{ fontSize: 10 }}>x2</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div style={{ borderTop: "1px solid #fff" }}>
                                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                                <span style={{ fontSize: 10, fontWeight: "bolder" }}>Delivery Details</span>
                                                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                                    <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                        <span>Delivery Fee</span> <span>0.00</span>
                                                                                                                    </span>
                                                                                                                    <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                        <span>Total</span> <span>0.00</span>
                                                                                                                    </span>
                                                                                                                    <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                        <span>Sub-Total</span> <span>0.00</span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        type === 'cart' && pid === 'receive' ?
                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 5, paddingTop: 5 }}>
                                                                                {
                                                                                    [...cartlist].map(() => {
                                                                                        return (
                                                                                            <div style={{ flex: 1, padding: "0px 5px" }}>
                                                                                                <div style={{ boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", backgroundColor: "#fff", borderRadius: 10 }}>
                                                                                                    <div style={{ flex: 1, padding: "10px 10px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                            <span style={{ fontSize: 10 }}>Pickup Center</span>
                                                                                                            <span style={{ fontSize: 10 }}>Completed</span>
                                                                                                        </div>
                                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                            <span style={{ fontSize: 10 }}>Order ID:</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                                                                        <div style={{ flex: 1, border: "0px solid #ddd", height: 100, display: "flex", justifyContent: "center", alignItems: "flex-start", padding: 5 }}>
                                                                                                            <img src="/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg" style={{ width: 80, height: 80, borderRadius: 10 }} />
                                                                                                        </div>
                                                                                                        <div style={{ flex: 3, border: "0px solid #ddd", padding: 5 }}>
                                                                                                            <div>
                                                                                                                <span style={{ fontWeight: "bolder", fontSize: 12 }}>Product Name</span>
                                                                                                            </div>
                                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                                                                                <div style={{ lineHeight: 1 }}>
                                                                                                                    <div>
                                                                                                                        <span style={{ fontSize: 10 }}>Category</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span style={{ fontSize: 10 }}>Set</span>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <span style={{ fontSize: 10 }}>+2 Matrix</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <span style={{ fontSize: 10 }}>x2</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div style={{ borderTop: "1px solid #fff" }}>
                                                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                                    <span style={{ fontSize: 10, fontWeight: "bolder" }}>Delivery Details</span>
                                                                                                                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                                        <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                            <span>Delivery Fee</span> <span>0.00</span>
                                                                                                                        </span>
                                                                                                                        <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                            <span>Total</span> <span>0.00</span>
                                                                                                                        </span>
                                                                                                                        <span style={{ fontSize: 10, display: "flex", justifyContent: "space-between" }}>
                                                                                                                            <span>Sub-Total</span> <span>0.00</span>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <div style={{ fontSize: 10, backgroundColor: "#e8943a", color: "#fff", padding: 3, width: 100, borderRadius: 10, border: "none", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", textAlign: "center" }}>Claim Matrix</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", padding: 5 }}>
                                                                                <Sidebar
                                                                                    icons={
                                                                                        <div style={{ width: "100%", fontSize: 18, fontWeight: 800 }}>Remove</div>
                                                                                    }
                                                                                    visible={removeitem.remove_dialog}
                                                                                    blockScroll={true}
                                                                                    showCloseIcon={false}
                                                                                    position="bottom"
                                                                                    // style={{ minHeight: 200, borderRadius: 0 }}
                                                                                    onHide={() => setRemoveItem(prev => ({ remove_dialog: false }))}
                                                                                >
                                                                                    {/* {JSON.stringify(removeitem)} */}
                                                                                    <div style={{ flex: 1, }}>
                                                                                        <button onClick={()=>removeCart()} className='btn btn-warning btn-block'>Remove this item</button>
                                                                                    </div>
                                                                                </Sidebar>
                                                                                <Sidebar
                                                                                    icons={
                                                                                        <div style={{ width: "100%", fontSize: 18, fontWeight: 800 }}>Details</div>
                                                                                    }
                                                                                    visible={placeitem.place_dialog}
                                                                                    blockScroll={true}
                                                                                    showCloseIcon={false}
                                                                                    position="bottom"
                                                                                    style={{ minHeight: 500, borderRadius: 0 }}
                                                                                    onHide={() => setPlaceItem(prev => ({ place_dialog: false }))}
                                                                                >
                                                                                    {/* {JSON.stringify(placeitem)} */}

                                                                                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                                                                        <div >
                                                                                            <span>Payment</span>
                                                                                            <select name='payment' onChange={(e) => setPlaceOrderDetails(prev=>({...prev, [e.target.name]: e.target.value}))} className='form-control'>
                                                                                                <option value={"Wallet Balance"}>Wallet Balance</option>
                                                                                                <option value={"COD"}>Cash on Delivery</option>
                                                                                                <option value={"GCash"}>GCash</option>
                                                                                                <option value={"GoTyme"}>GoTyme</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        {
                                                                                            placeorderdetails.payment === 'GCash' || placeorderdetails.payment === 'GoTyme' ?
                                                                                            <div >
                                                                                                <span>Proof of Payment</span>
                                                                                                <input type='file' />
                                                                                            </div>
                                                                                            : 
                                                                                            placeorderdetails.payment === 'COD' ?
                                                                                            <span style={{ fontWeight: "bolder", color: "#686868", display: "flex", flexDirection: "column", gap: 10 }}>
                                                                                                <span>COD: <span>{props.userInfo.balance}</span> </span> 
                                                                                                <span>Fee: <span></span> </span> 
                                                                                            </span>
                                                                                            :
                                                                                            <span style={{ fontWeight: "bolder", color: "#686868", display: "flex", gap: 10 }}><span>Wallet Bal</span> <span>{Number(props.userInfo.balance * usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                                                        }
                                                                                        <div >
                                                                                            <span>Shipping Address</span>
                                                                                            <textarea name='address' onChange={(e) => setPlaceOrderDetails(prev=>({...prev, [e.target.name]: e.target.value}))} className='form-control'></textarea>
                                                                                        </div>
                                                                                        <div >
                                                                                            <button onClick={()=>PlaceMyOrder()} className='btn btn-warning btn-block'>Place my Order</button>
                                                                                        </div>
                                                                                    </div>



                                                                                </Sidebar>
                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 5 }}>
                                                                                    {
                                                                                        [...cartlist].map((item, key) => {
                                                                                            return (
                                                                                                <div key={key} style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)" }}>
                                                                                                    <div style={{ flex: 1, border: "1px solid #fff", padding: 5, borderTopLeftRadius: 10, borderBottomLeftRadius: 10, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                                                                        <img src="/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg" style={{ width: 80, height: 80, borderRadius: 10 }} />
                                                                                                    </div>
                                                                                                    <div style={{ flex: 3, border: "1px solid #fff", display: "flex", flexDirection: "column", justifyContent: "space-between", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                                                                                                        <div style={{ padding: 5 }}>
                                                                                                            <div style={{ fontWeight: "600" }}>{item.product_name}</div>
                                                                                                            <div style={{ fontWeight: "normal", fontSize: 10 }}>Price: </div>
                                                                                                        </div>
                                                                                                        <div style={{ flex: 1, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                                                                                <span onClick={() => item.qty != 0 ? UpdateQty('minus', 1, item) : null} style={{ padding: 5, height: 25, width: 30, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}>
                                                                                                                    <span className='pi pi-minus' style={{ fontSize: 10 }}></span>
                                                                                                                </span>
                                                                                                                <span style={{ padding: 5, height: 25, minWidth: 40, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center" }}>{item.qty}</span>
                                                                                                                <span onClick={() => UpdateQty('plus', 1, item)} style={{ padding: 5, height: 25, width: 30, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                                                                                                                    <span className='pi pi-plus' style={{ fontSize: 10 }}></span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <span style={{ padding: "0px 10px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                                                                                <button onClick={() => item.qty != 0 ? setPlaceItem(prev => ({ ...item, place_dialog: true })) : null} style={{ fontSize: 10, backgroundColor: item.qty != 0 ? "#e8943a" : "#ddd", color: "#fff", padding: 5, width: 80, borderRadius: 10, border: "none", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)" }}>Place Order</button>
                                                                                                            </span>
                                                                                                            <span style={{ padding: "0px 10px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                                                                                <FaRegTrashAlt onClick={() => setRemoveItem(prev => ({ ...item, remove_dialog: true }))} color='red' />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    {/* <div style={{ flex: 1 }}>
                                                        <div style={{ flex: 1, padding: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                            <div style={{ flex: 4 }}>
                                                                <input className='form-control' placeholder='Search...' />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", padding: 10 }}>
                                                        {/* <div className style={{ width: '50%', padding: 5, display: 'flex', flexDirection: 'column', height: 250, gap: 10 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0.5rem 1rem', borderRadius: 10 }}>
                                                                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(255, 255, 255)', borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Link to="/products/buy/activation">
                                                                        <div style={{ padding: 5, textAlign: "center" }}><span>Buy Activation</span></div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0.5rem 1rem', borderRadius: 10 }}>
                                                                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(255, 255, 255)', borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Link to="/products/buy/matrix">
                                                                        <div style={{ padding: 5, textAlign: "center" }}><span>Buy Matrix</span></div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {
                                                            [...product].map((item, key) => {
                                                                return (
                                                                    <div key={key} className="" style={{ width: "50%", padding: 5, display: "flex", flexDirection: "column", height: 250 }}>
                                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", height: "100%", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .2)", borderRadius: 10 }}>
                                                                            <div style={{ backgroundColor: "#fff", width: "100%", height: "auto", borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                                                                                <Link to={`/products/details/${item.id}`}>
                                                                                    <img src={ENV.DOMAIN + item.images} style={{ width: "100%", height: "100%", borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
                                                                                </Link>
                                                                            </div>
                                                                            <div style={{ width: "100%", height: "100%", backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                                                                <Link to={`/products/details/${item.id}`}>
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <span>{item.product_name}</span>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>

                                                                            {
                                                                                [...productlikes].filter(
                                                                                    (i) => Number(i.product_id) === Number(item.id)
                                                                                ).length > 0 ?
                                                                                    <div style={{ flex: 1, width: "100%", padding: 5, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                                        <span style={{ fontSize: 12 }}>
                                                                                            {
                                                                                                "Likes: " + [...productlikes].filter(
                                                                                                    (i) => Number(i.product_id) === Number(item.id)
                                                                                                ).length
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {/* <div className style={{ width: '50%', padding: 5, display: 'flex', flexDirection: 'column', height: 250 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0.5rem 1rem', borderRadius: 10 }}>
                                                                <div style={{ backgroundColor: 'rgb(255, 255, 255)', width: '100%', height: 'auto', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                                                                    <Link to="/products/buy/matrix">
                                                                        <img src="https://drdecuplepi.org/dist/img/product/377149420_1068326037636354_2663609736674720865_n.jpg" style={{ width: '100%', height: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
                                                                    </Link>
                                                                </div>
                                                                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                                                    <Link to="/products/buy/matrix">
                                                                        <div style={{ padding: 5 }}><span>Buy Matrix</span></div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                    </div>
                                                </div>
                                }
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <MatrixMenu /> */}
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20 }}>
                        <i className='pi pi-microsoft' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{ position: "absolute", color: "#fff", padding: 3, top: 2, right: 10, backgroundColor: "red", fontSize: 8, borderRadius: 100 }}>Coming</span>
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Profile</span>
                    </div>
                </div> */}
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default Products;
