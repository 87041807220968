import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import ENV from './ENV';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Dialog } from 'primereact/dialog';

const AdminRaffleEntries = ({ userInfo }) => {
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [visible, setVisible] = useState(false)
    const [raffle_entries, setRaffleEntryList] = useState([])
    const [inputvalue, setInputValue] = useState({number_to_win: 0, user_id: "", fullname: "", sponsor_id: "", upline_name: "", next_upline: ""})

    useMemo(async () => {

        var formData = new FormData();
        formData.append("raffle_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'raffle.php',
            // url: 'http://localhost/drinkgood/raffle.php',
            data: formData,
        }).then((res) => {
            setRaffleEntryList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])

    useMemo(async () => {
        // if (inputvalue.number_to_win !== 0) {
        //     return;
        // }
        var formData = new FormData();
        formData.append("raffle_enter_to_win", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'raffle.php',
            // url: 'http://localhost/drinkgood/raffle.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            // console.log({fullname: res.data[0].fullname, user_id: res.data[0].user_id, sponsor_id: res.data[0].sponsor_id})
            // setInputValue(prev=>({...prev, fullname: res.data[0].fullname, user_id: res.data[0].user_id, sponsor_id: res.data[0].sponsor_id}))
        }).catch((err) => {

        });
        // if (Number(inputvalue.number_to_win)!==0) {
        //     alert(inputvalue.number_to_win)
        // }
    }, [inputvalue])

    const name = (item) => {
        return [...item.list].map((i)=>'No.: '+i.id+' '+i.fullname+"\n")
    }

    const handleClose = () => {
        setVisible(false)
    }


    const getNextWinner = async () => {
        console.log(inputvalue)
        return;
        var formData = new FormData();
        formData.append("nextupline", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue)); //inputvalue.upline_sponsor_id
        await axios({
            method: "post",
            // url: ENV.DOMAIN + 'process.php',
            url: 'http://localhost/drinkgood/raffle.php',
            data: formData,
        }).then((res) => {
            // setRaffleEntryList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }

    const onReprint = async (item) => {
        var formData = new FormData();
        formData.append("reprint", 1);
        formData.append("inputvalue", JSON.stringify(item)); //inputvalue.upline_sponsor_id
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'pos_reprint.php',
            // url: 'http://localhost/drinkgood/raffle.php',
            data: formData,
        }).then(async(res) => {
            alert(JSON.stringify(res.data[0]))
            var formData = new FormData();
            formData.append("print_data", JSON.stringify(res.data[0])); //inputvalue.upline_sponsor_id
            await axios({
                method: "post",
                url: 'https://posricompco.stgc.online',
                data: formData,
            }).then((res) => {
                console.log(res);
            });
        }).catch((err) => {

        });

    }

    // console.log(inputvalue)

    return (
        <div className="wrapper">
            
            <Dialog draggable={false} blockScroll={true} position="top" visible={visible} style={{ width: '30vw' }} onHide={handleClose}
                header={
                    "Enter to Win"
                }
            >
                <div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <label>Enter the Number</label>
                        <input type={"text"} name='number_to_win' className='form-control' onKeyPress={(e)=>{
                            if (e.key == 'Enter') {
                                setInputValue(prev=>({...prev, [e.target.name]: e.target.value}));
                              }
                        }} style={{height: 100, width: 300, fontSize: 50, textAlign: "center"}} />
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <label>Winner</label>
                        <span style={{fontSize: 20}}>{inputvalue.fullname}</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <label>Next Winner</label>
                        {/* <span style={{fontSize: 20}}>{inputvalue.upline_name}</span> */}
                        <button className='btn btn-info btn-sm' onClick={(()=>getNextWinner(inputvalue.sponsor_id))}>Next</button>
                    </div>
                </div>
            </Dialog>
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Raffle Entries</h1>
                                <a href='https://wheelofnames.com/' target='_blank'>Go to WheelofNames</a>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Main row */}
                        <div className="row">
                            <div className='col-sm-12'>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
                                    {
                                        raffle_entries.map((item) => {
                                            // if (item.status == 'approved') {
                                                return (
                                                    <div style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection:"column", width: "33%", justifyContent: "space-between", border:"1px solid #ddd" }}>
                                                        <div style={{width:"100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border:"1px solid #ddd" }}>
                                                            <div style={{width:"100%", display:"flex", flexDirection:"column" }}>
                                                                <div style={{ width: null, padding:5, fontWeight:600, backgroundColor:"#0092b9", color:"#fff", display:"flex", flexDirection:"row", justifyContent:"space-between", }}>
                                                                    <span>Month Of {item.month_name} {item.year}</span>
                                                                    {
                                                                        item?.list.filter((i) => i.status === 'pending').length > 0 ?
                                                                            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                                                                {/* <CopyToClipboard text={item.list.map((i)=>JSON.stringify({"text": `No.: ${i.id} ${i.fullname}`}))} onCopy={() => {alert()}}>
                                                                                <button className='btn btn-default btn-xs'>Copy</button>
                                                                            </CopyToClipboard> */}

                                                                                {
                                                                                    item.current_month === item.month_draw ?
                                                                                        <CopyToClipboard text={String(name(item)).replace(/,/g, '')} onCopy={() => { alert(String(name(item)).replace(/,/g, '')) }}>
                                                                                            <button className='btn btn-success btn-xs'>Copy</button>
                                                                                        </CopyToClipboard>
                                                                                        : null
                                                                                }
                                                                                {/* {
                                                                                    JSON.stringify(item?.list.filter((i) => i.status === 'pending').length)
                                                                                } */}
                                                                                {
                                                                                    item.current_month === item.month_draw ?
                                                                                        <button onClick={() => {
                                                                                            setVisible(true)
                                                                                        }} className='btn btn-warning btn-xs'>Enter to Win</button>
                                                                                        :
                                                                                        <button className='btn btn-default btn-xs'>Enter to Win</button>
                                                                                }
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    item?.list?.map((_item, _key)=>{
                                                                        return (
                                                                            <div style={{padding:5, width:"100%", display:"flex", flexDirection:"column", fontSize:12, justifyContent:"flex-start", border:"1px solid #dfff"}}>
                                                                                {/* <div>{_item.date_used}</div> */}
                                                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:10, width:"100%"}}>
                                                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:0, width:"100%"}}>
                                                                                        <div style={{display:"flex", flexDirection:"row", alignItems: "center", gap:5}}>
                                                                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>{_key+1} Ticket No.: <span style={{fontSize: 20}}>{_item.raffle_number}</span></div>
                                                                                            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                                                                <span style={{fontWeight:"bold", fontSize: 20}}>{_item.fullname}</span>
                                                                                                {
                                                                                                    _item.status === 'pending' ?
                                                                                                    <div onClick={(()=>onReprint(_item))}>Reprint</div>
                                                                                                    : 
                                                                                                    <span style={{fontWeight:"normal", fontSize: 15}}>Winner</span>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                            <div>[{_item.sponsorid}]</div>
                                                                                            <div><span style={{fontWeight:"bold", textDecoration:"underline"}}>{_item.sponsorname}</span> (Sponsored By)</div>
                                                                                        </div>
                                                                                        <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                            <div>[{_item.userid_activate}]</div>
                                                                                            <div><span style={{fontWeight:"bold", textDecoration:"underline"}}>{_item.username_activate}</span> (Activated By)</div>
                                                                                        </div> */}
                                                                                        {/* <div style={{display:"flex", flexDirection:"column"}}>
                                                                                            <div>Account Name: <span style={{fontWeight:"bold"}}>{_item.fullname}</span></div>
                                                                                        </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }).slice(0, 10)
                                                                }                                            
                                                            </div>
                                                        </div>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                            <div style={{padding:5, display:"flex", flexDirection:"column"}}>
                                                                <span style={{fontWeight:"bold", textDecoration:"underline"}}>Total Raffle Tickets: {item?.list.length}</span>
                                                            </div>
                                                            <div style={{padding:5, display:"flex", flexDirection:"row", justifyContent: "flex-end", alignItems: "center", gap: 10}}>
                                                                <span>Collection</span>
                                                                <span>PHP <span style={{fontWeight: "bolder"}}>{item.total}</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            // }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );
}

export default AdminRaffleEntries;
