import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import axios from 'axios';
import ENV from './ENV';
import ConfirmPaymentEntry from './extra/admin/ConfirmPaymentEntry';

const AdminPayoutDeduction = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';



    const [useraccountlist, setUserAccountlist] = useState([])
    const [userlist, setUserlist] = useState([])
    const [search, setSearch] = useState('')


    const refreshCodeList = async () => {
        var formData = new FormData();
        formData.append("kyc_list", 1);
        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/kyc_form.php',
            // url: ENV.DOMAIN+'kyc_form.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
        }).catch((err) => {

        });
    }


    useMemo(async () => {

        var formData = new FormData();
        formData.append("user_accounts", 1);
        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/process.php',
            // url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setUserAccountlist(res.data)
        }).catch((err) => {

        });
    }, [ENV])


    useMemo(async () => {

        var formData = new FormData();
        formData.append("users", 1);
        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/kyc_form.php',
            // url: ENV.DOMAIN+'kyc_form.php',
            data: formData,
        }).then((res) => {
            setUserlist(res.data)
        }).catch((err) => {

        });
    }, [ENV])



    const SearchData = (data, value) => {
        if (value != '') {
            data = data.filter((i) => (i?.fullname.toLowerCase().includes(value.trim().toLowerCase()))
                || (i?.owner_name.toLowerCase().includes(value.trim().toLowerCase()))
                || (i?.user_id.toLowerCase().includes(value.trim().toLowerCase()))
                || (i?.code.toLowerCase().includes(value.trim().toLowerCase()))
                || (i?.confirm == 0 ? 'paid' : 'unpaid').toLowerCase().includes(value.trim().toLowerCase())
            );
        }
        return data;
    }

    const buttonProcess = (status) => {
        if (status === 'approve') {
            alert("Under Construction")
        } else if (status === 'decline') {
            alert("Under Construction")
        }
    }

    // console.log(useraccountlist)

    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
            <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
        </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <h1 className="m-0">User Account</h1>
                                <span>
                                    <input type='text' onChange={(e) => setSearch(e.target.value)} style={{ width: 300, height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 5 }} placeholder='Search' />
                                </span>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}

                            <div className='col-sm-12'>
                                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                    {
                                        [...useraccountlist].filter((i) => i?.fullname?.toLowerCase().includes(search.trim().toLowerCase()) && i?.user_id !== '000000000').map((item) => {
                                            return (
                                                <div style={{ width: "100%", border: "1px solid #ddd", padding: 5, backgroundColor: "#fff" }}>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <span>Account ID: <span style={{ fontWeight: "bolder" }}>{item.user_id}</span></span>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <span>Name: <span style={{ fontWeight: "bolder" }}>{item.fullname}</span></span>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <span>Username: <span style={{ fontWeight: "bolder" }}>{item.username}</span></span>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <span>Password: <span style={{ fontWeight: "bolder" }}>{item.password}</span></span>
                                                    </div>
                                                </div>
                                            )
                                        }).slice(0, 20)
                                    }
                                </div>
                            </div>


                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );



}

export default AdminPayoutDeduction;
