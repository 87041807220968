import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import swal from 'sweetalert';
import CopyToClipboard from 'react-copy-to-clipboard';
import LeftNavSwiper from './component/swipe/LeftNavSwiper';
import { Sidebar } from 'primereact/sidebar';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

const PreSignUpList = (props) => {
    const history = useHistory()
    const { activation, user_id, name } = useParams();
    // if (props.userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';
    // console.log(props.userInfo)

    const [Copied, setCopied] = useState({ id: '', status: false });
    const [activateForm, setActivateForm] = useState({ visible: false });

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }


    const [usdRate, setUSDRate] = useState(0);

    useMemo(async () => {
        // var formData = new FormData();
        // formData.append("admin_dashboard", 1);
        await axios({
            method: "GET",
            url: 'https://api.exchangerate-api.com/v4/latest/USD',
            // data: formData,
        }).then((res) => {
            // console.log(res.data.rates.PHP)
            setUSDRate(res.data.rates.PHP)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, activateForm.user_id]);

    const [directInfo, setDirectInfo] = useState([]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("unpaid", 1);
        formData.append("user_id", props.userInfo?.user_id);

        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setDirectInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [props]);

    const ActivateBtn = async (amount) => {
        if (Number(props.userInfo?.token*usdRate).toFixed(0)>=amount) {
            swal({
                title: "Process!",
                text: "Are you sure!",
                icon: "info",
                // timer: 1000,
                buttons: {
                    confirm: "Activate Now",
                    cancel: "Cancel",
                },
            }).then(async (confirm) => {
                if (confirm) {
                    var formData = new FormData();
                    formData.append("activate_unpaid_bypoints", 1);
                    formData.append("value", JSON.stringify({ unpaid_id: user_id, user_id: localStorage.getItem('user_id'), amount: amount, usdRate: usdRate, usd_amount: Number(amount/usdRate).toFixed(2) }));
                    await axios({
                        method: "post",
                        // url: 'http://192.168.1.13/drinkgood/process.php',
                        url: ENV.DOMAIN + 'process.php',
                        data: formData,
                    }).then((res) => {
                        alert(JSON.stringify(res.data))
                        // window.location.href = '/pre-sign-up';
                    })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    history.goBack();
                }
            });
        } else {
            swal({
                title: "Invalid!",
                text: "Insufficient Balance",
                icon: "error",
                timer: 1000,
                button: false,
            });
        }
    }

    useEffect(()=>{
        
        if (activation) {
            // alert(JSON.stringify(sessionStorage.getItem("item")))
            setActivateForm(prev => ({...prev, visible: true }))
        }
    }, [activation, user_id])







    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////



    // const ClickCopied = async (user_id) => {
    //     await navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id)
    //     alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    // }


    const [startX, setStartX] = useState(null)
    const [widthRoot, setWidthRoot] = useState(0)
    const [translateX, setTranslateX] = useState(0)
    const [leftright, setLeftRight] = useState('Left')
    const refArea = useRef()

    let touchArea = document.getElementById("touch-area");
    let mouseX, initialX = 0;
    let mouseY, initialY = 0;
    let isSwiped;

    let events = {
        mouse: {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
        },
        touch: {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
        }
    }

    let deviceType = "";

    // Detect touch device

    const isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (error) {
            deviceType = "mouse";
            return false;
        }
    }

    isTouchDevice();

    // console.log(events[deviceType].down)

    let rectLeft = 0; //touchArea.getBoundingClientRect([0]).left;
    let rectTop = 0; //touchArea.getBoundingClientRect([0]).top;
    // useEffect(()=>{
    //     if (props.userInfo) {
    rectLeft = touchArea?.getBoundingClientRect().left;
    rectTop = touchArea?.getBoundingClientRect().top;
    //     }
    // }, [])

    const getXY = (e) => {
        mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
        mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    }

    // isTouchDevice();

    // console.log(touchArea)


    touchArea?.addEventListener(events[deviceType].down, (event) => {
        isSwiped = true;
        getXY(event);
        initialX = mouseX;
        initialY = mouseY;
    });

    let diffX = 0;
    let diffY = 0;

    touchArea?.addEventListener(events[deviceType].move, (event) => {
        if (!isTouchDevice()) {
            event.preventDefault();
        }
        if (isSwiped) {
            getXY(event);
            diffX = mouseX - initialX;
            diffY = mouseY - initialY;
            if (Math.abs(diffY) > Math.abs(diffX)) {
                // console.log(diffY > 0 ? "Down" : "Up")
                // output.innerText = diffY > 0 ? "Down" : "Up"
            } else {
                // console.log(diffX > 0 ? "Right" : "Left")
                // if((diffY > 0 ? "Down" : "Up") !== "Down" || (diffY > 0 ? "Down" : "Up") !== "Up"){
                // setLeftRight(diffX > 0 ? "Right" : "Left")
                // }
                // output.innerText = diffX > 0 ? "Right" : "Left"
                console.log("X", mouseX)
                if (leftright !== 'Right') {
                    setLeftRight(diffX > 0 ? "Right" : "Left")
                }
            }
        }
    });

    touchArea?.addEventListener(events[deviceType].up, (event) => {
        isSwiped = false;
    });

    touchArea?.addEventListener("mouseleave", (event) => {
        isSwiped = false;
    });

    window.onload = () => {
        isSwiped = false;
    }


    if (sessionStorage.getItem('session_page')) {

    } else {
        window.location.href = '/dashboard';
        return (<>{sessionStorage.getItem('session_page')}Redirecting...</>);
    }
    
    return (
        <div className="wrapper" ref={refArea} id='touch-area'>
            <LeftNavSwiper userInfo={props.userInfo} leftright={leftright} setLeftRight={setLeftRight} tab="presignup" raffle_program={false} />
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{ flex: 1, height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div onClick={() => setLeftRight('Right')} style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bars'></span>
                                </div>
                            </div>
                            <div style={{ flex: 4, paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <img src={require('./sdsadasd4a65sd51a56s4da.png')} style={{ width: 45, height: 45, borderRadius: 100 }} />
                                <div style={{ display: "flex", flexDirection: "column", color: "green", lineHeight: 1 }}>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute" }}>Mirabile</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7 }}>&</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12 }}>Proventus
                                        <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5 }}>Co.</span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bell'></span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* ============================== */}

            <Sidebar
                icons={
                    <div style={{flex: 1}}>
                        <div style={{ width: "100%", "fontSize": 15, "fontWeight": 800 }}>Activation</div>
                        <div style={{ width: "100%", "fontSize": 15, fontWeight: "bolder" }}>{name}</div>
                        <div style={{ width: "100%", "fontSize": 10, fontWeight: "bolder" }}>ID: {user_id}</div>
                    </div>
                }
                visible={user_id ? true : false}
                blockScroll={true}
                showCloseIcon={true}
                position="bottom"
                style={{ minHeight:300, borderRadius: 0 }}
                onHide={() => {
                    history.goBack()
                }}
            >

                <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 10}}>
                    <div style={{flex:1, display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between"}}>
                        <span>My Credits</span>
                        <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", gap: 5}}>
                            <span style={{fontWeight: "bolder"}}>USD {Number(props.userInfo?.token).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <span style={{fontWeight: "bolder"}}>PHP {Number(props.userInfo?.token*usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>
                    </div>
                    <button onClick={() => {
                        ActivateBtn(1988)
                    }} className={Number(props.userInfo?.token * usdRate).toFixed(0) >= 1988 ? 'btn btn-primary' : 'btn btn-default'} style={{ flex: 1, display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <span>PHP {Number(1988).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <span>USD {Number(1988 / usdRate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </button>
                    <button onClick={() => {
                        ActivateBtn(3888)
                    }} className={Number(props.userInfo?.token * usdRate).toFixed(0) >= 3888 ? 'btn btn-primary' : 'btn btn-default'} style={{ flex: 1, display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <span>PHP {Number(3888).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <span>USD {Number(3888 / usdRate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </button>
                </div>


                {/* {JSON.stringify(activateForm)} */}

            </Sidebar>




            {/* ============================== */}
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row" style={{height: "100vh"}}>
                            {/* /.col */}
                            <div className="col-md-12" style={{ padding: 0 }}>
                                {/* <div className="card-header p-2">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                    </ul>
                                </div> */}
                                <div style={{ padding: "5px 0px 5px 0px"}}>
                                    {/* <div>
                                        <span style={{fontSize: 18}}>Token: 0.00</span>
                                    </div> */}
                                    {/* <div style={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderRadius: 5, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)", backgroundColor: "#fff", height: 50, padding: 5}}>
                                        <span style={{fontSize: 18, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>Token: <span style={{fontWeight: "bolder", fontSize: 25}}>{props.userInfo.token}</span></span>
                                        <div>
                                            <button className='btn btn-info btn-xs'>Buy Token</button>
                                        </div>
                                    </div> */}
                                </div>
                                {/* /.card-header */}


                                <>
                                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                        {
                                            [...directInfo].map((item, key) => {
                                                return (
                                                    <Link to={`/pre-sign-up/activation/${item.user_id}/${item.fullname}`} key={key} className="card" style={{ padding: 5, display: "flex", flexDirection: "column", gap: 0, marginBottom: 0 }}>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 10 }}>
                                                            <div>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                    <img src={ENV.DOMAIN + item.image} className="img-circle elevation-2" alt="User Image" style={{ width: 50, height: 50 }} />
                                                                    <div>
                                                                        <b>{item.fullname}</b>
                                                                        <div><small>ID: {item.user_id}</small></div>
                                                                        <div><small>{item.date_registered}</small></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                                {/* /.card-body */}
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20 }}>
                        <i className='pi pi-microsoft' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{ position: "absolute", color: "#fff", padding: 3, top: 2, right: 10, backgroundColor: "red", fontSize: 8, borderRadius: 100 }}>Coming</span>
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Profile</span>
                    </div>
                </div> */}
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default PreSignUpList;
