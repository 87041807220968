import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import axios from 'axios';
import ENV from './ENV';
import ConfirmPaymentEntry from './extra/admin/ConfirmPaymentEntry';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Production from './component/inventory/Production';

const AdminInventory = ({ userInfo }) => {
    const history = useHistory()
    const { supplies } = useParams();
    document.body.className = 'hold-transition sidebar-mini layout-fixed';


    const [kyclist, setKYClist] = useState([])
    const [userlist, setUserlist] = useState([])
    const [search, setSearch] = useState('')



    if (window.innerWidth <= 768) {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h5 className="m-0">Paid Code</h5>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div style={{ padding: 5 }}>
                            <input onChange={(e) => setSearch(e.target.value)} placeholder='Search...' style={{ padding: 5, height: 30, borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>

                        </div>
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>
        )
    } else {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">
                                        {
                                            supplies === 'production' ?
                                                <>Production Inventory</>
                                                : null
                                        }
                                        {
                                            supplies === 'warehouse' ?
                                                <>Warehouse Inventory</>
                                                : null
                                        }
                                        {
                                            supplies === 'puc' ?
                                                <>Pickup Center Inventory</>
                                                : null
                                        }
                                    </h1>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                                {/* Left col */}
                                {/* /.Left col */}
                                {/* right col (We are only adding the ID to make the widgets sortable)*/}

                                {
                                            supplies === 'production' ?
                                                <Production />
                                                :
                                                supplies === 'warehouse' ?
                                                    <>Warehouse Inventory</>
                                                    :
                                                    <>Pickup Center Inventory</>
                                        }


                                {/* right col */}
                            {/* /.row (main row) */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>

        );
    }



}

export default AdminInventory;
