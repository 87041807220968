import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import swal from 'sweetalert';
import useLongPress from './extra/useLongPress';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import Countdown from './extra/Countdown';
import KYCStatus from './component/kyc/KYCStatus';
import SelfieCamera from './component/wallet/SelfieCamera';
import LeftNavSwiper from './component/swipe/LeftNavSwiper';
import { Sidebar } from 'primereact/sidebar';
import { Sync } from '@mui/icons-material';

const Wallet = (props) => {


    // console.log(props.userInfo?.balance, props.userInfo?.token)

    const history = useHistory()
    const location = useLocation()
    const { dialog } = useParams();

    // if (props.userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';
    // const [activation_code, setActivationCode] = useState('');
    const [inputvalue, setInputValue] = useState({
        "user_id": localStorage.getItem('user_id'),
        "otp_code": "",
        "payout_method": "",
        "account_name": "",
        "account_number": "",
        "amount": 0
    });
    // console.log(inputvalue)
    const [OpenActivateForm, setOpenActivateForm] = useState(false);
    const [Copied, setCopied] = useState({ id: '', status: false });
    const [payoutForm, setPayoutForm] = useState({
        "otp_code": "",
        "payout_method": "",
        "account_name": "",
        "account_number": "",
        "amount": "",
        panel: false
    });
    const [cashinForm, setCashinForm] = useState({ "payout_method": "", "amount": "", panel: false });
    const [tokenForm, setTokenForm] = useState({ "amount": "", panel: false });
    const [sendFundForm, setSendFundForm] = useState(false);

    const [payoutlist, setPayoutList] = useState([])
    const [balance, setBalance] = useState(props.userInfo?.balance)
    const [token, setToken] = useState(props.userInfo?.token)

    const [totalpayout, setTotalPayout] = useState(0)

    const [insufficientalert, setInsufficientAlert] = useState(false);
    const [incorrectpassword, setIncorrectPassword] = useState(false);
    const [accountName, setAccountName] = useState(false);
    const [accountNumber, setAccountNumber] = useState(false);
    const [amountRequest, setAmountRequest] = useState(false);
    const [otpcode, setOTPCode] = useState('');
    const [password, setPassword] = useState(false);
    const [payout_method, setPayoutMethod] = useState('');
    const [topupsidebar, setTopupSidebar] = useState({ "open": false });

    // console.log(balance)

    const [resendAction, setResendAction] = useState(false);
    const [time, setTime] = useState(null);

    const [usdRate, setUSDRate] = useState(0);
    const [avatarImage, setAvatarImage] = useState(null)

    useMemo(async () => {
        // var formData = new FormData();
        // formData.append("admin_dashboard", 1);
        await axios({
            method: "GET",
            url: 'https://api.exchangerate-api.com/v4/latest/USD',
            // data: formData,
        }).then((res) => {
            // console.log(res.data.rates.PHP)
            setUSDRate(res.data.rates.PHP)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, payoutForm.amount, cashinForm.amount, tokenForm.amount]);

    useMemo(async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV, location.key]);

    const payout_list = async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }

    const [SendFundInput, setSendFundInput] = useState({ user_id_to: null, amount: '' });

    const SendFund = async () => {

        if (SendFundInput.user_id_to != '' && SendFundInput.amount != '') {
            if (Number(SendFundInput.amount) != 0) {
                if (SendFundInput.user_id_to != localStorage.getItem('user_id')) {
                    var formData = new FormData();
                    formData.append("sendFund", 1);
                    formData.append("user_id", localStorage.getItem('user_id'));
                    formData.append("user_id_to", SendFundInput.user_id_to);
                    formData.append("amount", SendFundInput.amount);
                    formData.append("usdRate", 55);
                    await axios({
                        method: "post",
                        // url: 'http://192.168.1.13/drinkgood/process.php',
                        url: ENV.DOMAIN + 'process.php',
                        data: formData,
                    }).then((res) => {
                        if (res.data == 'ok') {
                            swal({
                                // title: "Done!",
                                text: "Successfully Sent",
                                icon: "success",
                                timer: 1000,
                                button: false,
                            }).then(() => {
                                window.location.assign('/wallet')
                            });
                        } else {
                            swal({
                                // title: "Done!",
                                text: "you have insufficient balance",
                                icon: "error",
                                timer: 2000,
                                button: false,
                            });
                        }
                        // setPayoutList(res.data)
                        // alert(JSON.stringify(res.data));
                    }).catch((err) => {

                    });
                } else {
                    swal({
                        // title: "Done!",
                        text: "Invalid",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    });
                }
            } else {

            }
        } else {

        }
    }


    const btnstatus = (item) => {
        if (item.status == 'deducted') {
            return (<span style={{ color: "red", fontSize: 10 }}>Deducted</span>);
        } else if (item.status == 'cancelled') {
            return (<span style={{ color: "red", fontSize: 10 }}>Cancelled</span>);
        } else if (item.status == 'declined') {
            return (<span style={{ color: "red", fontSize: 10 }}>Declined</span>);
        } else if (item.status == 'received') {
            return (<span style={{ color: "blue", fontSize: 10 }}>Recevied Fund</span>);
        } else if (item.status == 'transferred') {
            return (<span style={{ color: "blue", fontSize: 10 }}>Sent Fund</span>);
        } else if (item.status == 'approved') {
            return (<span style={{ color: "green", fontSize: 10 }}>Approved</span>);
        } else if (item.status == 'weekly_bonus') {
            return (<span style={{ color: "green", fontSize: 10 }}>Weekly Bonus</span>);
        } else if (item.status == 'topup') {
            return (<span style={{ color: "green", fontSize: 10 }}>Top-up</span>);
        } else {
            return (<a onClick={() => CancelRequest(item, 'cancelled')} style={{ width: 100, fontSize: 10, color: "red", textDecoration: "underline" }}>Cancel Payout</a>);
        }
    }

    const Status = (item) => {
        if (item.status == 'pending') {
            return item.status
        }
    }


    const CancelRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("cancel_payout_request", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("id", item.id);
        formData.append("amount", item.amount);
        formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setBalance(res.data[0].balance)
            payout_list()
        }).catch((err) => {

        });

    }

    const sendRequest = async () => {
        if (String(payoutForm.account_name) != '' && payoutForm.account_number != '' && String(payoutForm.payout_method) != '' && Number(payoutForm.amount) != 0 && props.userInfo.mobile != '' && payoutForm.otp_code != '') {
            if (!payoutForm.selfie_image) {
                swal({
                    // title: "Done!",
                    text: "Please take a picture Selfie",
                    icon: "info",
                    timer: 1000,
                    button: false,
                });
                return;
            }

            // alert(JSON.stringify(payoutForm))
            // return;


            if (Number(payoutForm.amount) <= Number(props.userInfo?.balance * 55).toFixed(0)) {
                var formData = new FormData();
                formData.append("payout_request", 1);
                formData.append("inputvalue", JSON.stringify({ ...payoutForm, "rate": 55, "user_id": localStorage.getItem('user_id') }));

                await axios({
                    method: "post",
                    // url: 'http://192.168.1.13/drinkgood/process.php',
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    alert(JSON.stringify(res.data))
                    if (res.data[0].status == 'success') {
                        setInsufficientAlert(false)
                        props.setUserInfo(prev => ({ ...prev, "balance": res.data[0].balance }))
                        setBalance(res.data[0].balance)
                        payout_list()
                        setPayoutForm(prev => ({ ...prev, "amount": 0, "payment_method": "", panel: false }))
                        setOTPCode('')
                    }
                    else if (res.data[0].status == 'insufficient') {
                        alert(JSON.stringify(res.data))
                        setInsufficientAlert(true)
                    }
                    else if (res.data[0].status == 'expire_otp') {
                        swal({
                            text: "Expite OTP Code",
                            icon: "error",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            setOTPCode('')
                        });
                    }
                }).catch((err) => {

                });
            } else {
                swal({
                    title: "Invalid!",
                    text: "Insufficient Balance",
                    icon: "error",
                    timer: 2000,
                    button: false,
                });
            }
        } else {
            // alert('dsf')
        }

    }

    const CashinRequest = async () => {
        // alert(JSON.stringify({ ...cashinForm, "user_id": localStorage.getItem('user_id') }))
        // return;
        var formData = new FormData();
        formData.append("cashin", 1);
        formData.append("value", JSON.stringify({ ...cashinForm, "rate": 55, "user_id": localStorage.getItem('user_id') }));
        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setPayoutList(res.data[0].data)
            setBalance(res.data[0].balance)
            setCashinForm(prev => ({ ...prev, "amount": 0, "payment_method": "", "image": "", panel: false }))
            // setTime(null)
            // swal({
            //     title: "Done!",
            //     text: "Successfull added contact number",
            //     icon: "success",
            //     timer: 1000,
            //     button: false,
            // }).then(() => {
            // setResendAction(false)
            //     console.log(input)
            // });
        });
    }

    const BuyToken = async () => {
        // console.log(tokenForm.amount, (props.userInfo?.balance * usdRate).toFixed(0), Number(tokenForm.amount)<=Number(props.userInfo?.balance * usdRate).toFixed(0))
        // return;
        // if ((props.userInfo?.balance * usdRate).toFixed(0) > 0) {
        if (Number(props.userInfo?.balance * 55).toFixed(2) >= Number(tokenForm.amount)) {
            var formData = new FormData();
            formData.append("buytoken", 1);
            formData.append("value", JSON.stringify({ ...tokenForm, "rate": 55, "user_id": localStorage.getItem('user_id') }));
            await axios({
                method: "post",
                // url: 'http://192.168.1.13/drinkgood/process.php',
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                // alert(JSON.stringify(res.data))
                // setPayoutList(res.data[0].data)
                // setBalance(res.data[0].balance)
                // setCashinForm({ panel: false })
                if (res.data[0].status === 'success') {
                    // setBalance(res.data[0].balance)
                    // setToken(res.data[0].token)
                    setPayoutList(res.data[0].data)
                    setTokenForm(prev => ({ ...prev, "amount": "", panel: false }));
                    props.setUserInfo(prev => ({ ...prev, "balance": res.data[0].balance, "token": res.data[0].token }));
                } else {
                    alert(JSON.stringify(res.data))
                    setTokenForm(prev => ({ ...prev, "amount": "", panel: false }));
                }
                // setTime(null)
                // swal({
                //     title: "Done!",
                //     text: "Successfull added contact number",
                //     icon: "success",
                //     timer: 1000,
                //     button: false,
                // }).then(() => {
                // setResendAction(false)
                //     console.log(input)
                // });
            });
        } else {
            swal({
                title: "Invalid!",
                text: "Insufficient Balance",
                icon: "error",
                timer: 2000,
                button: false,
            });
        }
        // } else {
        //     swal({
        //         title: "Invalid!",
        //         text: "Insufficient Balance",
        //         icon: "error",
        //         timer: 2000,
        //         button: false,
        //     });
        // }
    }

    const ConvertToken = async () => {
        // alert(JSON.stringify({...tokenForm, "rate": usdRate, "user_id": localStorage.getItem('user_id')}))
        // return;
        if (tokenForm.amount === "" || tokenForm.amount === 0) {
            swal({
                title: "Invalid!",
                text: "Please input Amount",
                icon: "error",
                timer: 2000,
                button: false,
            });
        } else {
            if (Number(props.userInfo?.token * 55).toFixed(2) >= tokenForm.amount) {
                var formData = new FormData();
                formData.append("converttoken", 1);
                formData.append("value", JSON.stringify({ ...tokenForm, "rate": 55, "user_id": localStorage.getItem('user_id') }));
                await axios({
                    method: "post",
                    // url: 'http://192.168.1.13/drinkgood/process.php',
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data)
                    // alert(JSON.stringify(res.data))
                    // setPayoutList(res.data[0].data)
                    // setBalance(res.data[0].balance)
                    // setCashinForm({ panel: false })
                    if (res.data[0].status === 'success') {
                        // setBalance(res.data[0].balance)
                        // setToken(res.data[0].token)
                        setPayoutList(res.data[0].data)
                        setTokenForm(prev => ({ ...prev, "amount": "", panel: false }));
                        props.setUserInfo(prev => ({ ...prev, "balance": res.data[0].balance, "token": res.data[0].token }));
                    } else {
                        alert(JSON.stringify(res.data))
                        setTokenForm(prev => ({ ...prev, "amount": "", panel: false }));
                    }
                    // setTime(null)
                    // swal({
                    //     title: "Done!",
                    //     text: "Successfull added contact number",
                    //     icon: "success",
                    //     timer: 1000,
                    //     button: false,
                    // }).then(() => {
                    // setResendAction(false)
                    //     console.log(input)
                    // });
                });
            } else {
                swal({
                    title: "Invalid!",
                    text: "Insufficient Balance",
                    icon: "error",
                    timer: 2000,
                    button: false,
                });
            }
        }
    }


    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = async (e) => {
                setCashinForm(prev => ({ ...prev, "image": e.target.result }))
                // console.log(event.target.files[0])
                // setimageArray([...imagearray, { 
                //     filename: event.target.files[0].name, 
                //     filetype: event.target.files[0].type, 
                //     filezise: event.target.files[0].size, 
                //     image: e.target.result
                // }])
                // setAvatarImage(e.target.result)
                // setimageDisplayOne(e.target.result)
                // const split = event.target.files[0].name.split('.')[1]
                // console.log(split)
            }
        }
    };

    // const [userInfo, setUserInfo] = useState(null);

    // useMemo(async () => {

    //     var formData = new FormData();
    //     formData.append("profile", 1);
    //     formData.append("user_id", localStorage.getItem('user_id'));

    //     await axios({
    //         method: "post",
    //         url: 'http://192.168.1.13/drinkgood/process.php',
    //         // url: ENV.DOMAIN + 'process.php',
    //         data: formData,
    //     }).then((res) => {
    //         setBalance(res.data[0].balance)
    //         setToken(res.data[0].token)
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, [ENV]);


    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    const onLongPress = () => {
        console.log('longpress is triggered');
    };

    const onClick = () => {
        console.log('click is triggered')
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    // <button {...longPressEvent}>use  Loooong  Press</button>

    // useEffect(()=>{

    //     history.pushState(null, null, window.location.href);
    //     window.onpopstate = function () {
    //         history.go(1);
    //     };
    // },[history])



    const [fix, setFix] = useState(true)

    function setFixed() {
        if (window.scrollY >= 58) {
            // console.log(false)
            setFix(false)
        } else {
            setFix(true)
            // console.log(false)
        }
        // console.log(window.scrollY)
    }

    useMemo(() => {
        window.addEventListener('scroll', setFixed)
    }, [])


    const sendOTP = async () => {
        setResendAction(true)
        var formData = new FormData();
        formData.append("send_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", props.userInfo.mobile);
        formData.append("type", "payout");
        if (props.userInfo.mobile != '') {
            await axios({
                method: "post",
                // url: 'http://192.168.1.13/drinkgood/process.php',
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                // alert(res.data)
                // setTime(null)
                // swal({
                //     title: "Done!",
                //     text: "Successfull added contact number",
                //     icon: "success",
                //     timer: 1000,
                //     button: false,
                // }).then(() => {
                // setResendAction(false)
                //     console.log(input)
                // });
            });
        }
    }


    const HistoryPanel = (item, key) => {


        if (item?.status == 'approved') {
            return (
                <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600, display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                                {item.payout_method}
                                {
                                    item.type === 'cashin' ?
                                        <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Cashin]</span>
                                        :
                                        <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Payout]</span>
                                }
                            </div>
                        </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            {
                                item.type === 'cashin' ?
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "green" }}>+USD {Number(item.amount)}</span>
                                        <span style={{ color: "green", fontSize: 12 }}>PHP {Number(item.amount * 55).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "red" }}>-USD {(Number(item.amount) + Number(item.fee)).toFixed(2)}</span>
                                        <span style={{ color: "green", fontSize: 12 }}>PHP {Number(item.amount * 55).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        item.type === 'cashin' ?
                            null
                            :
                            <div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        Amnt: ${item.amount}
                                    </div>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        Fee: ${item.fee}
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                        <small>Account Name</small>
                                        <br />
                                        {item.account_name}
                                    </div>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                        {
                                            item.status == 'transferred' ?
                                                <small>Account ID</small>
                                                :
                                                <small>Account Number</small>
                                        }
                                        <br />
                                        {item.account_number}
                                    </div>
                                </div>
                            </div>
                    }
                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                        <div>{item.date_approved !== '' ? item.date_approved : item.date_payout}</div>
                        <div>{btnstatus(item)}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'cancelled') {
            return (
                <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600, display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>{item.payout_method}<span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[payout]</span></div>
                        </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            {
                                item.type === 'cashin' ?
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "green" }}><strike>+USD {Number(item.amount)}</strike></span>
                                        <span style={{ color: "green", fontSize: 12 }}><strike>PHP {Number(item.amount * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strike></span>
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "rgb(179, 177, 177)" }}><strike>-USD {(Number(item.amount) + Number(item.fee)).toFixed(2)}</strike></span>
                                        <span style={{ color: "rgb(179, 177, 177)", fontSize: 12 }}><strike>PHP {Number(item.amount * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strike></span>
                                    </div>
                            }
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Amnt: ${item.amount}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Fee: ${item.fee}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            <small>Account Name</small>
                            <br />
                            {item.account_name}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            {
                                item.status == 'transferred' ?
                                    <small>Account ID</small>
                                    :
                                    <small>Account Number</small>
                            }
                            <br />
                            {item.account_number}
                        </div>
                    </div>
                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                        <div>{item.date_approved}</div>
                        <div>{btnstatus(item)}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'pending') {
            return (
                <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600, display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                                {item.payout_method}
                                {
                                    item.type === 'cashin' ?
                                        <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Cashin]</span>
                                        :
                                        <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Payout]</span>
                                }
                            </div>
                        </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            {
                                item.type === 'cashin' ?
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "green" }}>+USD {Number(item.amount)}</span>
                                        <span style={{ color: "green", fontSize: 12 }}>PHP {Number(item.amount * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "red" }}>-USD {(Number(item.amount) + Number(item.fee)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        <span style={{ color: "red", fontSize: 12 }}>PHP {Number(Number(item.amount * 55) + Number(item.fee * 55)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        item.type === 'cashin' ?
                            null
                            :
                            <div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        Amnt: ${item.amount}
                                    </div>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        Fee: ${item.fee}
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                        <small>Account Name</small>
                                        <br />
                                        {item.account_name}
                                    </div>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                        {
                                            item.status == 'transferred' ?
                                                <small>Account ID</small>
                                                :
                                                <small>Account Number</small>
                                        }
                                        <br />
                                        {item.account_number}
                                    </div>
                                </div>
                            </div>
                    }
                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                        <div>{item.date_approved !== '' ? item.date_approved : item.date_payout}</div>
                        <div>{item.type === 'cashin' ? <span style={{ color: "orange" }}>{item.status}</span> : btnstatus(item)}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'deducted') {
            return (
                <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ fontSize: 12, fontWeight: 600 }}>Deduction</div>
                        </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            <span style={{ color: "red" }}>-${(Number(item.amount) + Number(item.fee)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>{item.description}</div>
                    </div>
                    <div>
                        <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'declined') {
            return (
                <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600, display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                                {item.payout_method}
                                {
                                    item.type === 'cashin' ?
                                        <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Cashin]</span>
                                        :
                                        <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Payout]</span>
                                }
                            </div>
                        </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            {
                                item.type === 'cashin' ?
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "green" }}>+USD {Number(item.amount)}</span>
                                        <span style={{ color: "green", fontSize: 12 }}>PHP {Number(item.amount * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ color: "red" }}>-USD {(Number(item.amount) + Number(item.fee)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        <span style={{ color: "red", fontSize: 12 }}>PHP {Number(Number(item.amount * 55) + Number(item.fee * 55)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        item.type === 'cashin' ?
                            null
                            :
                            <div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        Amnt: ${item.amount}
                                    </div>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        Fee: ${item.fee}
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                        <small>Account Name</small>
                                        <br />
                                        {item.account_name}
                                    </div>
                                    <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                        {
                                            item.status == 'transferred' ?
                                                <small>Account ID</small>
                                                :
                                                <small>Account Number</small>
                                        }
                                        <br />
                                        {item.account_number}
                                    </div>
                                </div>
                            </div>
                    }
                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                        <div>{item.date_approved !== '' ? item.date_approved : item.date_payout}</div>
                        <div>{item.type === 'cashin' ? <span style={{ color: "orange" }}>{item.status}</span> : btnstatus(item)}</div>
                    </div>
                </div>
            )
        } else {
            if (item.type === 'token_buy') {
                return (
                    <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                <div style={{ fontSize: 15, fontWeight: 600 }}>
                                    Buy Credits
                                    {/* <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Convert]</span> */}
                                </div>
                            </div>
                            <div style={{ flex: 1, textAlign: "right" }}>
                                {
                                    item.type === 'cashin' ?
                                        <div>
                                            <div style={{ color: "green" }}>+USD {Number(item.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            <div style={{ color: "green", fontSize: 12 }}>PHP {(Number(item.amount * 55)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ color: "red" }}>-USD {(Number(item.amount)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            <div style={{ color: "red", fontSize: 12 }}>PHP {(Number(item.amount * 55)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                            <div>{item.date_payout}</div>
                            {/* <div>{btnstatus(item)}</div> */}
                        </div>
                    </div>
                )
            } else
                if (item.type === 'token_convert') {
                    return (
                        <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                    <div style={{ fontSize: 15, fontWeight: 600 }}>
                                        Convert Credits
                                        {/* <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Convert]</span> */}
                                    </div>
                                </div>
                                <div style={{ flex: 1, textAlign: "right" }}>
                                    <div style={{ color: "green" }}>+USD {Number(item.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                    <div style={{ color: "green", fontSize: 12 }}>PHP {(Number(item.amount * 55)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                </div>
                            </div>
                            <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                                <div>{item.date_payout}</div>
                                {/* <div>{btnstatus(item)}</div> */}
                            </div>
                        </div>
                    )
                } else
                    if (item.type === 'token_activation') {
                        return (
                            <div key={key} style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                    <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                        <div style={{ fontSize: 15, fontWeight: 600 }}>
                                            Activation
                                            {/* <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[Convert]</span> */}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1, textAlign: "right" }}>
                                        <div style={{ color: "red" }}>-USD {Number(item.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                        <div style={{ color: "red", fontSize: 12 }}>PHP {(Number(item.amount * 55)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                    </div>
                                </div>
                                <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                                    <div>{item.date_payout}</div>
                                    {/* <div>{btnstatus(item)}</div> */}
                                </div>
                            </div>
                        )
                    }
        }
    }



    const [leftright, setLeftRight] = useState('Left')
    const refArea = useRef()

    let touchArea = document.getElementById("touch-area");
    let mouseX, initialX = 0;
    let mouseY, initialY = 0;
    let isSwiped = false;

    let events = {
        mouse: {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
        },
        touch: {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
        }
    }

    let deviceType = "";

    // Detect touch device

    const isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (error) {
            deviceType = "mouse";
            return false;
        }
    }

    isTouchDevice();

    // console.log(events[deviceType].down)

    let rectLeft = 0; //touchArea.getBoundingClientRect([0]).left;
    let rectTop = 0; //touchArea.getBoundingClientRect([0]).top;
    // useEffect(()=>{
    //     if (props.userInfo) {
    rectLeft = touchArea?.getBoundingClientRect().left;
    rectTop = touchArea?.getBoundingClientRect().top;
    //     }
    // }, [])

    const getXY = (e) => {
        mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
        mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    }

    // isTouchDevice();

    // console.log(touchArea)


    touchArea?.addEventListener(events[deviceType].down, (event) => {
        isSwiped = true;
        getXY(event);
        initialX = mouseX;
        initialY = mouseY;
    });

    let diffX = 0;
    let diffY = 0;

    touchArea?.addEventListener(events[deviceType].move, (event) => {
        if (!isTouchDevice()) {
            event.preventDefault();
        }
        if (isSwiped) {
            getXY(event);
            diffX = mouseX - initialX;
            diffY = mouseY - initialY;
            if (Math.abs(diffY) > Math.abs(diffX)) {
                // console.log(diffY > 0 ? "Down" : "Up")
                // output.innerText = diffY > 0 ? "Down" : "Up"
            } else {
                // console.log(diffX > 0 ? "Right" : "Left")
                // if((diffY > 0 ? "Down" : "Up") !== "Down" || (diffY > 0 ? "Down" : "Up") !== "Up"){
                // setLeftRight(diffX > 0 ? "Right" : "Left")
                // }
                // output.innerText = diffX > 0 ? "Right" : "Left"
                if (leftright !== 'Right') {
                    if (Math.abs(diffX) <= 50) {
                        setLeftRight(diffX > 0 ? "Right" : "Left")
                    }
                }
            }
        }
    });

    touchArea?.addEventListener(events[deviceType].up, (event) => {
        isSwiped = false;
    });

    touchArea?.addEventListener("mouseleave", (event) => {
        isSwiped = false;
    });

    window.onload = () => {
        isSwiped = false;
    }

    // if (isSwiped) {
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // }



    const Topup = () => {
        // console.log(topupsidebar)
    }

    
    if (sessionStorage.getItem('session_page')) {

    } else {
        window.location.href = '/dashboard';
        return (<>{sessionStorage.getItem('session_page')}Redirecting...</>);
    }

    return (
        <div className="wrapper" ref={refArea} id='touch-area'>

            <LeftNavSwiper userInfo={props.userInfo} leftright={leftright} setLeftRight={setLeftRight} tab="wallet" />
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{ flex: 1, height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div onClick={() => setLeftRight('Right')} style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bars'></span>
                                </div>
                            </div>
                            <div style={{ flex: 4, paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <img src={require('./sdsadasd4a65sd51a56s4da.png')} style={{ width: 45, height: 45 }} />
                                <div style={{ display: "flex", flexDirection: "column", color: "green", lineHeight: 1 }}>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute" }}>Mirabile</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7 }}>&</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12 }}>Proventus
                                        <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5 }}>Co.</span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bell'></span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Header />
            }
            {/* /.navbar */}

            {/* ////// TOPUP ////// */}
            <Sidebar
                icons={
                    <div style={{ width: "100%", fontWeight: "bolder" }}>Top-up</div>
                }
                visible={dialog === 'topup' ? true : false}
                blockScroll={true}
                showCloseIcon={false}
                position="bottom"
                style={{ minHeight: 500, borderRadius: 0, backgroundColor: "#ddd" }}
                onHide={() => history.goBack()}
            >
                <div style={{ flex: 1 }}>
                    <div style={{ padding: "10px 0px" }}>
                        <input type='number' name='amount' onChange={(e) => setTopupSidebar(prev => ({ ...prev, [e.target.name]: e.target.value }))} style={{ border: "none" }} placeholder='Amount' className='form-control' />
                    </div>
                    <div style={{ padding: "10px 0px" }}>
                        <input name='reference_number' onChange={(e) => setTopupSidebar(prev => ({ ...prev, [e.target.name]: e.target.value }))} style={{ border: "none" }} placeholder='Reference Number' className='form-control' />
                    </div>
                    <div style={{ padding: "10px 0px" }}>
                        <div style={{ width: 150, height: 200, backgroundColor: "#fff" }}>

                        </div>
                    </div>
                    <div style={{ padding: "10px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ flex: 1 }}></div>
                        <div style={{ flex: 1 }}>
                            <button onClick={Topup} style={{ width: 150, height: 50, borderRadius: 10, border: "none", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", backgroundColor: "#609af8", color: "#fff" }}>Top-up</button>
                        </div>
                    </div>
                </div>
            </Sidebar>
            {/* ////// PAYOUT ////// */}
            <Sidebar
                icons={
                    <div style={{ width: "100%", "fontSize": 25, "fontWeight": 800 }}>Payout</div>
                }
                visible={dialog === 'payout' ? true : false}
                blockScroll={true}
                showCloseIcon={false}
                position="bottom"
                style={{ minHeight: "100vh", borderRadius: 0 }}
                onHide={() => history.goBack()}
            >
                {/* Payout Method */}
                {/* Account Name */}
                {/* Account Number */}
                {/* Enter Amount */}
                {/* Take a Selfie */}
                {/* OTP (Code) */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <div>
                        <label>Payout Method</label>
                        <select name="payout_method" className="form-control">
                            <option value>Select...</option>
                            <option value="GCash">GCash</option>
                            <option value="GoTyme">GoTyme</option>
                        </select>
                    </div>
                    <div><label>Account Name</label><input type="text" name="account_name" className="form-control" placeholder="Account Name" /></div>
                    <div><label>Account Number</label><input type="text" name="account_number" className="form-control" placeholder="Account Number" /></div>
                    <div><label>Enter Amount</label><input type="number" name="amount" className="form-control" placeholder={0.00} /></div>
                    <div>
                        <label>Take a Selfie</label>
                        <div style={{ width: 120, height: 170, borderRadius: 5, border: '1px solid rgb(221, 221, 221)', backgroundColor: 'rgb(255, 255, 255)' }} />
                    </div>
                    <div>
                        <label>OTP <span style={{ color: 'rgb(136, 136, 136)' }}>(Code)</span></label>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 5 }}>
                            <input type="number" name="otp_code" className="form-control" placeholder style={{ width: '100%', textAlign: 'center' }} defaultValue />
                            <div style={{ width: 200, textAlign: 'center' }}>
                                <div>63927****0610</div>
                                <div style={{ textDecoration: 'underline' }}>Send OTP</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexGrow: 1, gap: 5 }}>
                        <button className="btn btn-primary btn-block">Request Payout</button>
                        <button onClick={() => history.goBack()} className="btn btn-danger">Cancel</button>
                    </div>
                </div>
            </Sidebar>
            {/* ////// PAYOUT ////// */}
            <Sidebar
                icons={
                    <div style={{ width: "100%", "fontSize": 25, "fontWeight": 800 }}>Convert</div>
                }
                visible={false}
                blockScroll={true}
                showCloseIcon={false}
                position="bottom"
                style={{ minHeight: "100vh", borderRadius: 0 }}
                onHide={() => history.goBack()}
            >

            </Sidebar>



            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '100vh' }}>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12" style={{ padding: "5px 0px 0px 0px" }}>
                                {/* <button {...longPressEvent}>use  Loooong  Press</button> */}


                                {/* <div style={{flex: 1, backgroundColor: "#fff", borderRadius: 10, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", padding: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)"}}>
                                    <div style={{flex: 2, display: "flex", flexDirection: "column"}}>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ddd"}}>
                                            <span style={{ fontSize: 13, fontWeight: 600 }}>Reward: ${balance ?? 0}</span>
                                            <span style={{ fontSize: 13, fontWeight: "normal", textDecoration: "underline" }}>Convert</span>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <span style={{ fontSize: 18, fontWeight: 600 }}>Balance: ${balance ?? 0}</span>
                                        </div>
                                        <span style={{ fontSize: 15 }}>Total Payout: ${props.userInfo?.total_payout ?? 0}</span>
                                    </div>
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", gap: 10}}>
                                        <Link to={"/wallet/topup"}>
                                            <button className='btn btn-primary btn-sm'>Topup</button>
                                        </Link>
                                        <Link to={"/wallet/payout"}>
                                            <button className='btn btn-warning btn-sm'>Payout</button>
                                        </Link>                                        
                                    </div>
                                </div>

                                <hr /> */}

                                {
                                    !fix ?
                                        <div>
                                            <div style={{ position: "fixed", zIndex: 2, width: '100%', transition: 'width 5s, transform 2s', top: 0, left: 0, paddingLeft: 0, paddingRight: 0, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)" }}>
                                                <div style={{ width: "100%", borderRadius: 0, borderBottom: "1px solid #ddd", display: "flex", flexDirection: "column", padding: 10, backgroundColor: "#fff", backgroundImage: "url(https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.revivedwater.eu%2F&psig=AOvVaw2VtGmCLGF8-9YRdBtzlDPr&ust=1699197739534000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKD-9_7SqoIDFQAAAAAdAAAAABAE)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat, repeat" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                                                        <div style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center" }}>
                                                            <span style={{ fontWeight: "normal", fontSize: 12 }}>UPCOMING EARNING: <span style={{ fontWeight: 600, fontSize: 12 }}>USD {Number(props.userInfo?.upcoming_earning).toFixed(2)} PHP {Number(props.userInfo?.upcoming_earning * 55).toFixed(2)}</span></span>
                                                            {/* <span style={{ fontWeight: "normal", fontSize: 12, color: "#333" }}>(₱{Number(props.userInfo?.upcoming_earning * usdRate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</span> */}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center" }}>
                                                        <span style={{ fontSize: 25, fontWeight: 600 }}>Balance: USD{Number(props.userInfo?.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0} </span>
                                                        <span style={{ fontSize: 12, fontWeight: "normal" }}>(PHP{Number(props.userInfo?.balance * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0})</span>
                                                    </div>
                                                    {/* <div style={{ fontSize: 12, fontWeight: 600 }}>₱{Number(balance*usdRate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0} </div> */}
                                                    {/* <span style={{color:"rgb(137 135 135)", fontSize:12}}>(PHP {Number(balance*usdRate).toFixed(2)})</span> */}
                                                    <div style={{ fontSize: 12, fontWeight: 500, display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                        <span style={{ fontSize: 12, fontWeight: 500, display: "flex", flexDirection: "row", gap: 5 }}>
                                                            <span>Credits: USD <span style={{ fontWeight: "bolder" }}>{Number(props.userInfo?.token).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span></span>
                                                            <span>(PHP <span style={{ fontWeight: "bolder" }}>{Number(props.userInfo?.token * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0})</span></span>
                                                        </span>
                                                        {
                                                            tokenForm.panel ?
                                                                null
                                                                :
                                                                <div style={{ display: "flex", gap: 10 }}>
                                                                    {
                                                                        (props.userInfo?.balance * 55).toFixed(0) > 0 ?
                                                                            <a onClick={() => {
                                                                                setTokenForm(prev => ({ ...prev, panel: true, type: "buy" }))
                                                                                setCashinForm(prev => ({ ...prev, panel: false }))
                                                                                setPayoutForm(prev => ({ ...prev, panel: false }))
                                                                            }} style={{ color: "red", textDecoration: "underline" }}>Buy</a>
                                                                            : null
                                                                    }
                                                                    {
                                                                        (props.userInfo?.token * 55).toFixed(0) > 0 ?
                                                                            <a onClick={() => {
                                                                                setTokenForm(prev => ({ ...prev, panel: true, type: "convert" }))
                                                                                setCashinForm(prev => ({ ...prev, panel: false }))
                                                                                setPayoutForm(prev => ({ ...prev, panel: false }))
                                                                            }} style={{ color: "green", textDecoration: "underline" }}>Convert</a>
                                                                            : null
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                    <div style={{ fontSize: 12, fontWeight: 500 }}>Total Payout: PHP {Number(props.userInfo?.total_payout * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</div>
                                                    {
                                                        Number(props.userInfo?.pending_payout) != 0 ?
                                                            <div style={{ fontSize: 12, fontWeight: 500 }}>Pending Payout: PHP {Number(props.userInfo?.pending_payout * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</div>
                                                            : null
                                                    }

                                                    <>
                                                        {
                                                            props.userInfo?.close_payout ?
                                                                <>
                                                                    {
                                                                        balance == '0.00' ?
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                                {
                                                                                    cashinForm.panel ?
                                                                                        <div></div> :
                                                                                        <button onClick={() => {
                                                                                            setCashinForm(prev => ({ ...prev, panel: true }));
                                                                                            setTokenForm(prev => ({ ...prev, panel: false }));
                                                                                            setPayoutForm(prev => ({ ...prev, panel: false }));
                                                                                        }} className='btn btn-info btn-sm'>Cash-In</button>
                                                                                }
                                                                                <KYCStatus userInfo={props.userInfo}>
                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                </KYCStatus>
                                                                            </div>
                                                                            :
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                                {/* {
                                                                                            userInfo.sendFund_oprate == true ?
                                                                                            <div onClick={() => {
                                                                                                setSendFundForm(true)
                                                                                                setPayoutForm(false);
                                                                                            }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                            :
                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                        }*/}
                                                                                {
                                                                                    cashinForm.panel ?
                                                                                        <div></div> :
                                                                                        <button onClick={() => {
                                                                                            setCashinForm(prev => ({ ...prev, panel: true }));
                                                                                            setTokenForm(prev => ({ ...prev, panel: false }));
                                                                                            setPayoutForm(prev => ({ ...prev, panel: false }));
                                                                                        }} className='btn btn-info btn-sm'>Cash-In</button>
                                                                                }

                                                                                {
                                                                                    payoutForm.panel ?
                                                                                        null
                                                                                        :
                                                                                        props.userInfo.account_status == 1 ?
                                                                                            <KYCStatus userInfo={props.userInfo}>
                                                                                                <div onClick={() => {
                                                                                                    setCashinForm(prev => ({ ...prev, panel: false }));
                                                                                                    setTokenForm(prev => ({ ...prev, panel: false }));
                                                                                                    setPayoutForm(prev => ({ ...prev, panel: true }));
                                                                                                }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                            </KYCStatus>
                                                                                            :
                                                                                            <KYCStatus userInfo={props.userInfo}>
                                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                            </KYCStatus>

                                                                                }
                                                                            </div>
                                                                    }
                                                                </>
                                                                :
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                    {
                                                                        cashinForm.panel ?
                                                                            <div></div> :
                                                                            <button onClick={() => {
                                                                                setCashinForm(prev => ({ ...prev, panel: true }));
                                                                                setTokenForm(prev => ({ ...prev, panel: false }));
                                                                                setPayoutForm(prev => ({ ...prev, panel: false }));
                                                                            }} className='btn btn-info btn-sm'>Cash-In</button>
                                                                    }
                                                                    <KYCStatus userInfo={props.userInfo}>
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                    </KYCStatus>
                                                                </div>
                                                        }
                                                    </>


                                                </div>
                                            </div>
                                            <div style={{ height: 119, backgroundColor: "#fff" }}></div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ width: "100%", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", display: "flex", flexDirection: "column", padding: 10, marginBottom: 10, backgroundColor: "#fff", backgroundImage: "url(https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.revivedwater.eu%2F&psig=AOvVaw2VtGmCLGF8-9YRdBtzlDPr&ust=1699197739534000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKD-9_7SqoIDFQAAAAAdAAAAABAE)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat, repeat" }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                                                    <div style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center" }}>
                                                    <span style={{ fontWeight: "normal", fontSize: 12 }}>UPCOMING EARNING: <span style={{ fontWeight: "normal", fontSize: 12 }}>USD {Number(props.userInfo?.upcoming_earning).toFixed(2)} (PHP {Number(props.userInfo?.upcoming_earning * 55).toFixed(2)})</span></span>
                                                        {/* <span style={{ fontWeight: "normal", fontSize: 12, color: "#333" }}>(₱{Number(props.userInfo?.upcoming_earning * usdRate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</span> */}
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center" }}>
                                                    <span style={{ fontSize: 25, fontWeight: 600 }}>Balance: USD{Number(props.userInfo?.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0} </span>
                                                    <span style={{ fontSize: 12, fontWeight: "normal" }}>(PHP{Number(props.userInfo?.balance * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0})</span>
                                                </div>
                                                {/* <div style={{ fontSize: 12, fontWeight: 600 }}>₱{Number(balance*usdRate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0} </div> */}
                                                {/* <span style={{color:"rgb(137 135 135)", fontSize:12}}>(PHP {Number(balance*usdRate).toFixed(2)})</span> */}
                                                <div style={{ fontSize: 12, fontWeight: 500, display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                    <span style={{ fontSize: 12, fontWeight: 500, display: "flex", flexDirection: "row", gap: 5 }}>
                                                        <span>Credits: USD <span style={{ fontWeight: "bolder" }}>{Number(props.userInfo?.token).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span></span>
                                                        <span>(PHP <span style={{ fontWeight: "bolder" }}>{Number(props.userInfo?.token * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0})</span></span>
                                                    </span>
                                                    {
                                                        tokenForm.panel ?
                                                            null
                                                            :
                                                            <div style={{ display: "flex", gap: 10 }}>
                                                                {
                                                                    (props.userInfo?.balance * 55).toFixed(0) > 0 ?
                                                                        <a onClick={() => {
                                                                            setTokenForm(prev => ({ ...prev, panel: true, type: "buy" }))
                                                                            setCashinForm(prev => ({ ...prev, panel: false }))
                                                                            setPayoutForm(prev => ({ ...prev, panel: false }))
                                                                        }} style={{ color: "red", textDecoration: "underline" }}>Buy</a>
                                                                        : null
                                                                }
                                                                {
                                                                    (props.userInfo?.token * 55).toFixed(0) > 0 ?
                                                                        <a onClick={() => {
                                                                            setTokenForm(prev => ({ ...prev, panel: true, type: "convert" }))
                                                                            setCashinForm(prev => ({ ...prev, panel: false }))
                                                                            setPayoutForm(prev => ({ ...prev, panel: false }))
                                                                        }} style={{ color: "green", textDecoration: "underline" }}>Convert</a>
                                                                        : null
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                <div style={{ fontSize: 12, fontWeight: 500 }}>Total Payout: PHP {Number(props.userInfo?.total_payout * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</div>
                                                    {
                                                        Number(props.userInfo?.pending_payout) != 0 ?
                                                            <div style={{ fontSize: 12, fontWeight: 500 }}>Pending Payout: PHP {Number(props.userInfo?.pending_payout * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</div>
                                                            : null
                                                    }
                                                <>
                                                    {
                                                        props.userInfo?.close_payout ?
                                                            <>
                                                                {
                                                                    balance == '0.00' ?
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                            {
                                                                                cashinForm.panel ?
                                                                                    <div></div> :
                                                                                    <button onClick={() => {
                                                                                        setCashinForm(prev => ({ ...prev, panel: true }));
                                                                                        setTokenForm(prev => ({ ...prev, panel: false }));
                                                                                        setPayoutForm(prev => ({ ...prev, panel: false }));
                                                                                    }} className='btn btn-info btn-sm'>Cash-In</button>
                                                                            }
                                                                            <KYCStatus userInfo={props.userInfo}>
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                            </KYCStatus>
                                                                        </div>
                                                                        :
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                            {
                                                                                cashinForm.panel ?
                                                                                    <div></div> :
                                                                                    <button onClick={() => {
                                                                                        setCashinForm(prev => ({ ...prev, panel: true }));
                                                                                        setTokenForm(prev => ({ ...prev, panel: false }));
                                                                                        setPayoutForm(prev => ({ ...prev, panel: false }));
                                                                                    }} className='btn btn-info btn-sm'>Cash-In</button>
                                                                            }
                                                                            {
                                                                                payoutForm.panel ?
                                                                                    null
                                                                                    :
                                                                                    props.userInfo.account_status == 1 ?
                                                                                        <KYCStatus userInfo={props.userInfo}>
                                                                                            <div onClick={() => {
                                                                                                setCashinForm(prev => ({ ...prev, panel: false }));
                                                                                                setTokenForm(prev => ({ ...prev, panel: false }));
                                                                                                setPayoutForm(prev => ({ ...prev, panel: true }));
                                                                                            }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                        </KYCStatus>
                                                                                        :
                                                                                        <KYCStatus userInfo={props.userInfo}>
                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                        </KYCStatus>

                                                                            }
                                                                        </div>
                                                                }
                                                            </>
                                                            :
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                                {
                                                                    cashinForm.panel ?
                                                                        <div></div> :
                                                                        <button onClick={() => {
                                                                            setCashinForm(prev => ({ ...prev, panel: true }));
                                                                            setTokenForm(prev => ({ ...prev, panel: false }));
                                                                            setPayoutForm(prev => ({ ...prev, panel: false }));
                                                                        }} className='btn btn-info btn-sm'>Cash-In</button>
                                                                }
                                                                <KYCStatus userInfo={props.userInfo}>
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                </KYCStatus>
                                                            </div>
                                                    }
                                                </>

                                            </div>
                                        </div>
                                }
                                {/* <div style={{paddingTop: 10, paddingBottom: 10}}>
                                    <KYCStatus userInfo={props.userInfo} />
                                </div> */}
                                {/* <div style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                                        <span style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>Link Bank <span style={{fontSize:12, color:"red"}}>(soon)</span></span> <span className='pi pi-credit-card'></span>
                                    </div>
                                <hr /> */}
                                {/* {
                                    sendFundForm ?
                                    <></>
                                    :
                                    <></>
                                } */}
                                {
                                    sendFundForm ?
                                        <>
                                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                                <div>
                                                    <label>Amount:</label>
                                                    <input type='number' name='amount' onChange={(e) => setSendFundInput({ ...SendFundInput, [e.target.name]: e.target.value })} value={SendFundInput.amount} style={{ height: 40, width: "100%", fontSize: 25, padding: 5, outline: "none" }} placeholder='0.00' />
                                                </div>
                                                <div>
                                                    <label>Send to:</label>
                                                    <input type='number' name='user_id_to' onChange={(e) => setSendFundInput({ ...SendFundInput, [e.target.name]: e.target.value })} value={SendFundInput.user_id_to} style={{ height: 40, width: "100%", fontSize: 25, padding: 5, outline: "none" }} placeholder='Account ID' />
                                                </div>
                                            </div>
                                            <div style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>

                                                <div onClick={() => SendFund()} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", fontWeight: 600, paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 40, width: 100 }}>Send</div>

                                                <div onClick={() => {
                                                    // setSendFundForm(false);
                                                    setPayoutForm(prev => ({ ...prev, panel: false }));
                                                    setSendFundInput({ user_id_to: '', amount: '' });
                                                }} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", alignSelf: "flex-end", backgroundColor: "red", fontWeight: 600, paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 40, width: 100 }}>Cancel</div>

                                            </div>
                                        </>
                                        :
                                        <></>
                                }

                                {
                                    payoutForm.panel ?
                                        <div style={{ marginBottom: 50 }}>
                                            <div>
                                                <span style={{ fontSize: 25, fontWeight: "800" }}>Available Payout</span>
                                            </div>
                                            {/* <div><span style={{ fontSize: 25, fontWeight: "800" }}>GCash Account</span></div> */}
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                </svg> */}
                                                <span style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center" }}>
                                                    {/* <span style={{ fontSize: 25, fontWeight: "800" }}>{Number(balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span> */}
                                                    {/* <span style={{ fontSize: 12, fontWeight: "normal", color: "#333" }}>(PHP {Number(balance * usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0})</span> */}
                                                    <span style={{ fontSize: 25, fontWeight: "800" }}>PHP {Number(balance * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                                                </span>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                <div>
                                                    <label>Payout Method</label>
                                                    <select name='payout_method' onChange={(e) => setPayoutForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control">
                                                        <option value={""}>Select...</option>
                                                        <option value={"GCash"}>GCash</option>
                                                        <option value={"GoTyme"}>GoTyme</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>Account Name</label>
                                                    <input type="text" name='account_name' onChange={(e) => setPayoutForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder="Account Name" />
                                                </div>
                                                <div>
                                                    <label>Account Number</label>
                                                    <input type="text" name='account_number' onChange={(e) => setPayoutForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder="Account Number" />
                                                </div>
                                                <div>
                                                    <label>Enter Amount</label>
                                                    <div>
                                                        <input type="number" name='amount' onChange={(e) => setPayoutForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder="PHP0.00" />
                                                        {/* {
                                                            payoutForm.amount === "" ?
                                                                <div></div>
                                                                :
                                                                payoutForm.amount >= balance ?
                                                                    <span style={{ color: "red" }}>Insuficient</span>
                                                                    :
                                                                    payoutForm.amount !== 0 ?
                                                                        <span>₱{Number(payoutForm.amount * 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                                                                        : null
                                                        } */}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label>Take a Selfie</label>
                                                    <SelfieCamera userInfo={props.userInfo} payoutForm={payoutForm} setPayoutForm={setPayoutForm} />
                                                </div>
                                                {
                                                    insufficientalert ?
                                                        <div style={{ color: "red" }}>Insufficient</div>
                                                        :
                                                        null
                                                }
                                                {/* <div>
                                                    <label>Enter Password</label>
                                                    <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="" />
                                                </div> */}
                                                <div>
                                                    <label>OTP <span style={{ color: "#888" }}>(Code)</span></label>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                                                        <input type="number" name='otp_code' onChange={(e) => setPayoutForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={payoutForm.otp_code} className="form-control" placeholder="" style={{ width: "100%", textAlign: "center" }} />
                                                        {
                                                            resendAction ?
                                                                <div style={{ width: 200, textAlign: "center" }}>
                                                                    <div>{props.userInfo.telephone}</div>
                                                                    <Countdown second={119} setResendAction={setResendAction} setTime={setTime} />
                                                                </div>
                                                                :
                                                                <div style={{ width: 200, textAlign: "center" }}>
                                                                    <div>{props.userInfo.telephone}</div>
                                                                    <div onClick={() => sendOTP()} style={{ textDecoration: "underline" }}>Send OTP</div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    incorrectpassword ?
                                                        <div style={{ color: "red" }}>Incorrect Password</div>
                                                        :
                                                        null
                                                }
                                                <br />
                                                <div style={{ display: "flex", flexGrow: 1, gap: 5 }}>
                                                    <button className="btn btn-primary btn-block" onClick={() => sendRequest()}>Request Payout</button>
                                                    <button onClick={() => {
                                                        setPayoutForm(prev => ({
                                                            ...prev,
                                                            "otp_code": "",
                                                            "payout_method": "",
                                                            "account_name": "",
                                                            "account_number": "",
                                                            "amount": "",
                                                            panel: false
                                                        }))
                                                        // setInputValue({
                                                        //     "user_id": localStorage.getItem('user_id'),
                                                        //     "otp_code": "",
                                                        //     "payout_method": "",
                                                        //     "account_name": "",
                                                        //     "account_number": "",
                                                        //     "amount": 0
                                                        // })
                                                    }} className="btn btn-danger">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        cashinForm.panel ?
                                            <>
                                                <div>
                                                    <span style={{ fontSize: 25, fontWeight: "800" }}>Cash-In</span>
                                                </div>
                                                {/* <div><span style={{ fontSize: 25, fontWeight: "800" }}>GCash Account</span></div> */}
                                                {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><span>Bal:</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                    </svg>
                                                    <span style={{ fontSize: 25, fontWeight: "800" }}>{balance}</span>
                                                </div> */}
                                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                    <div>
                                                        {/* setInputValue */}
                                                        <label>Payout Method</label>
                                                        <select name='payout_method' onChange={(e) => setCashinForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control">
                                                            {/* setPayoutMethod(e.target.value) */}
                                                            <option value={""}>Select...</option>
                                                            <option value={"Cash"}>Cash</option>
                                                            <option value={"GCash"}>GCash</option>
                                                            <option value={"GoTyme"}>GoTyme</option>
                                                        </select>
                                                    </div>
                                                    {/* <div>
                                                        <label>Enter Amount</label>
                                                        <input type="number" name='amount' onChange={(e) => setCashinForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder="0.00" />
                                                    </div> */}
                                                    <div>
                                                        <label>Enter Amount</label>
                                                        <div>
                                                            <div>
                                                                <input type="number" name='amount' onChange={(e) => setCashinForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder="PHP0.00" />
                                                            </div>
                                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                {
                                                                    cashinForm.amount === "" ?
                                                                        <div></div>
                                                                        :
                                                                        cashinForm.amount !== 0 ?
                                                                            <span>${Number(cashinForm.amount / 55).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                                                                            : <div></div>
                                                                }
                                                                USD: {55}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        cashinForm.payout_method !== 'Cash' ?
                                                            <div>
                                                                <label>Proof of Payment</label>
                                                                <div style={{ width: 150, height: 250, position: "relative", backgroundColor: "#fff" }}>
                                                                    <label>
                                                                        <img src={cashinForm.image !== null ? cashinForm.image : "../dist/img/user1-128x128.jpg"} style={{ width: 150, height: 250 }} />
                                                                        <input type="file" placeholder="" style={{ display: "none" }} className="required" onChange={(e) => changeHandlerImage(e)} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        insufficientalert ?
                                                            <div style={{ color: "red" }}>Insufficient</div>
                                                            :
                                                            null
                                                    }
                                                    <div style={{ display: "flex", flexGrow: 1, gap: 5 }}>
                                                        <button className="btn btn-primary btn-block" onClick={() => CashinRequest()}>Cash-In</button>
                                                        <button onClick={() => setCashinForm(prev => ({ ...prev, "payout_method": "", "amount": "", "image": "", panel: false }))} className="btn btn-danger">Cancel</button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            tokenForm.panel ?
                                                <>
                                                    <div>
                                                        {
                                                            tokenForm.panel && tokenForm.type === 'buy' ?
                                                                <span style={{ fontSize: 25, fontWeight: "800" }}>Buy Credits</span>
                                                                :
                                                                <span style={{ fontSize: 25, fontWeight: "800" }}>Convert Credits</span>
                                                        }
                                                    </div>
                                                    {/* <div><span style={{ fontSize: 25, fontWeight: "800" }}>GCash Account</span></div> */}
                                                    {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><span>Bal:</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                        </svg>
                                                        <span style={{ fontSize: 25, fontWeight: "800" }}>{balance}</span>
                                                    </div> */}
                                                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                        {/* <div>
                                                            <label>Enter Amount</label>
                                                            <input type="number" name='amount' onChange={(e) => setCashinForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder="0.00" />
                                                        </div> */}
                                                        <div>
                                                            <label>Enter Amount</label>
                                                            <div>
                                                                <input type="number" name='amount' onChange={(e) => setTokenForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder="PHP0.00" />
                                                                {/* {
                                                                    tokenForm.panel && tokenForm.type === 'buy' ?
                                                                        tokenForm.amount >= (balance * usdRate).toFixed(0) ?
                                                                            <span style={{ color: "red" }}>Insuficient</span>
                                                                            :
                                                                            tokenForm.amount !== "" ?
                                                                                <span>USD {Number(tokenForm.amount / usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                                                                                : null
                                                                        :
                                                                        tokenForm.amount >= Number(props.userInfo?.token) ?
                                                                            <span style={{ color: "red" }}>Insuficient</span>
                                                                            :
                                                                            tokenForm.amount !== "" ?
                                                                                <span>PHP {Number(tokenForm.amount / usdRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                                                                                : null
                                                                } */}
                                                                {/* props.userInfo?.token */}
                                                            </div>
                                                        </div>
                                                        {
                                                            insufficientalert ?
                                                                <div style={{ color: "red" }}>Insufficient</div>
                                                                :
                                                                null
                                                        }
                                                        <div style={{ display: "flex", flexGrow: 1, gap: 5 }}>
                                                            <button className="btn btn-primary btn-block" onClick={() => {
                                                                if (tokenForm.panel && tokenForm.type === 'buy') {
                                                                    BuyToken();
                                                                } else {
                                                                    ConvertToken();
                                                                }
                                                            }}>{tokenForm.panel && tokenForm.type === 'buy' ? "Buy Now" : "Convert Now"}</button>
                                                            <button onClick={() => {
                                                                setTokenForm(prev => ({ ...prev, "amount": "", panel: false }));
                                                            }} className="btn btn-danger">Cancel</button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                }


                                {
                                    cashinForm.panel || tokenForm.panel || sendFundForm || payoutForm.panel ?
                                        <></>
                                        :
                                        <>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, marginBottom: 30 }}>
                                                {
                                                    [...payoutlist]?.map((item, key) => {
                                                        return HistoryPanel(item, key)
                                                    })
                                                }
                                            </div>
                                        </>
                                }


                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/dashboard')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20 }}>
                        <i className='pi pi-microsoft' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{ position: "absolute", color: "#fff", padding: 3, top: 2, right: 10, backgroundColor: "red", fontSize: 8, borderRadius: 100 }}>Coming</span>
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Shop</span>
                    </div>
                    <div style={{ color: "#fff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#71a3f5", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{ fontSize: 12, color: "#fff" }}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Profile</span>
                    </div>
                </div> */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default Wallet;
