import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';

const AddPickupCenter = (props) => {
    const [visible, setVisible] = useState(false);
    const [application, setApplication] = useState([]);
    const [inputvalue, setInputValue] = useState({});

    const [useraccountlist, setUserAccountlist] = useState([])
    useMemo(async () => {

        var formData = new FormData();
        formData.append("user_accounts", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setUserAccountlist([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])

    return (
        <>
            <Dialog
                header={
                    <>Add Pick-Up Center</>
                }
                position={"top"}
                draggable={false}
                visible={visible}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                modal className="p-fluid"
                style={{ width: 400 }}
                onHide={() => setVisible(false)}>
                <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                    <div>
                        Street/Building
                        <input className='form-control' />
                    </div>
                    <div>
                        Province
                        <input className='form-control' />
                    </div>
                    <div>
                        City/Municipality
                        <input className='form-control' />
                    </div>
                    <div>
                        Barangay
                        <input className='form-control' />
                    </div>
                    <div>
                        Zip Cpde
                        <input className='form-control' />
                    </div>

                </div>
            </Dialog>
            <button onClick={() => setVisible(true)} className='btn btn-info btn-sm'>Add Pickup Center</button>
            {/* <div onClick={() => setVisible(true)} style={{ padding: 5, border: "1px solid #ddd", width: "100%", borderRadius: 5, textAlign: "center", backgroundColor: "#fff", color: "#000" }}>Enter Matrix Code</div> */}
        </>
    );
}

export default AddPickupCenter;
