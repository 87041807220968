import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as faceapi from 'face-api.js';
import EventForm from './EventForm';
import Webcam from 'react-webcam';
import axios from 'axios';
import ENV from './ENV';

function FaceRecognition() {
    const [initializing, setInitializing] = useState(false)
    const videoRef = useRef({});
    const imageRef = useRef({});
    const video = document.getElementById('video');
    const [name, setName] = useState({ msg: "" })
    const [count, setCount] = useState(0)

    const [visible, setVisible] = useState(false);
    const [inputvalue, setInputValue] = useState({});
    const [facingMode, setFacingMode] = useState("user");
    const [image, setImage] = useState(null);
    const [scannedData, setScannedData] = useState('');
    const webcamRef = useRef(null);
    const [labels, setLabels] = useState([])
    const [attendancelist, setAttendanceList] = useState([])
    const [oldattendancelist, setOldAttendanceList] = useState([])
    const [loading, setLoading] = useState(false)
    const [intervalId, setIntervalId] = useState(0);
    const [scan, setScan] = useState(true);

    const videoConstraints = {
        facingMode: "user",
        width: window.innerHeight - 150,
        height: window.innerWidth,
        aspectRatio: 0.6666666667
    };




    useEffect(async () => {
        const LoadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + '/models';
            setInitializing(true);
            Promise.all([
                faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            ])
            // .then(() => {
            //     navigator.mediaDevices.getUserMedia({
            //         "video": true,
            //         audio: false
            //     }).then((stream) => {
            //         videoRef.current.srcObject = stream;
            //     }).catch((error) => {
            //         console.error(error)
            //     })
            // })
            // .then(captureImage);
        }
        LoadModels();
    }, [])

    // const labels = []; 
    useMemo(async () => {
        var formData = new FormData();
        formData.append("event_registrationlist", 1);
        await axios({
            method: "post",
            url: 'https://event.drdecuplepi.org/process.php',
            data: formData,
        }).then((res) => {
            setLabels([...res.data]);
        })
    }, [])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("attendancelist", 1);
        await axios({
            method: "post",
            url: 'https://event.drdecuplepi.org/process.php',
            data: formData,
        }).then((res) => {
            setAttendanceList([...res.data[0].todaydata]);
            setOldAttendanceList([...res.data[0].olddata]);
        })
    }, [])
    if (labels.length > 0) {
        console.log(labels)
    }

    const getLabeledFaceDescriptions = async () => {
        if ([...labels].length > 0) {
            return Promise.all(
                [...labels].map(async (label) => {
                    const descriptions = [];
                    for (let i = 0; i <= labels.length; i++) {

                        const image = await faceapi.fetchImage(`https://event.drdecuplepi.org/dist/img/face/all/${label.image}`);

                        const detections = await faceapi
                            .detectSingleFace(image)
                            .withFaceLandmarks()
                            .withFaceDescriptor();

                        descriptions.push(detections.descriptor);

                    }
                    return new faceapi.LabeledFaceDescriptors(JSON.stringify(label), descriptions);
                })
            );
        }
    }

    const captureImage = async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setLoading(true)
        if (imageSrc) {

            setImage(imageSrc)
            setInputValue(prev => ({ ...prev, "image": imageSrc }))

            const labeledFaceDescriptors = await getLabeledFaceDescriptions();

            const interval = setInterval(async () => {
                const faceMatcher = await new faceapi.FaceMatcher(labeledFaceDescriptors);

                const canvas = faceapi.createCanvasFromMedia(imageRef.current);
                // console.log(faceMatcher)
                document.body.append(canvas)
                const displaySize = { width: imageRef.current.width, height: imageRef.current.height };
                faceapi.matchDimensions(canvas, displaySize);


                const detections = await faceapi.detectAllFaces(imageRef.current).withFaceLandmarks().withFaceDescriptors();

                const resizeDetections = faceapi.resizeResults(detections, displaySize);

                canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

                const results = resizeDetections.map((d) => {
                    return faceMatcher.findBestMatch(d.descriptor);
                });

                // console.log(faceMatcher.findBestMatch(detections[0].descriptor))

                // return;
                console.log(results)

                if (results[0]._label !== "unknown") {
                    setLoading(false)
                    setName(prev => ({ ...prev, 'msg': 'Found' }));

                    results.map(async (result, i) => {
                        // const box = resizeDetections[i].detection.box;
                        // const drawBox = new faceapi.draw.DrawBox(box, { label: JSON.parse(result._label).fullname });
                        // console.log(result);
                        // drawBox.draw(canvas);

                        setName(prev => ({ ...prev, 'data': result._label }));
                        var formData = new FormData();
                        formData.append("attendance", 1);
                        formData.append("inputvalue", result._label);
                        await axios({
                            method: "post",
                            url: 'https://event.drdecuplepi.org/process.php',
                            data: formData,
                        }).then((res) => {
                            setAttendanceList(res.data[0].todaydata)
                            setOldAttendanceList(res.data[0].olddata)
                            setLoading(false)

                            clearInterval(interval);
                            // clearInterval(intervalId);
                        });
                    });
                } else {
                    setLoading(false)
                    setName({ 'msg': 'Not Found!' });
                    setScan(false)
                    // setTimeout(()=>{
                    //     setImage('');
                    // }, 1500);
                    clearInterval(interval);
                }

            }, 100)
            setIntervalId(interval);

        }
    };

    const AddNew = async () => {
        if (inputvalue.fullname !== '' && inputvalue.contact !== '') {
            var formData = new FormData();
            formData.append("new_event_register", 1);
            formData.append("inputvalue", JSON.stringify(inputvalue));
            await axios({
                method: "post",
                url: 'https://event.drdecuplepi.org/process.php',
                data: formData,
            }).then((res) => {
                console.log(res.data)
                setAttendanceList(res.data[0].todaydata)
                setOldAttendanceList(res.data[0].olddata)
                setLabels(res.data[0].labelarray)
                setInputValue({})
                setScan(true)
                setImage(null)
            });
        }
    }





    const faceChecker = async () => {

        const labeledFaceDescriptors = await getLabeledFaceDescriptions();
        const faceMatcher = await new faceapi.FaceMatcher(labeledFaceDescriptors);

        const canvas = faceapi.createCanvasFromMedia(imageRef.current);
        // console.log(imageRef.current)

        document.body.append(canvas)
        const displaySize = { width: imageRef.current.width, height: imageRef.current.height };
        faceapi.matchDimensions(canvas, displaySize);
        setInterval(async () => {
            const detections = await faceapi.detectAllFaces(imageRef.current).withFaceLandmarks().withFaceDescriptors();

            const resizeDetections = faceapi.resizeResults(detections, displaySize);

            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

            const results = resizeDetections.map((d) => {
                return faceMatcher.findBestMatch(d.descriptor);
            });

            results.map(async (result, i) => {
                const box = resizeDetections[i].detection.box;
                const drawBox = new faceapi.draw.DrawBox(box, { label: result });
                // console.log(result)
                setName(result._label);
                var formData = new FormData();
                formData.append("attendance", 1);
                formData.append("inputvalue", result._label);
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data)        
                });

                drawBox.draw(canvas);
            });

        }, 1000);
    }

    // useMemo(async()=>{        
    //     var formData = new FormData();
    //     formData.append("attendance", 1);
    //     formData.append("inputvalue", name);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         console.log(res.data)

    //     });
    // }, [])




    return (
        <div>




            {/* <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}> */}
                <div className='row'>
                    {/* { */}
                        {/* // scan ?  */}
                        <div className='col-sm-6'>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                {/* <span>{initializing ? 'initializing' : 'Ready'}</span> */}
                                {/* <video ref={videoRef} id='video' width={600} height={450} autoPlay></video> */}
                                <div style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={{
                                            ...videoConstraints,
                                            facingMode
                                        }}
                                        mirrored={true}
                                        screenshotQuality={0.92}
                                        height={550}
                                        width={650}
                                        forceScreenshotSourceSize={true}
                                    />
                                    
                                    {
                                        image ?
                                            <div style={{ position: "absolute", left: 150, bottom: 0 }}>
                                                <div style={{ position: "relative", width: 150, height: 150, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div style={{ width: 150, height: 150, bottom: 10, position: "absolute", borderRadius: 10 }}>
                                                        <img ref={imageRef} src={image} style={{ width: 150, height: 200, borderRadius: 10, border: loading ? "5px solid yellow" : name.msg === "Not Found!" ? "5px solid red" : "5px solid rgb(91 249 127)" }} />
                                                    </div>
                                                    <div style={{ position: "absolute", width: 150, height: 150, display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bolder", fontSize: 20, color: "#fff" }}>
                                                        {
                                                            loading ?
                                                                <>Verifying</>
                                                                :
                                                                name.msg === 'Not Found!' ?
                                                                    <>Not Found!</>
                                                                    : <>Confirmed</>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
    
    
                                </div>
    
                                <div style={{ padding: 10 }}>
                                    <div><button onClick={() => captureImage()} className='btn btn-info btn-sm'>Verify </button></div>
                                    {/* <div>{name.data.fullname}</div> */}
                                </div>
                            </div>
                        </div>
                        {/* // : */}
                        <div className='col-sm-6'>
                            <EventForm AddNew={AddNew} inputvalue={inputvalue} setInputValue={setInputValue} />
                        </div>
                    {/* } */}
                </div>
            {/* </div> */}

            <div style={{ padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 15 }}>
                    <input className='form-control' placeholder='Search...' style={{ width: 200 }} />
                    <span>Attendees: {[...attendancelist].length}</span>
                </div>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th style={{ width: 200 }}>Date Time</th>
                            <th style={{ width: 250 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            [...attendancelist].map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.fullname}</td>
                                        <td>{item.date_time}</td>
                                        <td style={{ textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                                            <div style={{ padding: 1, backgroundColor: "rgb(91, 249, 127)", color: "rgb(97 97 97)", borderRadius: 10, width: 100, textAlign: "center" }}>Present</div>
                                            {
                                                oldattendancelist.filter((i) => i.reg_id === item.reg_id).map((i) => i).length === 1 ?
                                                    "First Timer"
                                                    :
                                                    oldattendancelist.filter((i) => i.reg_id === item.reg_id).map((i) => i).length + " times"
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>


        </div>
    )
}

export default FaceRecognition