import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';
import Countdown from '../../extra/Countdown';

const ChangeContactNumber = ({ userInfo }) => {

    const [changepasswordform, setChangePasswordForm] = useState(false);


    const [aftersendotpold, setAfterSendOTPOLD] = useState(false);
    const [aftersendotp, setAfterSendOTP] = useState(false);
    const [input, setInput] = useState({number: '', code: ''});
    
    const [resendAction, setResendAction] = useState(false);
    const [time, setTime] = useState(null);

    

    const sendOTP = async () => {
        var formData = new FormData();
        formData.append("send_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", input.number);
        formData.append("type", '');
        if (input.number!='') {
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                // console.log(res.data)
                // swal({
                //     title: "Done!",
                //     text: "Successfull added contact number",
                //     icon: "success",
                //     timer: 1000,
                //     button: false,
                // }).then(() => {
                    setAfterSendOTP(true)
                //     console.log(input)
                // });
            });   
        }
    }
    
    const resendOTP = async () => {
        setResendAction(true)
        var formData = new FormData();
        formData.append("send_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", input.number);
        formData.append("type", '');
        if (input.number!='') {
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                // console.log(res.data)
                setTime(null)
                // swal({
                //     title: "Done!",
                //     text: "Successfull added contact number",
                //     icon: "success",
                //     timer: 1000,
                //     button: false,
                // }).then(() => {
                    // setResendAction(false)
                //     console.log(input)
                // });
            });   
        }
    }

    const submitOTP = async () => {
        var formData = new FormData();
        formData.append("verify_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", input.number);
        formData.append("code", input.code);

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            if (res.data[0].status == 'invalid') {
                swal({
                    text: "You entered an invalid OTP. Please try again.",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    // setAfterSendOTP(false)
                    // setChangePasswordForm(false)
                    setInput({ ...input, code: ''})
                    // console.log(input)
                });
            } else if (res.data[0].status == 'expired') {
                swal({
                    text: "Expired",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    // setAfterSendOTP(false)
                    // setChangePasswordForm(false)
                    setInput({ ...input, code: ''})
                    // console.log(input)
                });
            } else if (res.data[0].status == 'success') {
                swal({
                    title: "Done!",
                    text: "Successfull added contact number",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    setAfterSendOTP(false)
                    setChangePasswordForm(false)
                    setInput({number: '', code: ''})
                    window.location.assign('/profile')
                });
            }
        });
    }

    // const []

    const requestOTP_oldNumber = async () => {
        if (userInfo?.mobile.substr(-10) === input.number.substr(-10)) {
            var formData = new FormData();
            formData.append("send_otp", 1);
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("number", input.number.substr(-10));
            if (input.number!='') {
                await axios({
                    method: "post",
                    url: ENV.DOMAIN+'process.php',
                    data: formData,
                }).then((res)=>{
                    setTime(null)
                    setAfterSendOTPOLD(true)
                });   
            }
        } else {
            swal({
                // title: "Done!",
                text: "Enter the correct registered number",
                icon: "error",
                timer: 1500,
                button: false,
            })
        }        
    }

    const sendOTP_oldNumber = async () => {
        var formData = new FormData();
        formData.append("send_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", input.number.substr(-10));
        if (input.number!='') {
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                setTime(null)
                setAfterSendOTPOLD(true)
            });   
        }
    }

    const requestOTP_newNumber = async () => {
        var formData = new FormData();
        formData.append("verify_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", input.number);
        formData.append("code", input.code);

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            if (res.data[0].status == 'invalid') {
                swal({
                    text: "You entered an invalid OTP. Please try again.",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    // setAfterSendOTP(false)
                    // setChangePasswordForm(false)
                    setInput({ ...input, code: ''})
                    // console.log(input)
                });
            } else if (res.data[0].status == 'expired') {
                swal({
                    text: "Expired",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    // setAfterSendOTP(false)
                    // setChangePasswordForm(false)
                    setInput({ ...input, code: ''})
                    // console.log(input)
                });
            } else if (res.data[0].status == 'success') {
                swal({
                    title: "Done!",
                    text: "Successfull added contact number",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    setAfterSendOTP(false)
                    setChangePasswordForm(false)
                    setInput({number: '', code: ''})
                    window.location.assign('/profile')
                });
            }
        });
    }

    const sendOTP_newNumber = async () => {
        var formData = new FormData();
        formData.append("send_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", input.number.substr(-10));
        if (input.number!='') {
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                setTime(null)
                setAfterSendOTPOLD(true)
            });   
        }
    }

    const handleClose = () => {
        setChangePasswordForm(false)
        setAfterSendOTPOLD(false)
        setInput({number: '', code: ''})
    }

    // setInput({ ...input, [e.target.name]: e.target.value })
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')
    return (
        <>
            <Sidebar blockScroll={true} visible={changepasswordform} header={<>Change Password</>} position="bottom" style={{ minHeight: 400, borderRadius: 0 }} onHide={handleClose}>
                <div>
                    {/* {
                        !aftersendotpold ?
                        <div style={{ width: "100%", minHeight: 100, padding: 10, backgroundColor: "#fff", opacity: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                {
                                    userInfo?.telephone != '' ?
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
                                            <label>Change Mobile Number</label>
                                            <span style={{fontSize:12}}>{userInfo?.telephone}</span>
                                        </div>
                                        :
                                        <div><label>Add Mobile Number</label></div>
                                }
                            </div>
                            <div style={{ padding: 10 }}>
                                <span>Enter your phone number that you use with your account to continue.</span>
                                <input type={"number"} name='number' onChange={(e) => setInput((prev)=>({ ...prev, [e.target.name]: e.target.value }))} value={input.number} placeholder='Enter Number' style={{ padding: 5, borderRadius: 5, textAlign: "center", width: "100%", border: "2px solid #ddd" }} />
                            </div>
                            <br />
                            <div style={{ padding: 5 }}>
                                <button onClick={(e) => requestOTP_oldNumber()} style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#326fd1", color: "#fff" }}>Send OTP</button>
                            </div>
                        </div>
                        :  
                        <div style={{ width: "100%", minHeight: 100, padding: 10, backgroundColor: "#fff", opacity: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "column", textAlign:"center", lineHeight: 1 }}>
                                    {
                                        userInfo?.telephone != '' ?
                                        <div><label>Change Mobile Number</label></div>
                                        :
                                        <div><label>Add Mobile Number</label></div>
                                    }
                                </div>
                                <div>
                                    <span style={{ fontSize: 14 }}>You will receive a One-Time Password (OTP)</span>
                                </div>
                            </div>
                            <div style={{ padding: 10 }}>
                                <label>Enter OTP</label>
                                <input type={"number"} name='code' onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} value={input.code} placeholder='Enter 6 digit code' style={{ padding: 5, borderRadius: 5, textAlign: "center", width: "100%", border: "2px solid #ddd" }} />
                            </div>
                            <div>
                                <><span style={{ fontSize: 14 }}>Did not receive code?</span>  {
                                    resendAction ?
                                    <a> <Countdown second={59} setResendAction={setResendAction} setTime={setTime} /></a>
                                    :
                                    <a style={{ textDecoration: "underline" }} onClick={() => resendOTP()}>resend </a>
                                }
                                </>
                            </div>
                            <br />
                            <div style={{ padding: 5 }}>
                                {
                                    input.code.length>=6 && input.code.length<=6 ?
                                    
                                    <>
                                        {
                                            time != '00:00' ?
                                            <button onClick={(e) => requestOTP_newNumber()} style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#326fd1", color: "#fff" }}>Submit</button>
                                            :
                                            <button style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#ddd", color: "#fff" }}>Submit</button>
                                        }
                                    </>
                                    :
                                    <button style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#ddd", color: "#fff" }}>Submit</button>
                                }
                            </div>
                        </div>
                    } */}


                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    <>
                        {
                            aftersendotp ?
                                <div style={{ width: "100%", minHeight: 100, padding: 10, backgroundColor: "#fff", opacity: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", flexDirection: "column", textAlign:"center", lineHeight: 1 }}>
                                            {
                                                userInfo?.telephone != '' ?
                                                <div><label>Change Mobile Number</label></div>
                                                :
                                                <div><label>Add Mobile Number</label></div>
                                            }
                                        </div>
                                        <div>
                                            <span style={{ fontSize: 14 }}>You will receive a One-Time Password (OTP)</span>
                                        </div>
                                    </div>
                                    <div style={{ padding: 10 }}>
                                        <label>Enter OTP</label>
                                        <input type={"number"} name='code' onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} value={input.code} placeholder='Enter 6 digit code' style={{ padding: 5, borderRadius: 5, textAlign: "center", width: "100%", border: "2px solid #ddd" }} />
                                    </div>
                                    <div>
                                        <><span style={{ fontSize: 14 }}>Did not receive code?</span>  {
                                            resendAction ?
                                            <a> <Countdown second={59} setResendAction={setResendAction} setTime={setTime} /></a>
                                            :
                                            <a style={{ textDecoration: "underline" }} onClick={() => resendOTP()}>resend </a>
                                        }
                                        </>
                                    </div>
                                    <br />
                                    <div style={{ padding: 5 }}>
                                        {
                                            input.code.length>=6 && input.code.length<=6 ?
                                            
                                            <>
                                                {
                                                    time != '00:00' ?
                                                    <button onClick={(e) => submitOTP()} style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#326fd1", color: "#fff" }}>Submit</button>
                                                    :
                                                    <button style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#ddd", color: "#fff" }}>Submit</button>
                                                }
                                            </>
                                            :
                                            <button style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#ddd", color: "#fff" }}>Submit</button>
                                        }
                                    </div>
                                </div>
                                :
                                <div style={{ width: "100%", minHeight: 100, padding: 10, backgroundColor: "#fff", opacity: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                        {
                                            userInfo?.telephone != '' ?
                                                <div><label>Change Mobile Number</label></div>
                                                :
                                                <div><label>Add Mobile Number</label></div>
                                        }
                                    </div>
                                    <div style={{ padding: 10 }}>
                                        <label>Phone Number</label>
                                        <input type={"number"} name='number' onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} value={input.number} placeholder='Enter Number' style={{ padding: 5, borderRadius: 5, textAlign: "center", width: "100%", border: "2px solid #ddd" }} />
                                    </div>
                                    <br />
                                    <div style={{ padding: 5 }}>
                                        <button onClick={(e) => sendOTP()} style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#326fd1", color: "#fff" }}>Send OTP</button>
                                    </div>
                                </div>
                        }
                    </>
                </div>
            </Sidebar>
            {
                userInfo?.telephone != '' ?
                <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", borderRadius: 5 }}>
                    Change Mobile Number <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span style={{fontSize:12}}>{userInfo?.telephone}</span><span className='pi pi-chevron-right'></span></span>
                    {/* {userInfo?.telephone?.replace(/.(?=.{4,}$)/g, '*')} */}
                </div>
                :
                <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", borderRadius: 5 }}>
                    Add Mobile Number <span className='pi pi-chevron-right'></span>
                </div>
            }
        </>
    );
}

export default ChangeContactNumber;
