import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import CopyToClipboard from 'react-copy-to-clipboard';
import MatrixMenu from './extra/MatrixMenu';
import PUCApplication from './component/pickupcenter/PUCApplication';
import MatrixCodeDorm from './component/pickupcenter/MatrixCodeForm';
import LeftNavSwiper from './component/swipe/LeftNavSwiper';

const IncomeTransactions = (props) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';


    

    const [activitylist, setActivityList] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("showactivity", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setActivityList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, []);



    const [leftright, setLeftRight] = useState('Left')
    const refArea = useRef()

    let touchArea = document.getElementById("touch-area");
    let mouseX, initialX = 0;
    let mouseY, initialY = 0;
    let isSwiped;

    let events = {
        mouse: {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
        },
        touch: {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
        }
    }

    let deviceType = "";

    // Detect touch device

    const isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (error) {
            deviceType = "mouse";
            return false;
        }
    }

    isTouchDevice();

    // console.log(events[deviceType].down)

    let rectLeft = 0; //touchArea.getBoundingClientRect([0]).left;
    let rectTop = 0; //touchArea.getBoundingClientRect([0]).top;
    // useEffect(()=>{
    //     if (props.userInfo) {
    rectLeft = touchArea?.getBoundingClientRect().left;
    rectTop = touchArea?.getBoundingClientRect().top;
    //     }
    // }, [])

    const getXY = (e) => {
        mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
        mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    }

    // isTouchDevice();

    // console.log(touchArea)


    touchArea?.addEventListener(events[deviceType].down, (event) => {
        isSwiped = true;
        getXY(event);
        initialX = mouseX;
        initialY = mouseY;
    });

    let diffX = 0;
    let diffY = 0;

    touchArea?.addEventListener(events[deviceType].move, (event) => {
        if (!isTouchDevice()) {
            event.preventDefault();
        }
        if (isSwiped) {
            getXY(event);
            diffX = mouseX - initialX;
            diffY = mouseY - initialY;
            if (Math.abs(diffY) > Math.abs(diffX)) {
                // console.log(diffY > 0 ? "Down" : "Up")
                // output.innerText = diffY > 0 ? "Down" : "Up"
            } else {
                // console.log(diffX > 0 ? "Right" : "Left")
                // if((diffY > 0 ? "Down" : "Up") !== "Down" || (diffY > 0 ? "Down" : "Up") !== "Up"){
                // setLeftRight(diffX > 0 ? "Right" : "Left")
                // }
                // output.innerText = diffX > 0 ? "Right" : "Left"
                if (leftright !== 'Right') {
                    if (Math.abs(diffX) <= 50) {
                        setLeftRight(diffX > 0 ? "Right" : "Left")
                    }
                }
            }
        }
    });

    touchArea?.addEventListener(events[deviceType].up, (event) => {
        isSwiped = false;
    });

    touchArea?.addEventListener("mouseleave", (event) => {
        isSwiped = false;
    });

    window.onload = () => {
        isSwiped = false;
    }


    return (
        <div className="wrapper" ref={refArea} id='touch-area'>
            <LeftNavSwiper userInfo={props.userInfo} leftright={leftright} setLeftRight={setLeftRight} tab="puc" />
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{ flex: 1, height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div onClick={() => setLeftRight('Right')} style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bars'></span>
                                </div>
                            </div>
                            <div style={{ flex: 4, paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{ width: 45, height: 45 }} />
                                <div style={{ display: "flex", flexDirection: "column", color: "green", lineHeight: 1 }}>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute" }}>Mirabile</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7 }}>&</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12 }}>Proventus
                                        <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5 }}>Co.</span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bell'></span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* Main content */}
                <section className="content" style={{ padding: 0 }}>
                    <div className="container-fluid">
                        <div className="row" style={{ padding: 0 }}>
                            {/* /.col */}
                            <div className="col-md-12" style={{ padding: 0 }}>
                                
                                <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 5, padding: 10, marginBottom: 20}}>
                                    {
                                        [...activitylist].filter((i) => String(i.user_id) === String(localStorage.getItem('user_id'))).map((i) => i).map((item, key) => {
                                            return (
                                                <div className="card" style={{ padding: 10, marginBottom: 2 }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                        <div>
                                                            <div style={{ fontSize: 18 }}>{item.fullname}</div>
                                                            <div>{item.description}</div>
                                                        </div>
                                                        {/* <small>{item.status}</small> */}
                                                    </div>
                                                    <div style={{ border: "0.5px solid #ddd" }}></div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "50%" }}>
                                                            <small>{item.date_time}</small>
                                                        </div>
                                                        {
                                                            item.status == 'matrix_refill' ?
                                                                <small>Refilled</small>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.status == 'matrix' ?
                                                                <small>Monthly Bonus</small>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.status == 'unilevel' ?
                                                                <small>Unilevel Bonus</small>
                                                                :
                                                                null
                                                        }
                                                        {/* <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                                                                <small><b>Placement</b></small>
                                                                                <small>{item.placement_name}</small>
                                                                                <small>{item.placement_user_id != '' ? item.placement_user_id : ''}</small>
                                                                            </div> */}
                                                    </div>
                                                </div>
                                            )
                                        }).slice(0, 20)
                                    }
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <MatrixMenu /> */}
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20 }}>
                        <i className='pi pi-microsoft' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{ position: "absolute", color: "#fff", padding: 3, top: 2, right: 10, backgroundColor: "red", fontSize: 8, borderRadius: 100 }}>Coming</span>
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Profile</span>
                    </div>
                </div> */}
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default IncomeTransactions;
