import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../../ENV';
import { Sidebar } from 'primereact/sidebar';
import swal from 'sweetalert';

const CodeUseFor = ({item, userInfo, loadCodeList}) => {
    const [openaddress, setOpenAddress] = useState(false);
    const [input, setInput] = useState({account_id: "", password: ""});
    console.log(item)
    const Activate = async () => {
        if(input.account_id !='' && input.password !='') {
            if(localStorage.getItem('user_id') === item.owner_id) {
                var formData = new FormData();
                formData.append("activate_pre_signup", 1);
                formData.append("encoder_id", localStorage.getItem('user_id'));
                formData.append("user_id", input.account_id);
                formData.append("code", item.code);
                formData.append("password", input.password);
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    console.log(res.data)
                    if (res.data[0].status === 'success') {
                        swal({
                            title: "Done!",
                            text: "Successfull Activated",
                            icon: "success",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            setOpenAddress(false)
                            loadCodeList()
                            setInput({account_id: "", password: ""});
                        });
                    } else 
                    if (res.data[0].status === 'already_activated') {
                        swal({
                            // title: "Done!",
                            text: "Already Activated",
                            icon: "error",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            setInput({account_id: "", password: ""});
                        });
                    } else 
                    if (res.data[0].status === 'invalid_owner') {
                        swal({
                            // title: "Done!",
                            text: "Invalid",
                            icon: "error",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            
                        });
                    } else 
                    if (res.data[0].status === 'already_used_code') {
                        swal({
                            // title: "Done!",
                            text: "Invalid Already Used",
                            icon: "error",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            
                        });
                    } else 
                    if (res.data[0].status === 'invalid_code') {
                        swal({
                            // title: "Done!",
                            text: "Invalid Code",
                            icon: "error",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            
                        });
                    } else 
                    if (res.data[0].status === 'invalid_account_id') {
                        swal({
                            // title: "Done!",
                            text: "Invalid Account ID",
                            icon: "error",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            setInput({account_id: "", password: ""});
                        });
                    }
                }).catch((err) => {
        
                });
            } else {

            }
        } else {
            swal({
                // title: "Done!",
                text: "Empty field",
                icon: "error",
                timer: 1000,
                button: false,
            }).then(() => {
                
            });
        }
    }
    const click = async () => {
        alert(JSON.stringify(item))

        
        // var formData = new FormData();
        // formData.append("activate_pre_signup", 1);
        // formData.append("user_id", localStorage.getItem('user_id'));
        // formData.append("code", item.code);
        // await axios({
        //     method: "post",
        //     url: ENV.DOMAIN + 'process.php',
        //     data: formData,
        // }).then((res) => {
            
        // }).catch((err) => {

        // });
    }
    return (
        <>
            <Sidebar visible={openaddress} blockScroll={true} showCloseIcon={true} position="bottom" style={{ minHeight: 500, borderRadius: 0 }} onHide={() => setOpenAddress(false)}>
                <div>
                    
                    <div>
                        <div style={{padding:10, display: "flex", flexDirection: "column"}}>
                            <span>Price: <span style={{fontSize:30, fontWeight:600}}>{Math.round(Number(item.amount*55))}</span></span>
                            <span>use: <span style={{fontSize:30, fontWeight:600}}>{item.code}</span></span>
                        </div>
                        <div style={{padding:10}}>
                            <span style={{fontWeight:600}}>Enter Account ID</span>
                            <input type='text' autoComplete='off' name='account_id' onChange={(e)=>setInput({...input, [e.target.name]: e.target.value})} value={input.account_id} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} />
                        </div>
                        <div style={{padding:10}}>
                            <span style={{fontWeight:600}}>Enter your Password</span>
                            <input type='password' autoComplete='off' name='password' onChange={(e)=>setInput({...input, [e.target.name]: e.target.value})} value={input.password} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} />
                        </div>
                        <div style={{padding:10}}>
                            <button onClick={()=>Activate()} className='btn btn-sm btn-info btn-block'>Activate</button>
                        </div>
                    </div>
                    
                </div>
            </Sidebar>
            
            <button onClick={()=>setOpenAddress(true)} style={{border:"none", border:"1px solid #ddd", borderRadius:5}} className='btn btn-sm btn-info'>use</button>
        </>
    );
}

export default CodeUseFor;
