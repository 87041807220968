import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';
import CodeUseFor from './CodeUseFor';
import MatrixCode from './MatrixCode';
import { useHistory, useParams, Link } from 'react-router-dom/cjs/react-router-dom';

const EnterMatrixCode = (props) => {

    const [visible, setVisible] = useState(false);
    const [codelist, setCodelist] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("ListMatrixCode", 1);
        await axios({
            method: "post",
            // url: 'http://192.168.1.24/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setCodelist([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])

    const ListMatrixCodeReload = async () => {
        var formData = new FormData();
        formData.append("ListMatrixCode", 1);
        await axios({
            method: "post",
            // url: 'http://192.168.1.24/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setCodelist([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }

    useMemo(()=>{
        if (props.type === 'matrix') {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [props])

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: '100%', fontSize: 20, fontWeight: "bolder" }}>Enter Matrix</div>
                }
                visible={visible} blockScroll={true} showCloseIcon={true} position="bottom" style={{ minHeight: 500, borderRadius: 0 }} onHide={() => props.history.goBack()}>
                <div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        {
                            [...props.matrixcodelist].filter((i) => i.matrix_status === 'open' && i.puc_id === localStorage.getItem('user_id')).map((item, key) => {
                                return (
                                    <MatrixCode key={key} item={item} userInfo={props.userInfo} ListMatrixCodeReload={ListMatrixCodeReload} />
                                )
                            })
                        }
                    </div>
                </div>
            </Sidebar>
            {
                [...props.matrixcodelist].filter((i) => i.puc_id === localStorage.getItem('user_id')).map((i)=>i).length>0 ?
                props.type === 'matrix' ?
                <Link style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12}}>
                    <span className='pi pi-bolt'></span>
                        <span>Matrix</span>
                </Link>
                :
                <Link to={"/profile/code/matrix"} style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12}}>
                    <span className='pi pi-bolt'></span>
                        <span>Matrix</span>
                </Link>
                // <div onClick={() => setVisible(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                //     Enter Matrix <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><span className='pi pi-chevron-right'></span></span>
                // </div>
                :
                <span style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: 12}}>Unavailable</span>
            }
        </>
    );
}

export default EnterMatrixCode;
