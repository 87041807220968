import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import { OrganizationChart } from 'primereact/organizationchart';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import LeftNavSwiper from './component/swipe/LeftNavSwiper';

const MatrixGenealogy = (props) => {
    // if (props.userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    const history = useHistory()
    const { id } = useParams();
    document.body.className = 'sidebar-mini';

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [data, setData] = useState([]);
    const [selection, setSelection] = useState([]);
    useMemo(async () => {
        if (props.userInfo?.user_id != undefined) {
            // console.log(props.userInfo?.user_id)
            var formData = new FormData();
            formData.append("OrganizationChart", 1);
            formData.append("user_id", props.userInfo?.user_id);

            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                // url: 'http://127.0.0.1/drinkgood/process.php?OrganizationChart',
                data: formData,
            }).then(async (res) => {
                // console.log(userInfo?.user_id)
                // console.log(res.data)
                setData(res.data)
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            return null
        }
    }, [props]);


    const [OpenDialog, setOpenDialog] = useState(false);
    const [userDetails, setUserDetails] = useState(null);

    const hideShippingDialog = () => {
        // setSubmitted(false);
        setOpenDialog(false);
    };


    const ShippingDialogFooter = (
        <React.Fragment>
            <Button style={{ width: 100, fontSize: 18 }} label="Close" icon="pi pi-times" outlined onClick={hideShippingDialog} />
            {/* <Button style={{width:100, fontSize:18}} label="Save" icon="pi pi-check" onClick={saveProduct} /> */}
        </React.Fragment>
    );

    let level = 1;

    const nodeTemplate = (node = null) => {
        // console.log(Number(level++))
        if (node?.label != null) {
            return (
                <>
                    <div onClick={() => {
                        // setOpenDialog(true)
                        setUserDetails(node)
                    }} className="" style={{ width: 100 }}>
                        <div className="flex flex-column align-items-center">
                            <img alt={node?.name} src={ENV.DOMAIN + node?.data.image} style={{ borderRadius: 100 }} className="mb-3 w-3rem h-3rem" />
                            {/* <span className="font-bold mb-2" style={{fontSize:10}}>{node?.label}</span> */}
                            <span className="font-bold mb-2" style={{ fontSize: 9 }}>{node?.name}</span>
                            <span style={{ fontSize: 12 }}>{node?.data?.title}</span>
                        </div>
                    </div>
                </>
            );
        }

        return node?.label;
    };



    const [leftright, setLeftRight] = useState('Left')
    const refArea = useRef()

    let touchArea = document.getElementById("touch-area");
    let mouseX, initialX = 0;
    let mouseY, initialY = 0;
    let isSwiped;

    let events = {
        mouse: {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
        },
        touch: {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
        }
    }

    let deviceType = "";

    // Detect touch device

    const isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (error) {
            deviceType = "mouse";
            return false;
        }
    }

    isTouchDevice();

    // console.log(events[deviceType].down)

    let rectLeft = 0; //touchArea.getBoundingClientRect([0]).left;
    let rectTop = 0; //touchArea.getBoundingClientRect([0]).top;
    // useEffect(()=>{
    //     if (props.userInfo) {
    rectLeft = touchArea?.getBoundingClientRect().left;
    rectTop = touchArea?.getBoundingClientRect().top;
    //     }
    // }, [])

    const getXY = (e) => {
        mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
        mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    }

    // isTouchDevice();

    // console.log(touchArea)


    touchArea?.addEventListener(events[deviceType].down, (event) => {
        isSwiped = true;
        getXY(event);
        initialX = mouseX;
        initialY = mouseY;
    });

    let diffX = 0;
    let diffY = 0;

    touchArea?.addEventListener(events[deviceType].move, (event) => {
        if (!isTouchDevice()) {
            event.preventDefault();
        }
        if (isSwiped) {
            getXY(event);
            diffX = mouseX - initialX;
            diffY = mouseY - initialY;
            if (Math.abs(diffY) > Math.abs(diffX)) {
                // console.log(diffY > 0 ? "Down" : "Up")
                // output.innerText = diffY > 0 ? "Down" : "Up"
            } else {
                // console.log(diffX > 0 ? "Right" : "Left")
                // if((diffY > 0 ? "Down" : "Up") !== "Down" || (diffY > 0 ? "Down" : "Up") !== "Up"){
                // setLeftRight(diffX > 0 ? "Right" : "Left")
                // }
                // output.innerText = diffX > 0 ? "Right" : "Left"
                if (leftright !== 'Right') {
                    if (Math.abs(diffX) <= 50) {
                        setLeftRight(diffX > 0 ? "Right" : "Left")
                    }
                }
            }
        }
    });

    touchArea?.addEventListener(events[deviceType].up, (event) => {
        isSwiped = false;
    });

    touchArea?.addEventListener("mouseleave", (event) => {
        isSwiped = false;
    });

    window.onload = () => {
        isSwiped = false;
    }





    return (
        <div className="wrapper" ref={refArea}>
            <LeftNavSwiper userInfo={props.userInfo} leftright={leftright} setLeftRight={setLeftRight} tab="matrix" />
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{ flex: 1, height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div onClick={() => setLeftRight('Right')} style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bars'></span>
                                </div>
                            </div>
                            <div style={{ flex: 4, paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{ width: 45, height: 45 }} />
                                <div style={{ display: "flex", flexDirection: "column", color: "green", lineHeight: 1 }}>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute" }}>Mirabile</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7 }}>&</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12 }}>Proventus
                                        <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5 }}>Co.</span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bell'></span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px', marginBottom: 50 }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Matrix Genealogy</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row" style={{ overflow: "auto" }}>

                            <Dialog visible={OpenDialog} style={{ width: '100rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                                <div style={{ fontSize: 18, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                        <img alt={userDetails?.name} src={userDetails?.data?.image} style={{ borderRadius: 100 }} className="w-4rem h-4rem" />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                            <span style={{ fontSize: 15 }}>{userDetails?.name}</span>
                                            <span style={{ fontSize: 12 }}>{userDetails?.data?.title}</span>
                                            <span style={{ fontSize: 12 }}>
                                                {
                                                    // userDetails?.date_registered != '' ?
                                                    //         format(Date.parse(userDetails?.date_registered), 'MMMM dd, Y')
                                                    //     :
                                                    //     null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            } modal className="p-fluid" footer={ShippingDialogFooter} onHide={hideShippingDialog}>
                                {
                                    userDetails ?
                                        <>
                                            <div style={{ width: "100%", border: "1px solid #ddd", padding: 5, borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                <img alt={userDetails?.name} src={userDetails?.data.image} style={{ borderRadius: 100 }} className="w-4rem h-4rem" />
                                                <div>
                                                    <div style={{ fontSize: 15 }}>
                                                        {
                                                            userDetails.data.title
                                                        }
                                                    </div>
                                                    <div style={{ fontSize: 12 }}>
                                                        {
                                                            // userDetails?.date_registered!=''?
                                                            // format(Date.parse(userDetails?.date_registered), 'MMMM dd, Y')
                                                            // :
                                                            // null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </>
                                        :
                                        null
                                }
                            </Dialog>
                            {
                                data.length > 0 ?
                                    <OrganizationChart value={data} selectionMode="multiple" selection={selection} onSelectionChange={(e) => setSelection(e.data)} nodeTemplate={nodeTemplate} />
                                    :
                                    null
                            }

                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20 }}>
                        <i className='pi pi-microsoft' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{ position: "absolute", color: "#fff", padding: 3, top: 2, right: 10, backgroundColor: "red", fontSize: 8, borderRadius: 100 }}>Coming</span>
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Profile</span>
                    </div>
                </div> */}
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default MatrixGenealogy;
