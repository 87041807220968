import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";
import React from 'react';
// import Homepage from "./components/Homepage";
import Login from './screens/Login'
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard";
import Profile from "./screens/Profile";
import axios from "axios";
import ENV from "./screens/ENV";
import { useEffect, useMemo, useState } from "react";
// import firebase from './firebase'
import Downline from "./screens/Downline";
import Direct from "./screens/Direct";
import Unpaid from "./screens/Unpaid";
import Wallet from "./screens/Wallet";
import AdminLogin from "./screens/AdminLogin";
import AdminDashboard from "./screens/AdminDashboard";
import AdminCodeGenerator from "./screens/AdminCodeGenerator";
import AdminPayout from "./screens/AdminPayout";
import AdminSales from "./screens/AdminSales";
import AdminMember from "./screens/AdminMembers";
import AdminCode from "./screens/AdminCode";
import Notifications from "./screens/Notifications";
import MatrixGenealogy from "./screens/MatrixGenealogy";
import PersonalGenealogy from "./screens/PersonalGenealogy";
import AdminPayoutHistory from "./screens/AdminPayoutHistory";
import AdminCodeUsed from "./screens/AdminCodeUsed";
import Unilevel from "./screens/Unilevel";
import Matrix from "./screens/Matrix";
import AdminMatrixGenealogy from "./screens/AdminMatrixGenealogy";
import AdminPersonalGenealogy from "./screens/AdminPersonalGenealogy";
import AdminTransaction from "./screens/AdminTransaction";
import Maintenance from "./screens/Maintenance";
import ProfileTwo from "./screens/ProfileTwo";
import { ProgressSpinner } from "primereact/progressspinner";
import Shop from "./screens/Shop";
import ShopDetails from "./screens/ShopDetails";
import PreSignUpList from "./screens/PreSignUpList";
import AdminOrderNew from "./screens/AdminOrderNew";
import AdminOrderPending from "./screens/AdminOrderPending";
import AdminTransactionPayin from "./screens/AdminTransactionPayin";
import AdminTransactionMatrixRefill from "./screens/AdminTransactionMatrixRefill";
import AdminPayinSummary from "./screens/AdminPayinSummary";
import AdminReportPayout from "./screens/AdminReportPayout";
import AdminReportSales from "./screens/AdminReportSales";
import AdminReportEntry from "./screens/AdminReportEntry";
import Pos from "./screens/Pos";
import NewsFeed from "./screens/NewsFeed";
import PickupCenter from "./screens/PickupCenter";
import CardScanner from "./screens/CardScanner";
import AutoCapture from "./screens/AutoCapture";
import KYCForm from "./screens/KYCForm";
import MainPOS from "./screens/MainPOS";
import AdminKYCApplication from "./screens/AdminKYCApplication";
import AdminAccessAccount from "./screens/AdminAccessAccount";
import MatrixUnilevel from "./screens/MatrixUnilevel";
import AdminInventory from "./screens/AdminInventory";
import AdminPickupCenter from "./screens/AdminPickupCenter";
// import firebase from "./firebase";

import { useSwipeable } from 'react-swipeable';
import IncomeTransactions from "./screens/IncomeTransactions";
import Order from "./screens/Order";
import Products from "./screens/Products";
import FaceRecognition from "./screens/FaceRecognition";
import EventForm from "./screens/EventForm";
import Announcement from "./screens/Announcement";
import AdminPayoutReport from "./screens/AdminPayoutReport";
import RaffleProgram from "./screens/RaffleProgram";
import AdminRaffleEntries from "./screens/AdminRaffleEntries";
import AdminPayoutDeduction from "./screens/AdminPayoutDeduction";


function App() {
    // localStorage.removeItem('username')

    // createBrowserRouter

    const [userInfo, setUserInfo] = useState(null);
    const [notification, setNotification] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [timeout, settimeout] = useState(0);
    const [maintenanceCheck, setMaintenanceCheck] = useState(false);

    const LOGINACCESS = localStorage.getItem('login_access') || false;



    // const handlers = useSwipeable({
    //     onSwipedLeft: () => alert('Swiped left!'),
    //     onSwipedRight: () => alert('Swiped right!'),
    //     onSwipedUp: () => alert('Swiped up!'),
    //     onSwipedDown: () => alert('Swiped down!'),
    // });

    useMemo(async () => { // Maintenance
        var formData = new FormData();
        formData.append("maintenance_check", 1);
        // const interval = setInterval(async() => {
            await axios({
                method: "post",
                // url: 'http://192.168.1.13/drinkgood/process.php',
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                setMaintenanceCheck(res.data)
            })
                .catch((err) => {
                    console.log(err);
                });
        // }, 5000);
        // return () => {
        //   clearInterval(interval);
        // };
    }, []);

    useMemo(async () => {

        var formData = new FormData();
        formData.append("profile", 1);
        formData.append("user_id", localStorage.getItem('user_id'));

        await axios({
            method: "post",
            // url: 'http://192.168.1.13/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if (res.data[0]) {
                setUserInfo({...res.data[0]})
                // console.log(res.data)
                setisLoading(false)
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);


    if (isLoading) {
        if (localStorage.getItem('user_id') === null) {
            setTimeout(() => {
                setisLoading(false)
            }, 2000);
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: window.innerHeight }}>
                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    <div>Please wait...</div>
                </div>
            )
        } else {
            // setTimeout(()=>{
            //     setisLoading(false)
            //     localStorage.removeItem('user_id')
            //     localStorage.removeItem('username')
            //     localStorage.removeItem('adminusername')
            //     window.location.assign('/')
            // }, 2000);
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", height: window.innerHeight }}>
                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    <div>Please wait...</div>
                </div>
            )
        }
    }

    const ProtectedRoute = ({ children }) => {
        if (localStorage.getItem('user_id') === null) {
            return <Login />;
        }
        return children;
    }

    ///////////////////////////////////////////////
    // START Event
    ///////////////////////////////////////////////
    // return (
    //     <Router>
    //         <Switch>
    //             <Route exact={true} path="/"> 
    //             {/* facerecognition */}
    //                 <FaceRecognition />
    //             </Route>
    //         </Switch>
    //     </Router>
    // );
    ///////////////////////////////////////////////
    // END Event
    ///////////////////////////////////////////////





    ///////////////////////////////////////////////
    // START Inventory
    ///////////////////////////////////////////////
    // return (
    //     <Router>
    //         <Switch>
    //             <Route exact={true} path="/"> 
    //             {/* facerecognition */}
    //                 <FaceRecognition />
    //             </Route>
    //         </Switch>
    //     </Router>
    // );
    ///////////////////////////////////////////////
    // END Inventory
    ///////////////////////////////////////////////





    ///////////////////////////////////////////////
    // START POS
    ///////////////////////////////////////////////
    // return (
    //     <Router>
    //         <Switch>
    //             <Route exact={true} path="/"> 
    //                 <Pos />
    //             </Route>
    //         </Switch>
    //     </Router>
    // );
    ///////////////////////////////////////////////
    // END POS
    ///////////////////////////////////////////////

    ///////////////////////////////////////////////
    ///////////////////////////////////////////////
    ///////////////////////////////////////////////
    return (
        // <AppProvider>
        <Router>
            <Switch>
                <Route exact={true} path="/">
                    <NewsFeed userInfo={userInfo} />
                    {/* {localStorage.getItem('username') === null ? <Login /> : <Dashboard userInfo={userInfo} />} */}
                </Route>
                <Route exact={true} path="/refer/:refid">
                    <NewsFeed userInfo={userInfo} />
                </Route>
                {/* <Route exact={true} path="/">
                        {localStorage.getItem('username') === null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route> */}
                <Route exact={true} path="/login">
                    {localStorage.getItem('username') === null ? <Login /> :
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Dashboard userInfo={userInfo} />
                    }
                </Route>
                {/* <Route exact={true} path="/register"></Route> */}
                <Route exact={true} path="/register/:refid">
                    <Register />
                </Route>
                <Route exact={true} path="/dashboard">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} /> :
                                <Dashboard userInfo={userInfo} />  : 
                                <Login />
                    }
                </Route>
                <Route exact={true} path="/profile">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Profile userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/profile/code/:type">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Profile userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/profile/:refid">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <ProfileTwo userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/pre-sign-up">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <PreSignUpList userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/pre-sign-up/:activation/:user_id">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <PreSignUpList userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/pre-sign-up/:activation/:user_id/:name">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <PreSignUpList userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/direct">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Direct userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/downline">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Downline userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/unilevel">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Unilevel userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/matrix">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Matrix userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/matrix/unilevel">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <MatrixUnilevel userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/personal/genealogy">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <PersonalGenealogy userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/matrix/genealogy">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <MatrixGenealogy userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/unpaid">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Unpaid userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/wallet">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} setUserInfo={setUserInfo} />
                            :
                            <Wallet userInfo={userInfo} setUserInfo={setUserInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/wallet/:dialog">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Wallet userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/wallet/:dialog">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Wallet userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/notifications">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Notifications userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/shop">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <Shop userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/shop/details/:pid">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <ShopDetails userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/pickupcenter">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <PickupCenter userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/pickupcenter/:id">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <PickupCenter userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/kyc">
                    {localStorage.getItem('username') !== null ?
                        maintenanceCheck === true ?
                            <Maintenance userInfo={userInfo} />
                            :
                            <KYCForm userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/pos">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} />
                                :
                                <Pos userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>
                <Route exact={true} path="/income/transaction">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} />
                                :
                                <IncomeTransactions userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>
                <Route exact={true} path="/products">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} />
                                :
                                <Products userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>
                <Route exact={true} path="/products/:type">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} />
                                :
                                <Products userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>
                <Route exact={true} path="/products/:type/:pid">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} />
                                :
                                <Products userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>
                <Route exact={true} path="/product/order">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} />
                                :
                                <></>
                                // <Order userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>
                <Route exact={true} path="/announcement">
                    {
                        localStorage.getItem('username') !== null ?
                                <Announcement userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>
                <Route exact={true} path="/raffle_program">
                    {
                        localStorage.getItem('username') !== null ?
                            maintenanceCheck === true ?
                                <Maintenance userInfo={userInfo} />
                                :
                                <RaffleProgram userInfo={userInfo} />
                            :
                            <Login />
                    }
                </Route>




                <Route exact={true} path="/administrator">
                    {localStorage.getItem('adminusername') !== null ? <AdminDashboard userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/dashboard">
                    {localStorage.getItem('adminusername') !== null ? <AdminDashboard userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/members">
                    {localStorage.getItem('adminusername') !== null ? <AdminMember userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/user_account">
                    {
                        localStorage.getItem('adminusername') !== null ?
                            localStorage.getItem('admin_id') === '2' || localStorage.getItem('admin_id') === '3' ?
                                <AdminAccessAccount userInfo={userInfo} />
                                : null
                            : <AdminLogin />
                    }
                </Route>
                <Route exact={true} path="/administrator/members/genealogy/matrix/:user_id">
                    {localStorage.getItem('adminusername') !== null ? <AdminMatrixGenealogy userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/administrator/members/genealogy/personal/:user_id">
                    {localStorage.getItem('adminusername') !== null ? <AdminPersonalGenealogy userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/administrator/transactions">
                    {localStorage.getItem('adminusername') !== null ? <AdminTransaction userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/administrator/transactions/payin">
                    {localStorage.getItem('adminusername') !== null ? <AdminTransactionPayin userInfo={userInfo} /> : <Login />}
                </Route>
                <Route exact={true} path="/administrator/transactions/matrixrefill">
                    {localStorage.getItem('adminusername') !== null ? <AdminTransactionMatrixRefill userInfo={userInfo} /> : <Login />}
                </Route>
                {/* <Route exact={true} path="/administrator/generator/code">
                        {localStorage.getItem('adminusername') !== null ? <AdminCodeGenerator userInfo={userInfo} /> : <AdminLogin />}
                    </Route> */}
                <Route exact={true} path="/administrator/payout">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/cashin">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayinSummary userInfo={userInfo} type="cashin" /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/payin/summary">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayinSummary userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/raffle/entries">
                    {localStorage.getItem('adminusername') !== null ? <AdminRaffleEntries userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/payout/request">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/payout/history">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayoutHistory userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/payout/report">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayoutReport userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/payout/deduction">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayoutDeduction userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/payout/request/:id">
                    {localStorage.getItem('adminusername') !== null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/sales">
                    {localStorage.getItem('adminusername') !== null ? <AdminSales userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/report/payout">
                    {localStorage.getItem('adminusername') !== null ? <AdminReportPayout userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/report/sales">
                    {localStorage.getItem('adminusername') !== null ? <AdminReportSales userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/report/entry">
                    {localStorage.getItem('adminusername') !== null ? <AdminReportEntry userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/code">
                    {localStorage.getItem('adminusername') !== null ? <AdminCode userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/code/generate">
                    {localStorage.getItem('adminusername') !== null ? <AdminCodeGenerator userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/code/used">
                    {localStorage.getItem('adminusername') !== null ? <AdminCodeUsed userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                {/* ///////////////////////// */}
                <Route exact={true} path="/administrator/order/new">
                    {localStorage.getItem('adminusername') !== null ? <AdminOrderNew userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/order/pending">
                    {localStorage.getItem('adminusername') !== null ? <AdminOrderPending userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/kyc/list">
                    {localStorage.getItem('adminusername') !== null ? <AdminKYCApplication userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/inventory/:supplies">
                    {localStorage.getItem('adminusername') !== null ? <AdminInventory userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/inventory/:supplies/:category">
                    {localStorage.getItem('adminusername') !== null ? <AdminInventory userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/inventory/:supplies/:category/:pickup_center">
                    {localStorage.getItem('adminusername') !== null ? <AdminInventory userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/pickupcenter">
                    {localStorage.getItem('adminusername') !== null ? <AdminPickupCenter userInfo={userInfo} /> : <AdminLogin />}
                </Route>
                <Route exact={true} path="/administrator/pickupcenter/:puc_id">
                    {localStorage.getItem('adminusername') !== null ? <AdminPickupCenter userInfo={userInfo} /> : <AdminLogin />}
                </Route>

                <Route exact={true} path="/CardScanner">
                    <AutoCapture />
                </Route>
                <Route exact={true} path="/main/pos">
                    <MainPOS />
                </Route>
                <Route exact={true} path="/facerecognition"> 
                {/* facerecognition */}
                    <FaceRecognition />
                </Route>
                <Route defer exact={true} path="/event">
                    <EventForm />
                </Route>
            </Switch>
        </Router>
        // </AppProvider>
    )
}

export default App;
