import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import CopyToClipboard from 'react-copy-to-clipboard';
import { format } from 'date-fns';
import { Link } from 'react-router-dom/cjs/react-router-dom'

const Pos = ({ userInfo }) => {
    document.body.className = 'sidebar-mini';


    const [users, serUsers] = useState([]);
    const [Copied, setCopied] = useState({ id: '', status: false });
    const [transaction, setTransaction] = useState({});
    const [todaydate, setTodayDate] = useState('');
    const [info, setInfo] = useState({});
    const [isloading, setisLoading] = useState(true);
    const [lastId, setLastId] = useState(0);
    const [loginField, setLoginField] = useState({ "serial_pos": "38-BA-F8-E1-CF-DA", "username": "", "password": "" });
    const [inputvalue, setInputValue] = useState({
        "serial_pos": loginField.serial_pos,
        "encoder_user_id": sessionStorage.getItem("pos_user_id"),
        "encoder": "cashier",
        "pos_name": "",
        "pos_address": "",
        "user_id": "",
        "fullname": "",
        "contact": "",
        "set": "",
        "qty": "",
        "price": "",
        "free": "",
        "category": "",
        "payment": "",
        "reference": "",
        "ifpuc": ""
    });

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [directInfo, setDirectInfo] = useState([]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("users", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/pos.php',
            url: ENV.DOMAIN + 'pos.php',
            data: formData,
        }).then((res) => {
            serUsers(res.data);
        })
            .catch((err) => {
                console.log(err);
            });

    }, [inputvalue.user_id]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("pos_find_user_id", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        if (inputvalue.user_id !== '') {
            await axios({
                method: "post",
                // url: 'http://localhost/drinkgood/pos.php',
                url: ENV.DOMAIN + 'pos.php',
                data: formData,
            }).then((res) => {
                if (res.data[0].status === 'success') {
                    setInfo(res.data[0])
                    console.log(res.data[0])
                } else if (res.data[0].status === 'not_found') {
                    setInfo(res.data[0])
                }
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setInfo({ "user_id": "", "fullname": "", "status": "" })
        }

    }, [inputvalue]);

    // useMemo(async () => {
    //     var formData = new FormData();
    //     formData.append("pos_login", 1);
    //     formData.append("loginField", JSON.stringify(loginField));
    //     await axios({
    //         method: "post",
    //         url: 'http://localhost/drinkgood/pos.php',
    //         data: formData,
    //     }).then((res) => {
    //         if (res.data[0].status === 'success') {
    //             sessionStorage.setItem("pos_user_id", res.data[0].user_id);
    //         } else if (res.data[0].status === 'invalid') {

    //         }
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);


    useMemo(async () => {
        var formData = new FormData();
        formData.append("pos_matrix_list", 1);

        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/pos.php',
            url: ENV.DOMAIN + 'pos.php',
            data: formData,
        }).then((res) => {
            console.log(res.data[0])
            setTransaction(res.data[0])
            // setTodayDate([...res.data[0]?.todaydate])
        })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const CompleteOrder = async () => {
        if (inputvalue.user_id !== '' && inputvalue.category !== '' && inputvalue.payment !== '' && inputvalue.set !== '' && inputvalue.qty !== '' && inputvalue.price !== '' && inputvalue.ifpuc !== '') {
            setisLoading(false)
            setTimeout(async () => {
                var formData = new FormData();
                formData.append("pos_matrix", 1);
                formData.append("inputvalue", JSON.stringify(inputvalue));
                await axios({
                    method: "post",
                    // url: 'http://localhost/drinkgood/pos.php',
                    url: ENV.DOMAIN + 'pos.php',
                    data: formData,
                }).then((res) => {
                    console.log(res.data[0].raffle_ticket[0])
                    if (res.data[0].raffle_ticket.length > 0) {
                        axios.post('https://posricompco.stgc.online/', JSON.stringify(res.data[0].raffle_ticket[0]))
                            .then(function (response) {
                                console.log(response);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }

                    setLastId(res.data[0].last_id)
                    setTimeout(() => {
                        setLastId(0)
                    }, 5000)
                    setTransaction(res.data[0])
                    setInputValue({
                        "encoder_user_id": sessionStorage.getItem("pos_user_id"),
                        "encoder": "cashier",
                        "pos_name": "",
                        "pos_address": "",
                        "user_id": "",
                        "fullname": "",
                        "contact": "",
                        "set": "",
                        "qty": "",
                        "price": "",
                        "free": "",
                        "category": "",
                        "payment": "",
                        "reference": "",
                        "ifpuc": ""
                    })
                    setisLoading(true)
                })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 1500)
        } else {

        }
    }


    const LoginPOS = async (e) => {
        e.preventDefault();
        // console.log(JSON.stringify(loginField))
        // return;
        var formData = new FormData();
        formData.append("pos_login", 1);
        formData.append("loginField", JSON.stringify(loginField));
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/pos.php',
            url: ENV.DOMAIN + 'pos.php',
            data: formData,
        }).then((res) => {
            // alert(JSON.stringify(res.data))
            console.log(res.data)
            if (res.data[0].status === 'success') {
                sessionStorage.setItem("pos_user_id", res.data[0].user_id);
                sessionStorage.setItem("serial_pos", res.data[0].serial_pos);
                window.location.assign("/")
            } else if (res.data[0].status === 'invalid') {
                
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }


    // const ClickCopied = async (user_id) => {
    //     await navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id)
    //     alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    // }

    const MonthlyTotalSales = transaction?.item?.filter((i) => i.date_time.includes(transaction.monthdate)).reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0).toFixed(2);
    const TodayTotalSales = transaction?.item?.filter((i) => i.date_time.includes(format(new Date(), 'yyyy-M-dd'))).reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0).toFixed(2);
    
    
    if (loginField.serial_pos !== sessionStorage.getItem("serial_pos")) {
        return (
            <div style={{ flex: 1, height: "100vh" }}>
                <div style={{ flex: 1, height: "100vh", display: "flex", justifyContent: "center" }}>
                    <form onSubmit={LoginPOS}>
                        <div style={{ width: 300, padding: 10, margin: "0 auto 100px" }}>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={require('./4c014d7c-6d7e-43e6-8b59-8d8c604f268a.jfif')} style={{ width: 200, height: 200 }} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "center", color: "green", lineHeight: 1, position: "relative" }}>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 30 }}>Mirabile</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 30, color: "orange" }}>&</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 30, color: "red", display: "flex", gap: 10 }}>Proventus
                                        <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 30, color: "gold" }}>Co.</span>
                                    </span>
                                </div>
                                <span style={{ fontWeight: 600, fontSize: 30, color: "orange", fontFamily: "Tangerine" }}>POS</span>
                            </div>
                            <div>
                                <span>Username</span>
                                <input type='text' name='username' onChange={(e)=>setLoginField(prev=>({...prev, [e.target.name]: e.target.value}))} autoFocus className='form-control' />
                            </div>
                            <div>
                                <span>Password</span>
                                <input type='password' name='password' onChange={(e)=>setLoginField(prev=>({...prev, [e.target.name]: e.target.value}))} autoFocus className='form-control' />
                            </div>
                            <div style={{ padding: "10px 0px 0px 0px" }}>
                                <button className='btn btn-success btn-block' >Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        ) 
    }

    return (
        <div style={{ flex: 1, padding: "0px 10px 0px 10px" }}>
            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start", gap: 5 }}>
                <div style={{ flex: 1, border: "1px solid #ddd", display: "flex", flexDirection: "column", borderRadius: 10 }}>
                    {/* <div style={{border: "1px solid #ddd", height: 50, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "green"}}>
                        <span style={{color: "#fff", fontWeight: "bolder"}}>Take Order</span>
                    </div> */}
                    <div style={{ flex: 3, border: null, height: 50, display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "red" }}>
                        <span style={{ flex: 1, color: "#fff", fontWeight: "bolder", fontSize: 40 }}></span>
                        <span style={{ flex: 1, color: "#fff", fontWeight: "bolder", fontSize: 40 }}>POS</span>
                        <div style={{ color: "#fff", padding: 5 }}>
                            <a onClick={()=>{
                                sessionStorage.setItem("serial_pos", null)
                                window.location.assign("/")
                            }} style={{cursor: "pointer"}}>Logout</a>
                        </div>
                    </div>
                    <div>
                        <div style={{ padding: 10, position: "relative" }}>
                            <span>Account ID </span>
                            <input className='form-control' name='user_id' placeholder='Search Name' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.user_id} />
                            <div style={{position: "absolute", backgroundColor: "#fff", width: "100%", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)"}}>
                                {
                                    inputvalue.user_id.length > 1 ?
                                        [...users].filter((i) => String(i.fullname).toLowerCase().includes(String(inputvalue.user_id).toLowerCase())).map((i) => {
                                            return (
                                                <div style={{padding: "5px 0px 5px 10px", cursor: "pointer", border: "1px solid #ddd"}} onClick={() => setInputValue(prev => ({ ...prev, "user_id": i.user_id, "fullname": i.fullname }))}>{i.fullname}</div>
                                            )
                                        }).slice(0, 30)
                                        : null
                                }
                            </div>
                        </div>
                        {/* <div style={{ padding: 10 }}>
                            <span>Fullname</span>
                            <input className='form-control' name='fullname' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} />
                        </div>
                        <div style={{ padding: 10 }}>
                            <span>Contact No.</span>
                            <input className='form-control' name='contact' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} />
                        </div> */}
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                            <div style={{ width: 250, padding: 10 }}>
                                <span>Category</span>
                                <select className='form-control' name='category' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.category}>
                                    <option value={""}>Select</option>
                                    <option value={"Container"}>Container</option>
                                    <option value={"Gallon"}>Gallon</option>
                                    <option value={"Litre"}>Litre</option>
                                </select>
                            </div>
                            <div style={{ width: 100, padding: 10 }}>
                                <span>Set</span>
                                <input type='number' className='form-control' placeholder={"0"} name='set' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.set} />
                            </div>
                            <div style={{ width: 100, padding: 10 }}>
                                <span>Qty</span>
                                <input type='number' className='form-control' placeholder={"0"} name='qty' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.qty} />
                            </div>
                            <div style={{ width: "auto", padding: 10 }}>
                                <span>Price</span>
                                <input type='number' className='form-control' placeholder={"0.00"} style={{ textAlign: "right" }} name='price' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.price} />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                            <div style={{ width: 100, padding: 10 }}>
                                <span>Free</span>
                                <input type='number' className='form-control' placeholder={"0"} style={{ textAlign: "right" }} name='free' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.free} />
                            </div>
                            <div style={{ width: 250, padding: 10 }}>
                                <span>Payment Method</span>
                                <select className='form-control' name='payment' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.payment} >
                                    <option value={""}>Select Payment</option>
                                    <option value={"Cash"}>Cash</option>
                                    <option value={"GCash"}>GCash</option>
                                    <option value={"GoTyme"}>GoTyme</option>
                                </select>
                            </div>
                            {
                                inputvalue.payment === 'GCash' || inputvalue.payment === 'GoTyme' ?
                                    <div style={{ width: "auto", padding: 10 }}>
                                        <span>Reference</span>
                                        <input type='text' className='form-control' name='reference' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.reference} />
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={{ width: 250, padding: 10 }}>
                            <span>PUC / NON-PUC</span>
                            <select className='form-control' name='ifpuc' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.ifpuc} >
                                <option value={""}></option>
                                <option value={"NON-PUC"}>NON-PUC</option>
                                <option value={"PUC"}>PUC</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 10 }}>
                        <div>
                            {
                                info.fullname !== '' ?
                                    <div style={{ fontWeight: "bolder", fontSize: 18 }}>Name: {info.fullname}</div>
                                    : null
                            }
                            {
                                inputvalue.user_id !== '' ?
                                    info.account_status === '1' ?
                                        <div style={{ fontWeight: "bolder", fontSize: 18, color: "green" }}>Activated</div>
                                        : <div style={{ fontWeight: "bolder", fontSize: 18, color: "red" }}>Unpaid</div>
                                    : null
                            }
                        </div>
                        <span style={{ fontWeight: "bolder", fontSize: 25 }}>Sub-Total: <span style={{ fontSize: 35 }}>{Number(Number(inputvalue.price) * Number(inputvalue.set)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                    </div>
                    <div style={{ padding: 10 }}>
                        {
                            isloading ?
                                info.status === "success" ?
                                    <button onClick={() => CompleteOrder()} className='btn btn-info btn-block'>Complete</button>
                                    : <button className='btn btn-default btn-block' style={{ cursor: "no-drop" }}>Complete</button>
                                :
                                <button className='btn btn-default btn-block' style={{ cursor: "no-drop" }}>Processing...</button>
                        }
                    </div>
                    <div style={{ padding: 10 }}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10, paddingTop: 10, paddingBottom: 10}}>
                            <div style={{flex: 1, border: "1px solid #ddd"}}></div>
                            <div>Application</div>
                            <div style={{flex: 1, border: "1px solid #ddd"}}></div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>
                            <button style={{flex: 1}}>ACTIVATE MEMBER</button>
                            <button style={{flex: 1}}>Add PICKUP CENTER</button>
                            <button style={{flex: 1}}>ADD MEGA DEPOT</button>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 2, border: "1px solid #ddd", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ padding: 5 }}>
                            <span style={{ fontSize: 30, fontWeight: "bolder" }}>Transaction</span>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10, padding: 5 }}>
                                <span>Search</span>
                                <input className='form-control' style={{ width: 300 }} placeholder='Search...' />
                            </div>
                            <div style={{ padding: 10 }}>
                                <div style={{ fontSize: 20 }}>
                                    <Link to="/raffle/tickets" style={{border: "1px solid #ddd", padding: 5, borderRadius: 5, backgroundColor: "#7eb57e", color: "#fff"}}>Raffle Tickets</Link>
                                </div>
                            </div>
                        </div> */}
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", gap: 10, padding: 5, width: 200, lineHeight: 1 }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 10, width: 300 }}>
                                    <span style={{ fontWeight: "bolder" }}>Container:</span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 20, width: 150 }}>
                                        <span>SET: {transaction?.item?.filter((item) => item.category === 'Container')?.reduce((amount, item) => amount = Number(item.set) + amount, 0)}</span>
                                        <span>QTY: {transaction?.item?.filter((item) => item.category === 'Container')?.reduce((amount, item) => amount = Number(item.qty) + amount, 0)}</span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 10, width: 300 }}>
                                    <span style={{ fontWeight: "bolder" }}>Gallon:</span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 20, width: 150 }}>
                                        <span>SET: {transaction?.item?.filter((item) => item.category === 'Gallon')?.reduce((amount, item) => amount = Number(item.set) + amount, 0)}</span>
                                        <span>QTY: {transaction?.item?.filter((item) => item.category === 'Gallon')?.reduce((amount, item) => amount = Number(item.qty) + amount, 0)}</span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 10, width: 300 }}>
                                    <span style={{ fontWeight: "bolder" }}>Litre:</span>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 20, width: 150 }}>
                                        <span>SET: {transaction?.item?.filter((item) => item.category === 'Litre')?.reduce((amount, item) => amount = Number(item.set) + amount, 0)}</span>
                                        <span>QTY: {transaction?.item?.filter((item) => item.category === 'Litre')?.reduce((amount, item) => amount = Number(item.qty) + amount, 0)}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", gap: 0, lineHeight: 1.5 }}>
                                <span style={{ fontWeight: "bolder", fontSize: 30 }}>Total Monthly Sales: <span style={{ fontSize: 40 }}>{transaction?.item?.filter((i) => i.date_time.includes(transaction.monthdate)).reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                <span style={{ fontWeight: "normal", fontSize: 20 }}>Yesterday Sales: <span style={{ fontSize: 20 }}>{Number(transaction?.item?.filter((i) => i.date_time.includes(transaction.yesterday) && i.payment_method === 'Cash').reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                <span style={{ fontWeight: "normal", fontSize: 20 }}>Today Sales: <span style={{ fontSize: 20 }}>{Number(transaction?.item?.filter((i) => i.date_time.includes(transaction.todaydate) && i.payment_method === 'Cash').reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                <span style={{ fontWeight: "normal", fontSize: 15 }}>Cash: <span style={{ fontSize: 20 }}>{Number(transaction?.item?.filter((i) => i.date_time.includes(transaction.todaydate) && i.payment_method === 'Cash').reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                <span style={{ fontWeight: "normal", fontSize: 15 }}>GCash: <span style={{ fontSize: 20 }}>{Number(transaction?.item?.filter((i) => i.date_time.includes(transaction.todaydate) && i.payment_method === 'GCash').reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                <span style={{ fontWeight: "normal", fontSize: 15 }}>GoTyme: <span style={{ fontSize: 20 }}>{Number(transaction?.item?.filter((i) => i.date_time.includes(transaction.todaydate) && i.payment_method === 'GoTyme').reduce((amount, item) => amount = (Number(item.price) * Number(item.set)) + amount, 0)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                            </div>
                        </div>
                        <div style={{ padding: 5 }}>
                            {/* {format(new Date(), 'yyyy-M-dd')} */}
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th style={{ width: 300 }}>Account ID / Name</th>
                                        {/* <th>Name</th> */}
                                        <th style={{ width: 150 }}>Category</th>
                                        <th>Set</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span>Total</span>
                                                {/* <span style={{ color: "rgb(133 133 133)", fontSize: 12, fontWeight: "normal" }}>Set x Price = Total</span>
                                                <span style={{ color: "rgb(133 133 133)", fontSize: 12, fontWeight: "normal" }}>Qty / Price = Margin</span> */}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transaction?.item?.filter((i) => i.date_time.includes(format(new Date(), 'yyyy-M-dd'))).map((item, key) => {
                                            return (
                                                <tr key={key} style={{ backgroundColor: Number(item.id) === lastId ? "#f1ffc6" : null }}>
                                                    <td>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center" }}>
                                                            <div>{item.user_id}</div>
                                                            {
                                                                item.puc === 'PUC' ?
                                                                    <div>PUC</div>
                                                                    : item.type === 'raffle_ticket' ?
                                                                        <div style={{ color: "green", fontSize: 12 }}>Raffle Entry</div>
                                                                        : item.type === 'refilled' ?
                                                                            <div style={{ color: "blue", fontSize: 12 }}>Refilled</div>
                                                                            : item.type === 'matrix_refill' ?
                                                                                <div style={{ color: "red", fontSize: 12 }}>Matrix</div>
                                                                                : <div style={{ color: "red", fontSize: 12 }}>Entry</div>
                                                            }
                                                        </div>
                                                    </td>
                                                    {/* <td></td> */}
                                                    <td>
                                                        {
                                                            item.type === 'entry' ?
                                                                <>Activation</>
                                                            : item.category
                                                        }
                                                    </td>
                                                    <td>{item.set}</td>
                                                    <td style={{ flex: 1, width: 200 }}>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center" }}>
                                                            <div>{item.qty}</div>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
                                                                <div style={{ fontSize: 13, color: "green" }}>{item.free === "0" ? null : 'Free: +' + item.free}</div>
                                                                <div style={{ fontSize: 13, color: "green" }}>{item.free === "0" ? null : 'Total: ' + Number(Number(item.free) + Number(item.qty))}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{Number(item.price).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                    <td>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center" }}>
                                                            <div>{Number(Number(item.set) * Number(item.price)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                            <div style={{ fontSize: 12 }}>{item.payment_method}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <div style={{flex: 1, display: "flex", flexDirection:"row", justifyContent: "flex-end", alignItems: "center", gap: 10}}>
                            <span style={{fontWeight:"bolder", fontSize: 20}}>Total Due: </span>
                            <span style={{fontWeight:"bolder", fontSize: 20}}>0.00</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pos;
