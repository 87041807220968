import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';

const ChangeAddress = ({ userInfo }) => {

    const [changepasswordform, setChangePasswordForm] = useState(false);




    const [provlist, setProvList] = useState([])
    const [citylist, setCityList] = useState([])
    const [brgylist, setBrgyList] = useState([])
    const [address, setAddress] = useState(false);
    const [input, setInput] = useState({ number: '', code: '' });
    const [inputvalue, setInputValue] = useState({
        "province": 0,
        "city": 0,
        "brgy": 0,
        "address": userInfo?.address,
        "user_id": localStorage.getItem('user_id')
    })


    const saveAddress = async () => {
        var formData = new FormData();
        formData.append("set_address", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        
        if (inputvalue.address != '') {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                console.log(res)
                if (res.data[0].status == 'success') {
                    swal({
                        title: "Done!",
                        text: "Successfull saved",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        // console.log(input)
                        setChangePasswordForm(false)
                        window.location.assign('/profile')
                    });
                } else {

                }
            });
        }
    }



    useMemo(async () => {

        var formData = new FormData();
        formData.append("porvinceList", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setProvList([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);


    useMemo(async () => {

        var formData = new FormData();
        formData.append("citymunList", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setCityList([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);


    useMemo(async () => {

        var formData = new FormData();
        formData.append("brgyList", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/process.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setBrgyList([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);

    // setInput({ ...input, [e.target.name]: e.target.value })
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')
    return (
        <>
            <Sidebar visible={changepasswordform} header={<>Address</>} position="bottom" style={{ minHeight: 500, borderRadius: 0 }} onHide={() => setChangePasswordForm(false)}>

                <div className="input-group mb-3">
                    <select name='province' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.province} className="form-control" style={{ flex: 1, width: "100%" }}>
                        <option selected>Select Province</option>
                        {
                            [...provlist].map((item, key) => {
                                return (
                                    <option key={key} value={item.id}>{item.provDesc}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="input-group mb-3">
                    <select name='city' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.city} className="form-control" style={{ flex: 1, width: "100%" }}>
                        <option selected disabled>Select City</option>
                        {
                            [...citylist].filter((i) => Number(i.provCode) === Number(inputvalue.province)).map((item, key) => {
                                return (
                                    <option key={key} value={item.id}>{item.citymunDesc}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="input-group mb-3">
                    <select name='brgy' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.brgy} className="form-control" style={{ flex: 1, width: "100%" }}>
                        <option selected disabled>Select Barangay</option>
                        {
                            [...brgylist].filter((i) => Number(i.provCode) === Number(inputvalue.province) && Number(i.citymunCode) === Number(inputvalue.city)).map((item, key) => {
                                return (
                                    <option key={key} value={item.id}>{item.brgyDesc}</option>
                                )
                            })
                        }
                    </select>
                </div>


                <div>
                    <>
                        <textarea name='address' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} style={{ width: "100%", height: 200, padding: 5, outline: "none" }}>{inputvalue?.address}</textarea>
                    </>
                    {
                        userInfo?.address != '' ?
                            <button style={{ width: "100%", height: 40, borderRadius: 10, border: "1px solid #ddd", backgroundColor: "#17a2b8", color: "#fff" }} onClick={() => saveAddress()}>Change Address</button>
                            :
                            <button style={{ width: "100%", height: 40, borderRadius: 10, border: "1px solid #ddd", backgroundColor: "#17a2b8", color: "#fff" }} onClick={() => saveAddress()}>Add Address</button>
                    }
                </div>
            </Sidebar>
            {
                userInfo?.address != '' ?
                    <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", borderRadius: 5 }}>
                        Change Address <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><span className='pi pi-chevron-right'></span></span>
                    </div>
                    :
                    <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", borderRadius: 5 }}>
                        Add Address <span className='pi pi-chevron-right'></span>
                    </div>
            }
        </>
    );
}

export default ChangeAddress;
