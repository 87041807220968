import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const ChangePasswordBtn = ({userInfo}) => {
    
    const [changepasswordform, setChangePasswordForm] = useState(false);


    const [OldPassword, setOldPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');



    const [showpassword, setShowPassword] = useState(false);
    const [passwordStatus, setPasswordStatus] = useState('');

    const ChangePassword = async () => {
        var formData = new FormData();
        formData.append("update_password", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("old_password", OldPassword);
        formData.append("new_password", NewPassword);
        formData.append("confirm_password", ConfirmPassword);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            // setActivityList(res.data)
            if (res.data[0].status == 'success') {
                setPasswordStatus('Successfully Changed')
                setTimeout(() => {
                    setPasswordStatus('')
                    setChangePasswordForm(false)
                    setOldPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                }, 2000)
            } else if (res.data[0].status == 'incorrect') {
                setPasswordStatus('Incorent Password')
            } else if (res.data[0].status == 'mismatch') {
                setPasswordStatus('Mismatch Password')
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <>
            <Sidebar closeOnEscape={true} showCloseIcon={false} visible={changepasswordform} header={<>Change Password</>} position="bottom" style={{ height: 350, borderRadius: 0 }} onHide={() => setChangePasswordForm(false)}>
                <div>
                    <div style={{ paddingTop: 5 }}>
                        <span>Old Password</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {
                                showpassword ?
                                    <input type='text' onChange={(e) => setOldPassword(e.target.value)} value={OldPassword} style={{ padding: 5, width: "100%", height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                                    :
                                    <input type='password' onChange={(e) => setOldPassword(e.target.value)} value={OldPassword} style={{ padding: 5, width: "100%", height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                            }
                            <div onClick={() => setShowPassword(showpassword == false ? true : false)} style={{ height: 35, width: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {
                                    showpassword ?
                                        <span className='pi pi-eye-slash'></span>
                                        :
                                        <span className='pi pi-eye'></span>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 5 }}>
                        <span>New Password</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {
                                showpassword ?
                                    <input type='text' onChange={(e) => setNewPassword(e.target.value)} value={NewPassword} style={{ padding: 5, width: "100%", height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                                    :
                                    <input type='password' onChange={(e) => setNewPassword(e.target.value)} value={NewPassword} style={{ padding: 5, width: "100%", height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                            }
                            <div onClick={() => setShowPassword(showpassword == false ? true : false)} style={{ height: 35, width: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {
                                    showpassword ?
                                        <span className='pi pi-eye-slash'></span>
                                        :
                                        <span className='pi pi-eye'></span>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 5 }}>
                        <span>Confirm Password</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {
                                showpassword ?
                                    <input type='text' onChange={(e) => setConfirmPassword(e.target.value)} value={ConfirmPassword} style={{ padding: 5, width: "100%", height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                                    :
                                    <input type='password' onChange={(e) => setConfirmPassword(e.target.value)} value={ConfirmPassword} style={{ padding: 5, width: "100%", height: 35, borderRadius: 5, border: "1px solid #ddd" }} />
                            }
                            <div onClick={() => setShowPassword(showpassword == false ? true : false)} style={{ height: 35, width: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {
                                    showpassword ?
                                        <span className='pi pi-eye-slash'></span>
                                        :
                                        <span className='pi pi-eye'></span>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                    {
                        passwordStatus == 'Successfully Changed' ?
                            <div style={{ color: "green" }}>
                                Successfully Changed
                            </div>
                            :
                            null
                    }
                    {
                        passwordStatus == 'incorrect' ?
                            <div style={{ color: "red" }}>
                                Incorrect Password
                            </div>
                            :
                            null
                    }
                    {
                        passwordStatus == 'Mismatch Password' ?
                            <div style={{ color: "red" }}>
                                Mismatch Password
                            </div>
                            :
                            null
                    }
                    <div style={{ paddingTop: 10 }}>
                        <button onClick={() => ChangePassword()} style={{ width: "100%", height: 35, borderRadius: 5, backgroundColor: "#17a2b8", border: "1px solid #ddd", color: "#fff" }}>Change Password</button>
                    </div>
                </div>
            </Sidebar>
            <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", borderRadius: 5 }}>
                Change Password <span className='pi pi-chevron-right'></span>
            </div>
        </>
    );
}

export default ChangePasswordBtn;
