import React, { useEffect, useMemo, useRef, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';


import { ProgressSpinner } from 'primereact/progressspinner';
import PreSignUpListBtn from './component/dashboard/PreSignUpListBtn';
import Countdown from './extra/Countdown';
import { Skeleton } from 'primereact/skeleton';
import PhoneNumberSetup from './extra/PhoneNumberSetup';
import ChristmasBanner from './extra/ChristmasBanner';
import Stories from './extra/Stories';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom/cjs/react-router-dom';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

import { FaHandHoldingUsd } from "react-icons/fa";
import { FaInbox } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { Sidebar } from 'primereact/sidebar';
import LeftNavSwiper from './component/swipe/LeftNavSwiper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaUserFriends } from "react-icons/fa";
import { FaSitemap } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";



const Dashboard = (props) => {

    const location = useLocation()
    // if (userInfo?.maintenance === true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';


    const [Copied, setCopied] = useState({ id: '', status: false });
    const [directInfo, setDirectInfo] = useState([]);
    const [last28, setLast28] = useState(0);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("direct", 1);
        formData.append("user_id", localStorage.getItem('user_id'));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setDirectInfo([...res.data])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, location.key]);

    const [downlineInfo, setDownlineInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("downline", 1);
        formData.append("user_id", localStorage.getItem('user_id'));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setDownlineInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, location.key]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("entry_last_28", 1);
        formData.append("user_id", localStorage.getItem('user_id'));

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setLast28(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, location.key]);

    const [activitylist, setActivityList] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("showactivity", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setActivityList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, location.key]);

    const [RaffleEntryList, setRaffleEntryList] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("raffle_entry_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'raffle.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setRaffleEntryList([...res.data])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);


    const formatNumber = (number) => {
        // Use the toLocaleString method to add suffixes to the number
        return number.toLocaleString('en-US', {
            // add suffixes for thousands, millions, and billions
            // the maximum number of decimal places to use
            maximumFractionDigits: 2,
            // specify the abbreviations to use for the suffixes
            notation: 'compact',
            compactDisplay: 'short'
        });
    }





    const [count, setCount] = useState(100)

    const [hr, setHr] = useState(0)
    const [min, setMin] = useState(0)
    const [sec, setSec] = useState(0)



    // useEffect(()=>{ // Time
    //     const myInterval = setInterval(()=>{
    //         setSec(sec+1);
    //         if (sec === 59) {
    //             setMin(min+1)
    //             setSec(0);
    //         }
    //         if (min === 59) {
    //             setHr(hr+1)
    //             setMin(0)
    //             setSec(0);
    //         }
    //             setCount(count-1)
    //     }, 1000);
    //     console.log(hr+' : '+min+' : '+sec);
    //     return () => clearInterval(myInterval)
    // }, [count]);


    // console.log(window.innerWidth <= 768)
    // function domReady(fn) {
    //     if (document.readyState === "complete" || document.readyState === "interactive") {
    //         setTimeout(fn, 1);
    //     } else {
    //         document.addEventListener("DOMContentLoaded", fn);
    //     }
    // }

    // domReady(function () {
    //     var myqr = document.getElementById("you-qr-result");
    //     var lastResult, countResults = 0;
    //     function onScanSuccess(decodeText, decodeResult) {
    //         if (decodeText !== lastResult) {
    //             ++countResults;
    //             lastResult = decodeText;

    //             alert("you Qr is: " + decodeText, decodeResult);
    //             myqr.innerHTML = `you scan ${countResults} : ${decodeText}`;

    //         }
    //     }
    //     var htmlscanner = new Html5QrcodeScanner("preview", { fps: 10, qrbox: 250 })

    //     htmlscanner.render(onScanSuccess)
    // })

    // alert(window.innerWidth)



    // useEffect(() => {
    //     window.addEventListener("mousedown", (e)=>{
    //         console.log(e.clientX)
    //     })
    //     window.addEventListener("mousemove", (e)=>{
    //         console.log(e.clientX)
    //     })
    //     window.addEventListener("mouseup", (e)=>{
    //         console.log(e.clientX)
    //     })

    //     return () => {
    //         window.removeEventListener("mousemove", (e)=>{
    //             console.log(e.clientX)
    //         })
    //     }
    //   }, [])


    const [startX, setStartX] = useState(null)
    const [widthRoot, setWidthRoot] = useState(0)
    const [translateX, setTranslateX] = useState(0)
    const [leftright, setLeftRight] = useState('Left')
    const refArea = useRef()

    let touchArea = document.getElementById("touch-area");
    let mouseX, initialX = 0;
    let mouseY, initialY = 0;
    let isSwiped;

    let events = {
        mouse: {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
        },
        touch: {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
        }
    }

    let deviceType = "";

    // Detect touch device

    const isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (error) {
            deviceType = "mouse";
            return false;
        }
    }

    isTouchDevice();

    // console.log(events[deviceType].down)

    let rectLeft = 0; //touchArea.getBoundingClientRect([0]).left;
    let rectTop = 0; //touchArea.getBoundingClientRect([0]).top;
    // useEffect(()=>{
    //     if (props.userInfo) {
    rectLeft = touchArea?.getBoundingClientRect().left;
    rectTop = touchArea?.getBoundingClientRect().top;
    //     }
    // }, [])

    const getXY = (e) => {
        mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
        mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    }

    // isTouchDevice();

    // console.log(touchArea)


    touchArea?.addEventListener(events[deviceType].down, (event) => {
        isSwiped = true;
        getXY(event);
        initialX = mouseX;
        initialY = mouseY;
    });

    let diffX = 0;
    let diffY = 0;

    touchArea?.addEventListener(events[deviceType].move, (event) => {
        if (!isTouchDevice()) {
            event.preventDefault();
        }
        if (isSwiped) {
            getXY(event);            
            diffX = mouseX - initialX;
            diffY = mouseY - initialY;
            if (Math.abs(diffY) > Math.abs(diffX)) {
                // console.log(diffY > 0 ? "Down" : "Up")
                // output.innerText = diffY > 0 ? "Down" : "Up"
            } else {
                // console.log(diffX > 0 ? "Right" : "Left")
                // if((diffY > 0 ? "Down" : "Up") !== "Down" || (diffY > 0 ? "Down" : "Up") !== "Up"){
                // setLeftRight(diffX > 0 ? "Right" : "Left")
                // }
                // output.innerText = diffX > 0 ? "Right" : "Left"
                    console.log("X", mouseX)
                if (leftright !== 'Right') {
                    setLeftRight(diffX > 0 ? "Right" : "Left")
                }
            }
        }
    });

    touchArea?.addEventListener(events[deviceType].up, (event) => {
        isSwiped = false;
    });

    touchArea?.addEventListener("mouseleave", (event) => {
        isSwiped = false;
    });

    window.onload = () => {
        isSwiped = false;
    }

    
    useEffect(() => {
        var ele = document.getElementById('myBlinkingText');
        // ele.style.visibility = (ele.style.visibility == '' ? 'hidden' : '');
        // console.log('first')
        // setInterval(function() {
        //     ele.style.visibility = (ele.style.visibility == 'hidden' ? '' : 'hidden');
        // }, 1000);
    }, []);
    


    return (
        <div className="wrapper" ref={refArea} id='touch-area'>
            <LeftNavSwiper userInfo={props.userInfo} leftright={leftright} setLeftRight={setLeftRight} tab="pre-signup" raffle_program={true} />
            {/* <div ref={refArea} style={{width: 15, height: "100%", backgroundColor: "#ddd", position: "fixed", zIndex: 1}} id='touch-area'></div> */}
            {/* <div id="preview"></div>
            <div id="you-qr-result"></div> */}
            {/* <PhoneNumberSetup userInfo={props.userInfo} /> */}
            <ChristmasBanner userInfo={props.userInfo} />
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{ flex: 1, height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div onClick={()=>setLeftRight('Right')} style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bars'></span>
                                </div>
                            </div>
                            <div style={{ flex: 4, paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <img src={require('./sdsadasd4a65sd51a56s4da.png')} style={{ width: 45, height: 45, borderRadius: 100 }} />
                                <div style={{ display: "flex", flexDirection: "column", color: "green", lineHeight: 1 }}>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute" }}>Mirabile</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7 }}>&</span>
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12 }}>Proventus
                                        <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5 }}>Co.</span>
                                    </span>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <span className='pi pi-bell'></span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}

                {/* <Stories /> */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}

                        {/* POST */}
                        {/* <div style={{width: "100%", border: "1px solid #000"}} className='hidescroll'> */}

                        {/* </div> */}

                        <div style={{ flex: 1, marginBottom: 20 }}>
                            <div style={{ padding: "20px 0px" }}>
                                <span style={{ fontSize: 40, fontWeight: "bolder" }}>Welcome to</span>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 20 }}>
                                <div>
                                    <div>
                                        <span style={{ fontSize: 20 }}>Hello, {props.userInfo?.fullname != null ? props.userInfo?.fullname : 'Loading...'}</span>
                                    </div>
                                    <div>
                                        {
                                            props.userInfo?.account_status == 1 ?
                                                <CopyToClipboard text={ENV.DOMAIN + 'register/' + localStorage.getItem('user_id')}
                                                    onCopy={() => {
                                                        setCopied({ id: localStorage.getItem('user_id'), status: true })
                                                        setTimeout(() => {
                                                            setCopied({ id: localStorage.getItem('user_id'), status: false })
                                                        }, 1000)
                                                    }}>
                                                    {
                                                        Copied.id === localStorage.getItem('user_id') && Copied.status === true ?
                                                            <button style={{ width: 100, height: 35, color: "#fff", backgroundColor: "gold", border: "1px solid #b5a54d", borderRadius: 5, fontWeight: "", borderRadius: 10, display: "flex", flexDirection: "row", gap: 10, alignItems: "center" }}>
                                                                <span className='pi pi-link'></span> <span>Copied</span>
                                                            </button>
                                                            :
                                                            // <><span className='pi pi-link'></span> My Link</>
                                                            <button style={{ width: 100, height: 35, color: "#fff", backgroundColor: "gold", border: "1px solid #b5a54d", borderRadius: 5, fontWeight: "", borderRadius: 10, display: "flex", flexDirection: "row", gap: 10, alignItems: "center" }}>
                                                                <span className='pi pi-link'></span> <span>My Link</span>
                                                            </button>
                                                    }
                                                </CopyToClipboard>
                                                : null
                                        }
                                    </div>
                                </div>

                                <div>
                                    <Link to={"/raffle_program"}>
                                        <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }} id='myBlinkingBackground'>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ padding: 10, textAlign: "center" }} id='myBlinkingText'>
                                                    Raffle Program
                                                    <br />
                                                    {RaffleEntryList.length+" Entries"}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div style={{display: "none"}}>
                                    <Link to={"/announcement"}>
                                        <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, height: 200, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                            <div style={{ flex: 1, height: 200, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ padding: 10, borderBottom: "1px solid #fff" }}>
                                                    Announcement
                                                    {/* <br />
                                                    {leftright} */}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Income Transactions</div>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ flex: 1 }}>
                                                {
                                                    [...activitylist].filter((i) => String(i.user_id) === String(localStorage.getItem('user_id'))).map((i) => i).map((item, key) => {
                                                        return (
                                                            <div style={{ padding: 10, marginBottom: 2 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div>
                                                                        <div style={{ fontSize: 18 }}>{item.fullname}</div>
                                                                        <div>{item.description}</div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ border: "0.5px solid #ddd" }}></div>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "50%" }}>
                                                                        <small>{item.date_time}</small>
                                                                    </div>
                                                                    {
                                                                        item.status === 'matrix_refill' ?
                                                                            <small>Refilled</small>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.status === 'matrix' ?
                                                                            <small>Monthly Bonus</small>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.status === 'unilevel' ?
                                                                            <small>Unilevel Bonus</small>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }).slice(0, 1)
                                                }
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 10 }}>
                                                <Link to={"/income/transaction"}>
                                                    <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                        <FaInbox size={20} /> More
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Your Current Rank Status</div>
                                        <div style={{ flex: 1, height: 100, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <div style={{ fontWeight: "bolder", fontSize: 30 }}>{props.userInfo?.rank_status}</div>
                                            {
                                                props.userInfo?.rank_status !== 'GRANDMASTER' ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                                        Next rank:
                                                        <span style={{ fontSize: 20, fontWeight: "bolder", }}>
                                                            {
                                                                props.userInfo?.rank_status === 'MASTER' ?
                                                                    <span>GRANDMASTER</span>
                                                                    : props.userInfo?.rank_status === 'ELITE' ?
                                                                        <span>MASTER</span>
                                                                        : props.userInfo?.rank_status === 'ROOKIE' ?
                                                                            <span>ELITE</span>
                                                                            : props.userInfo?.rank_status === 'TYRO' ?
                                                                                <span>ROOKIE</span>
                                                                                : props.userInfo?.rank_status === 'UNRANK' ?
                                                                                    <span>TYRO</span>
                                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Total Earnings</div>
                                        <div style={{ flex: 1, padding: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ flex: 2 }}>
                                                <div>
                                                    <span style={{ fontSize: 35, fontWeight: "bolder" }}>
                                                        {/* {Number(props.userInfo?.total_earning)} */}
                                                        PHP{formatNumber(Number(props.userInfo?.total_earning*55)).toString(2)}
                                                    </span>
                                                </div>
                                                <div>
                                                    <Link to={"/wallet"}>
                                                        <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                            <FaHandHoldingUsd size={24} /> Payout
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaWallet size={55} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Referral Bonus</div>
                                        <div style={{ flex: 1, padding: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ flex: 2 }}>
                                                <div>
                                                    <span style={{ fontSize: 35, fontWeight: "bolder" }}>
                                                        {/* {Number(props.userInfo?.balance)} */}
                                                        PHP{formatNumber(Number(props.userInfo?.referral_bonus*55)).toString(2)}
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <Link to={"/wallet"}>
                                                        <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                            <FaHandHoldingUsd size={24} /> Payout
                                                        </button>
                                                    </Link>
                                                </div> */}
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaUserFriends size={55} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Matrix Bonus</div>
                                        <div style={{ flex: 1, padding: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ flex: 2 }}>
                                                <div>
                                                    <span style={{ fontSize: 35, fontWeight: "bolder" }}>
                                                        {/* {Number(props.userInfo?.balance)} */}
                                                        PHP{formatNumber(Number(props.userInfo?.matrix_bonus*55)).toString(2)}
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <Link to={"/wallet"}>
                                                        <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                            <FaHandHoldingUsd size={24} /> Payout
                                                        </button>
                                                    </Link>
                                                </div> */}
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaSitemap size={55} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Matching Bonus</div>
                                        <div style={{ flex: 1, padding: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ flex: 2 }}>
                                                <div>
                                                    <span style={{ fontSize: 35, fontWeight: "bolder" }}>
                                                        {/* {Number(props.userInfo?.balance)} */}
                                                        ${formatNumber(0).toString(2)}
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <Link to={"/wallet"}>
                                                        <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                            <FaHandHoldingUsd size={24} /> Payout
                                                        </button>
                                                    </Link>
                                                </div> */}
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaShareAlt size={55} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Matrix Unilevel Bonus</div>
                                        <div style={{ flex: 1, padding: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ flex: 2 }}>
                                                <div>
                                                    <span style={{ fontSize: 50, fontWeight: "bolder" }}>
                                                        {/* {Number(props.userInfo?.balance)} */}
                                                        ${formatNumber(0).toString(2)}
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <Link to={"/wallet"}>
                                                        <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                            <FaHandHoldingUsd size={24} /> Payout
                                                        </button>
                                                    </Link>
                                                </div> */}
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaUsers size={55} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Refill Unilevel Bonus</div>
                                        <div style={{ flex: 1, padding: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ flex: 2 }}>
                                                <div>
                                                    <span style={{ fontSize: 50, fontWeight: "bolder" }}>
                                                        {/* {Number(props.userInfo?.balance)} */}
                                                        ${formatNumber(0).toString(2)}
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <Link to={"/wallet"}>
                                                        <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                            <FaHandHoldingUsd size={24} /> Payout
                                                        </button>
                                                    </Link>
                                                </div> */}
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaUsers size={55} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .08)" }}>
                                        <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>Matrix Cash Rewards</div>
                                        <div style={{ flex: 1, padding: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ flex: 2 }}>
                                                <div>
                                                    <span style={{ fontSize: 50, fontWeight: "bolder" }}>
                                                        {/* {Number(props.userInfo?.balance)} */}
                                                        ${formatNumber(0).toString(2)}
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <Link to={"/wallet"}>
                                                        <button style={{ padding: 5, width: 110, borderRadius: 10, backgroundColor: "gold", border: "1px solid #b5a54d", color: "#fff", fontWeight: "bolder" }}>
                                                            <FaHandHoldingUsd size={24} /> Payout
                                                        </button>
                                                    </Link>
                                                </div> */}
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <FaUsers size={55} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ////////////////////////////// */}
                        <div style={{ display: "none" }}>
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: 10, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                        <span>
                                            <img src={props.userInfo?.image != null ? ENV.DOMAIN + props.userInfo?.image : "../dist/img/user1-128x128.jpg"} style={{ width: 50, height: 50, borderRadius: 100, border: "1px solid #ddd" }} />
                                        </span>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                            <span style={{ fontSize: 18, fontWeight: 600 }}>{props.userInfo?.fullname != null ? props.userInfo?.fullname : 'Loading...'}</span>
                                            <small style={{ fontSize: 10 }}>{props.userInfo?.rank_status}</small>
                                            <small style={{ fontSize: 10 }}>ID: <span style={{ fontWeight: "bold" }}>{localStorage.getItem('user_id')}</span></small>
                                            {
                                                props.userInfo?.direct_sponsor_name != '' ?
                                                    <small style={{ fontSize: 10 }}>Direct Upline: <span style={{ textDecoration: "underline", fontWeight: 600 }}>{props.userInfo?.direct_sponsor_name}</span></small>
                                                    :
                                                    null
                                            }
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                <span style={{ fontSize: 12 }}>Balance:
                                                    $ {props.userInfo?.balance}</span>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                <span style={{ fontSize: 12 }}>{[...directInfo].filter((i) => String(i.sponsor_id) === String(localStorage.getItem('user_id'))).map((i) => i).length} Direct</span>
                                                <span style={{ width: 5, height: 5, backgroundColor: "#bfbcc2", borderRadius: 100 }}></span>
                                                <span style={{ fontSize: 12 }}>{[...downlineInfo].filter((i) => String(i.user_id) === String(localStorage.getItem('user_id'))).map((i) => i).length} Downline</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div style={{ paddingBottom: 0 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
                                        <span onClick={() => window.location.assign('/matrix/genealogy')} style={{ padding: 5, border: "1px solid #ddd", color: "#fff", backgroundColor: "#17a2b8", borderRadius: 5, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div>$ {props.userInfo?.total_earning ? Number(props.userInfo?.total_earning).toFixed(2) : '0.00'}</div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Total Earning</span>
                                            </div>
                                        </span>
                                        <span onClick={() => window.location.assign('/personal/genealogy')} style={{ padding: 5, border: "1px solid #ddd", color: "#fff", backgroundColor: "#e5ad05", borderRadius: 5, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div>$ {props.userInfo?.referral_bonus ? Number(props.userInfo?.referral_bonus).toFixed(2) : '0.00'}</div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Weekly Bonus</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: 0 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
                                        <span onClick={() => window.location.assign('/matrix/genealogy')} style={{ padding: 5, border: "1px solid #ddd", color: "#fff", backgroundColor: "#6366F1", borderRadius: 5, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div>$ {props.userInfo?.matrix_bonus ? Number(props.userInfo?.matrix_bonus).toFixed(2) : '0.00'}</div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Monthly Bonus</span>
                                            </div>
                                        </span>
                                        <span onClick={() => window.location.assign('/personal/genealogy')} style={{ padding: 5, border: "1px solid #ddd", color: "#fff", backgroundColor: "#dc3545", borderRadius: 5, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div>$ 0.00</div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Year-end Profit Sharing</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: 0 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
                                        <span onClick={() => window.location.assign('/matrix/unilevel')} style={{ padding: 5, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div style={{ display: "flex", height: 40, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                {/* <i className="pi pi-sitemap" style={{ color: '#708090', fontSize:40 }}></i> */}
                                                <img src='https://img.icons8.com/?size=256&id=sS0ovqv4ZaFo&format=png' style={{ width: 40, height: 40 }} />
                                            </div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Uni-level Matrix</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: 0 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
                                        <span onClick={() => window.location.assign('/matrix/genealogy')} style={{ padding: 5, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div style={{ display: "flex", height: 40, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                {/* <i className="pi pi-sitemap" style={{ color: '#708090', fontSize:40 }}></i> */}
                                                <img src='https://img.icons8.com/?size=256&id=sS0ovqv4ZaFo&format=png' style={{ width: 40, height: 40 }} />
                                            </div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Matrix Genealogy</span>
                                            </div>
                                        </span>
                                        <span onClick={() => window.location.assign('/personal/genealogy')} style={{ padding: 5, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div style={{ display: "flex", height: 40, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                {/* <i className="pi pi-sitemap" style={{ color: '#708090', fontSize:40 }}></i> */}
                                                <img src='https://img.icons8.com/?size=256&id=LBvXB546XHAT&format=png' style={{ width: 40, height: 40 }} />
                                            </div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Personal Genealogy</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: 0 }}>
                                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>

                                        <span onClick={() => window.location.assign('/downline')} style={{ padding: 5, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5, width: 140, textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div style={{ display: "flex", height: 40, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                {/* <i className="pi pi-sitemap" style={{ color: '#708090', fontSize:40 }}></i> */}
                                                <img src='https://img.icons8.com/?size=256&id=Opt0sU9CtbAW&format=png' style={{ width: 40, height: 40 }} />
                                            </div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Downline</span>
                                            </div>
                                        </span>

                                        <span onClick={() => window.location.assign('/unilevel')} style={{ padding: 5, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5, width: 140, textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div style={{ display: "flex", height: 40, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                {/* <i className="pi pi-sitemap" style={{ color: '#708090', fontSize:40 }}></i> */}
                                                <img src='https://img.icons8.com/?size=256&id=RgrhisLlDGBk&format=png' style={{ width: 40, height: 40 }} />
                                            </div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Unilevel</span>
                                            </div>
                                        </span>

                                        <span onClick={() => window.location.assign('/matrix')} style={{ padding: 5, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5, width: 140, textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <div style={{ display: "flex", height: 40, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                {/* <i className="pi pi-sitemap" style={{ color: '#708090', fontSize:40 }}></i> */}
                                                <img src='https://img.icons8.com/?size=256&id=VO4LwOs9bSlI&format=png' style={{ width: 40, height: 40 }} />
                                            </div>
                                            <div style={{ lineHeight: 1 }}>
                                                <span style={{ fontSize: 12 }}>Matrix</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <PreSignUpListBtn userInfo={props.userInfo} />
                            <div style={{ paddingTop: 5 }}>
                                {/* onClick={()=>window.location.assign('/pickupcenter')} */}
                                {/* <div onClick={()=>window.location.assign('/pickupcenter')} style={{fontSize:15, height:35, backgroundColor:"#fff", padding:5, borderRadius:5, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", border:"1px solid #ddd"}}>
                                    <span style={{fontWeight:600}}>Pick-Up Center</span>
                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center", gap:5}}> 
                                        <div style={{padding:2, paddingLeft:10, paddingRight:10, borderRadius:100, backgroundColor:"rgb(139 140 167)", color:"#fff", minWidth:20, fontSize:12}}>SOON</div> 
                                        <span className='pi pi-chevron-right'></span>
                                    </div>
                                </div> */}
                            </div>
                            <div style={{ paddingBottom: 0 }}>
                                <div style={{ fontSize: 15, paddingTop: 5, paddingBottom: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ fontWeight: 600 }}>Your Directs</span>
                                    {
                                        directInfo.length >= 2 ?
                                            <span onClick={() => window.location.assign('/direct')} style={{ fontWeight: 600, textDecoration: "underline" }}>show all</span>
                                            :
                                            null
                                    }
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", height: 75, alignItems: "center", gap: 15, overflowX: "scroll", overflowY: "hidden" }}>
                                    {
                                        [...directInfo].filter((i) => String(i.sponsor_id) === String(localStorage.getItem('user_id'))).map((i) => i).length > 0 ?
                                            [...directInfo].filter((i) => String(i.sponsor_id) === String(localStorage.getItem('user_id'))).map((item, key) => {
                                                // if(Number(key+1)<=5){
                                                return (
                                                    <div key={key} onClick={() => window.location.assign('/profile/' + item?.user_id)} style={{ width: 50, height: 70, borderRadius: 5, backgroundColor: "rgb(244 246 249)" }}>
                                                        <div style={{ width: 50, height: 50, backgroundColor: "#ddd", borderRadius: 5 }}>
                                                            <img src={ENV.DOMAIN + item?.image} style={{ width: 50, height: 50, borderRadius: 5, border: "1px solid #ddd" }} />
                                                        </div>
                                                        <div style={{ fontSize: 8, wordWrap: "break-word" }}>{item?.fullname}</div>
                                                    </div>
                                                )
                                                // }
                                            }).slice(0, 5)
                                            :
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 15 }}>
                                                <Skeleton size="3rem" className="mr-0"></Skeleton>
                                                <Skeleton size="3rem" className="mr-0"></Skeleton>
                                                <Skeleton size="3rem" className="mr-0"></Skeleton>
                                                <Skeleton size="3rem" className="mr-0"></Skeleton>
                                                {/* <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                            </div>
                                    }
                                </div>
                            </div>
                            <div style={{ paddingBottom: 5 }}>
                                <div style={{ fontSize: 15, paddingTop: 5, paddingBottom: 1 }}>Performance</div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                    <div style={{ fontSize: 10 }}>Last 28 days Entry: {last28 ?? 0}</div>
                                </div>
                            </div>
                            <div>
                                <div style={{ paddingTop: 0, paddingBottom: 5 }}>
                                    <span>Activity</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    {
                                        [...activitylist].filter((i) => String(i.user_id) === String(localStorage.getItem('user_id'))).map((i) => i).length > 0 ?
                                            <>
                                                {
                                                    [...activitylist].filter((i) => String(i.user_id) === String(localStorage.getItem('user_id'))).map((i) => i).map((item, key) => {
                                                        return (
                                                            <div className="card" style={{ padding: 10, marginBottom: 2 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div>
                                                                        <div style={{ fontSize: 18 }}>{item.fullname}</div>
                                                                        <div>{item.description}</div>
                                                                    </div>
                                                                    {/* <small>{item.status}</small> */}
                                                                </div>
                                                                <div style={{ border: "0.5px solid #ddd" }}></div>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "50%" }}>
                                                                        <small>{item.date_time}</small>
                                                                    </div>
                                                                    {
                                                                        item.status === 'matrix_refill' ?
                                                                            <small>Refilled</small>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.status === 'matrix' ?
                                                                            <small>Monthly Bonus</small>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.status === 'unilevel' ?
                                                                            <small>Unilevel Bonus</small>
                                                                            :
                                                                            null
                                                                    }
                                                                    {/* <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                                                                <small><b>Placement</b></small>
                                                                                <small>{item.placement_name}</small>
                                                                                <small>{item.placement_user_id != '' ? item.placement_user_id : ''}</small>
                                                                            </div> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }).slice(0, 20)
                                                }
                                            </>
                                            :
                                            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                {
                                                    [1, 2, 3, 4].map(() => {
                                                        return (

                                                            <div style={{ backgroundColor: "#fff", borderRadius: 5, border: "1px solid #ddd", padding: 10, boxShadow: "0px 0px 0px 3px argb(0,0,0,.3)" }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                                                                    <Skeleton width="100%" className="mb-2"></Skeleton>
                                                                    <Skeleton width="20%" className="mb-2"></Skeleton>
                                                                </div>
                                                                <div>
                                                                    <Skeleton width="40%" className="mb-2"></Skeleton>
                                                                </div>
                                                                <div>
                                                                    <Skeleton width="40%" className="mb-2"></Skeleton>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            <div style={{paddingTop:0, paddingBottom:5}}>
                                <span>Dashboard</span>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h4>
                                                $ {props.userInfo?.total_earning ? props.userInfo?.total_earning : '0.00'}</h4>
                                            <p>Total Earnings</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-bag" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h4>
                                                $ {props.userInfo?.referral_bonus ? props.userInfo?.referral_bonus : '0.00'}</h4>
                                            <p>Weekly Bonus</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person-add" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-primary">
                                        <div className="inner">
                                            <h4>
                                                $ {props.userInfo?.matrix_bonus ? props.userInfo?.matrix_bonus : '0.00'}</h4>
                                            <p>Monthly Bonus</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-pie-graph" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="small-box bg-danger">
                                        <div className="inner">
                                            <h4>
                                                $ 0.00</h4>
                                            <p>Year-end Profit Sharing</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-pie-graph" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* /.row */}
                        {/* Main row */}
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}

                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div style={{color:"#fff", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#71a3f5", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                        <i className='pi pi-microsoft' />
                        <span style={{fontSize: 12, color:"#fff"}}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Profile</span>
                    </div>
                </div> */}
                {/* /.content */}
            </div>
        </div>

    );
}

export default Dashboard;
