import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';

const CreateNewOrder = (props) => {
    const [visible, setVisible] = useState(false);
    const [application, setApplication] = useState([]);
    const [inputvalue, setInputValue] = useState({
        "codetype": "",
        "default_price": "",
        "qty_codes": "",
        "number_unit": "",
        "baseunit": "",
        "source_stock": "",
        "source_addr": "",
        "type": "",
        "sched_pickup": "",
        "driver_helper": "",
        "delivery_fee": "",
        "track_plate": "",
        "user_id": props.puc_id,
        "admin_id":""
    });


    const [useraccountlist, setUserAccountlist] = useState([])
    useMemo(async () => {

        var formData = new FormData();
        formData.append("user_accounts", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setUserAccountlist([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])

    const ProcessOrder = async () => {
        alert(JSON.stringify(inputvalue))


        var formData = new FormData();
        formData.append("createneworder", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/sample.php',
            // url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setInputValue({
                "codetype": "",
                "default_price": "",
                "qty_codes": "",
                "number_unit": "",
                "baseunit": "",
                "source_stock": "",
                "source_addr": "",
                "type": "",
                "sched_pickup": "",
                "driver_helper": "",
                "delivery_fee": "",
                "track_plate": "",
                "user_id": props.puc_id,
                "admin_id":""
            })
        }).catch((err) => {

        });
    }


    // useEffect(()=>{
    //     if (inputvalue.type === 'Pickup') {
    //         setInputValue(prev=>({...prev, "delivery_fee": "", "driver_helper": "", "track_plate": 0}))
    //     } else if (inputvalue.type === 'Delivery') {
    //         setInputValue(prev=>({...prev, "sched_pickup": ""}))
    //     }
    // }, [inputvalue])

    return (
        <>
            <Dialog
                header={
                    <>Create Order</>
                }
                position={"top"}
                draggable={false}
                visible={visible}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                modal className="p-fluid"
                style={{ width: 800 }}
                onHide={() => setVisible(false)}>
                <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 10}}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: 10 }}>
                            <div style={{ flex: 1 }}>
                                <div>
                                    Activation Code
                                    <select name='codetype' onChange={(e)=>{
                                            setInputValue(prev=>({...prev, [e.target.name]: e.target.value, "default_price": ""}))
                                    }} className='form-control' value={inputvalue.codetype}>
                                        <option value={""} selected disabled>Type</option>
                                        <option value={"Activation"}>Activation Code</option>
                                        <option value={"Matrix"}>Matrix Code</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{flex: 1, display: "flex", flexDirection: "row", gap: 10}}>
                                <div style={{flex: 1}}>
                                    <div>
                                        <span>Default Price</span>
                                    </div>
                                    {
                                        inputvalue.codetype === 'Activation' ?
                                            <select name='default_price' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.default_price} className='form-control'>
                                                <option value={""} selected>0.00</option>
                                                <option value={"1988"}>1,988</option>
                                                <option value={"3888"}>3,888</option>
                                            </select>
                                            :
                                            inputvalue.codetype === 'Matrix' ?
                                                <select name='default_price' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.default_price} className='form-control'>
                                                    <option value={""} selected>0.00</option>
                                                    <option value={"788"}>788</option>
                                                    <option value={"698"}>698</option>
                                                </select>
                                                : 
                                                <select className='form-control'>
                                                    <option selected disabled>...</option>
                                                </select>
                                    }
                                </div>
                                <div style={{ flex: 1 }}>
                                    Qty of Codes
                                    <input type='number' name='qty_codes' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.qty_codes} className='form-control' placeholder='0' />
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: 10 }}>
                            <div style={{ flex: 1 }}>
                                <div>
                                    Category
                                    <select name='baseunit' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.baseunit} className='form-control'>
                                        <option value={""} selected disabled>...</option>
                                        <option value={"Liter"}>Liter</option>
                                        <option value={"Gallon"}>Gallon</option>
                                        <option value={"Container"}>Container</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                <div style={{ flex: 1 }}>
                                    Number of Unit
                                    <input name='number_unit' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.number_unit} className='form-control' placeholder='0' />
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: 10 }}>
                            <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 10}}>
                                <div>
                                    Source of Stock
                                    <select name='source_stock' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.source_stock} className='form-control'>
                                        <option value={""}  selected disabled>...</option>
                                        <option value={"Production"}>Production</option>
                                        <option value={"Warehouse"}>Warehouse</option>
                                    </select>
                                </div>
                                {
                                    inputvalue.source_stock === 'Production' || inputvalue.source_stock === 'Warehouse' ?
                                        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                            <span>Address of {inputvalue.source_stock}</span>
                                            <textarea name='source_addr' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' style={{ resize: "none" }}>
                                                {inputvalue.source_addr}
                                            </textarea>
                                        </div>
                                        : null
                                }
                            </div>
                            <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", gap: 10}}>
                                <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: 10 }}>
                                    <div style={{ flex: 1}}>
                                        <span>Type</span>
                                        <select name='type' onChange={(e) => {
                                            if (e.target.value !== '') {
                                                if (inputvalue.type === 'Delivery') {
                                                    setInputValue(prev=>({...prev, [e.target.name]: e.target.value, "delivery_fee": "", "driver_helper": "", "track_plate": ""}))
                                                } else if (inputvalue.type === 'Pickup') {
                                                    setInputValue(prev=>({...prev, [e.target.name]: e.target.value, "sched_pickup": ""}))
                                                } else {
                                                    setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))
                                                }
                                            }
                                            
                                        }} className='form-control' value={inputvalue.type}>
                                            <option value={""} selected>...</option>
                                            <option value={"Pickup"}>Pickup</option>
                                            <option value={"Delivery"}>Delivery</option>
                                        </select>
                                    </div>
                                    {
                                        inputvalue.type === 'Delivery' ?
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                <span>Fee</span>
                                                <input type='number' name='delivery_fee' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.delivery_fee} className='form-control' placeholder='0' />
                                            </div>
                                            : inputvalue.type === 'Pickup' ?
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                <span>Schedule for Pickup</span>
                                                <input type='date' name='sched_pickup' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.sched_pickup} className='form-control' placeholder='0' />
                                            </div>
                                            : null
                                    }
                                </div>
                                {
                                    inputvalue.type === 'Delivery' ?
                                        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                <span>Driver/Helper</span>
                                                <textarea name='driver_helper' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' style={{ resize: "none" }}>
                                                    {inputvalue.driver_helper}
                                                </textarea>
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                <span>Track Number / Plate Number</span>
                                                <input type='number' name='track_plate' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.track_plate} className='form-control' placeholder='0' />
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div style={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <div style={{flex: 1, display: "flex", gap: 10}}>
                                <button className='btn btn-info btn-sm' onClick={()=>ProcessOrder()}>Process Order</button>
                                <button className='btn btn-warning btn-sm' onClick={() => {
                                    setVisible(false)
                                    setInputValue({
                                        "codetype": "",
                                        "default_price": "",
                                        "qty_codes": "",
                                        "number_unit": "",
                                        "baseunit": "",
                                        "source_stock": "",
                                        "source_addr": "",
                                        "type": "",
                                        "sched_pickup": "",
                                        "driver_helper": "",
                                        "delivery_fee": "",
                                        "track_plate": "",
                                        "user_id": props.puc_id,
                                        "admin_id":""
                                    })
                                }}>Cancel Order</button>
                            </div>
                            <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 10}}>
                                <div style={{flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10}}>
                                    <span>Total Amount:</span>
                                    <span style={{fontSize: 20, fontWeight: "bolder"}}>{Number(inputvalue.default_price*inputvalue.qty_codes).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                                <div style={{flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10}}>
                                    <span>Total {inputvalue.baseunit}:</span>
                                    <span style={{fontSize: 20, fontWeight: "bolder"}}>{Number(inputvalue.qty_codes*inputvalue.number_unit)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div></div>
                    </div>


                    {/* <div>
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Types of Entry</th>
                                    <th>Base Unit</th>
                                    <th>Type</th>
                                    <th>Source Stock</th>
                                    <th>Qty for Each Code</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>0.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}

                </div>
            </Dialog>
            <button onClick={() => setVisible(true)} className='btn btn-info btn-sm'>Create New Order</button>
            {/* <button onClick={() => setVisible(true)} className='btn btn-info btn-sm'>Add Pickup Center</button> */}
            {/* <div onClick={() => setVisible(true)} style={{ padding: 5, border: "1px solid #ddd", width: "100%", borderRadius: 5, textAlign: "center", backgroundColor: "#fff", color: "#000" }}>Enter Matrix Code</div> */}
        </>
    );
}

export default CreateNewOrder;
