import React, { useState } from 'react';

const EventForm = (props) => {

    const [visible, setVisible] = useState(true)

    console.log(props.inputvalue)


    return (
        <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>

            {/* Event Code */}
            {
                visible ?
                    <div style={{ width: "100%", padding: 10, display: "flex", flexDirection: "column", gap: 20 }}>
                        <div>
                            <span style={{ fontWeight: "bolder", fontSize: 20 }}>Mirabile & Proventos Co. Event</span>
                        </div>
                        <div>
                            <span>Fullname</span>
                            <input className='form-control' name='fullname' onChange={(e)=>props.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={props.inputvalue.fullname} />
                        </div>
                        {/* <div>
                            <span>Contact</span>
                            <input className='form-control' name='Contacy' onChange={(e)=>props.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} />
                        </div>
                        <div>
                            <span>Address</span>
                            <input className='form-control' name='address' onChange={(e)=>props.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} />
                        </div>
                        <div>
                            <span>Who invites you?</span>
                            <input className='form-control' name='address' onChange={(e)=>props.setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} />
                        </div> */}

                        <div>
                            <button onClick={()=>props.AddNew()} className='btn btn-info btn-block'>Submit</button>
                        </div>

                    </div>
                    :
                    <div style={{ width: "100%", padding: 10, display: "flex", flexDirection: "column", gap: 20 }}>
                        <div>
                            <span style={{ fontWeight: "bolder", fontSize: 20 }}>Mirabile & Proventos Co. Event</span>
                        </div>
                        <div>
                            <span>Event Code</span>
                            <input className='form-control' />
                        </div>

                        <div style={{ flex: 1 }}>
                            <button onClick={()=>props.AddNew()} className='btn btn-info btn-block'>Submit</button>
                        </div>

                    </div>
            }

            {/* Event Form */}
            {/* <div style={{width: "100%", padding: 10, display: "flex", flexDirection: "column", gap: 20}}>
                <div>
                    <span style={{fontWeight: "bolder", fontSize: 20}}>Mirabile & Proventos Co. Event</span>
                </div>
                <div>
                    <span>Fullname</span>
                    <input className='form-control' />
                </div>
                <div>
                    <span>City</span>
                    <input className='form-control' />
                </div>
                <div>
                    <span>Barangay</span>
                    <input className='form-control' />
                </div>
                <div>
                    <span>Address</span>
                    <input className='form-control' />
                </div>

                <div>
                    <button className='btn btn-info btn-block'>Submit</button>
                </div>

            </div> */}

        </div>
    );
}

export default EventForm;
