import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../../ENV';
import { Sidebar } from 'primereact/sidebar';
import swal from 'sweetalert';

const MatrixCode = (props) => {
    const [visible, setVisible] = useState(false);
    const [inputvalue, setInputValue] = useState({});
    // console.log({...props.userInfo, ...props.item, ...inputvalue})
    const EncodeMatrix = async () => {
        var formData = new FormData();
        formData.append("EncodeMatrix", 1);
        formData.append("inputvalue", JSON.stringify({...props.userInfo, ...props.item, ...inputvalue}));
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            props.ListMatrixCodeReload();
        }).catch((err) => {

        });
    }

    return (
        <>
            <Sidebar
            icons={
                <div style={{width: '100%', fontSize: 20, fontWeight: "bolder"}}>Matrix Code</div>
            }
             visible={visible} blockScroll={true} showCloseIcon={true} position="bottom" style={{ minHeight: 500, borderRadius: 0 }} onHide={() => setVisible(false)}>
                <div>
                    
                    <div>
                        <div style={{padding:10, display: "flex", flexDirection: "column"}}>
                            <span style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>Price: <span style={{fontSize:20, fontWeight:600}}>{Math.round(Number(props.item.amount))}</span></span>
                            <span style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>Code: <span style={{fontSize:20, fontWeight:600}}>{props.item.matrix_code}</span></span>
                            <span style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5}}>Size Bottle: <span style={{fontSize:20, fontWeight:600}}></span></span>
                        </div>
                        <div style={{padding:10}}>
                            <span style={{fontWeight:600}}>Enter Account ID</span>
                            <input type='text' autoComplete='off' name='account_id' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} />
                        </div>
                        <div style={{padding:10}}>
                            <span style={{fontWeight:600}}>Enter your Password</span>
                            <input type='password' autoComplete='off' name='password' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd", padding:5}} />
                        </div>
                        <div style={{padding:10}}>
                            <button className='btn btn-sm btn-info btn-block'>Disabled</button>
                            {/* <button onClick={EncodeMatrix} className='btn btn-sm btn-info btn-block'>Submit</button> */}
                        </div>
                    </div>
                    
                </div>
            </Sidebar>
            <div onClick={()=>setVisible(true)} style={{border: "1px solid #ddd", padding: 5, borderRadius: 5}}>
                <div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{fontWeight: "bolder", flex: 1}}>{props.item.matrix_code}</span>
                        <span style={{fontSize: 10, flex: 1, textAlign: "right"}}>
                            {/* with 2 Container */}
                        </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{fontSize: 12, flex: 1}}>P. {props.item.amount}</span>
                        <span style={{fontSize: 12, textAlign: "right", flex: 1}}>{props.item.date_time}</span>
                    </div>
                </div>
                <div>
                </div>
            </div>
            
        </>
    );
}

export default MatrixCode;
