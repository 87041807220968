import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import axios from 'axios';
import ENV from './ENV';
import ConfirmPaymentEntry from './extra/admin/ConfirmPaymentEntry';
import CanvasJSReact from '@canvasjs/react-charts';
import { useHistory, useParams, Link } from 'react-router-dom/cjs/react-router-dom';
import AddPickupCenter from './component/pickupcenter/AddPickupCenter';
import CreateNewOrder from './component/pickupcenter/CreateNewOrder';

const AdminPickupCenter = ({ userInfo }) => {
    const history = useHistory()
    const { puc_id } = useParams();
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';



    const [puclist, setPUClist] = useState([])
    const [puccodelist, setPUCCodelist] = useState([])
    const [activationcodelist, setActivationCodelist] = useState([])
    const [search, setSearch] = useState('')

    const [provlist, setProvList] = useState([])
    const [citylist, setCityList] = useState([])
    const [brgylist, setBrgyList] = useState([])
    const [tabs, setTabs] = useState({ tabs: "analytics" })
    const [inventorytabs, setInventoryTabs] = useState({ tabs: "activation" })

    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;


    
    const [useraccountlist, setUserAccountlist] = useState([])
    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("user_accounts", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            setUserAccountlist([...res.data])
            // console.log(res.data)
        }).catch((err)=>{

        });
    }, [ENV, puc_id])

    const [orderlist, setOrderlist] = useState([])
    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("listorder", 1);        
        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/sample.php',
            // url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res)=>{
            setOrderlist([...res.data])
            // console.log(res.data)
        }).catch((err)=>{

        });
        
    }, [ENV, puc_id, tabs])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("puclist", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res) => {
            setPUClist([...res.data])
        }).catch((err) => {

        });
    }, [ENV, puc_id])




    useMemo(async () => {

        var formData = new FormData();
        formData.append("porvinceList", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setProvList([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV, puc_id]);


    useMemo(async () => {

        var formData = new FormData();
        formData.append("citymunList", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setCityList([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV, puc_id]);


    useMemo(async () => {

        var formData = new FormData();
        formData.append("brgyList", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setBrgyList([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV, puc_id]);


    useMemo(async () => {

        var formData = new FormData();
        formData.append("ListMatrixCode", 1);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPUCCodelist([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV, puc_id]);

    useMemo(async () => {

        var formData = new FormData();
        formData.append("ListActivationCode", 1);
        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/sample.php',
            // url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setActivationCodelist([...res.data])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV, puc_id]);

    
    const [salesgraph, setSalesGraph] = useState([])
    const [salesgraphlist, setSalesGraphList] = useState([])
    useMemo(async()=>{        
        var formData = new FormData();
        formData.append("daily_report", 1);
        formData.append("user_id", puc_id);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setSalesGraph(res.data)
        })
    }, [ENV, puc_id, tabs]);

    
    const onClickDaily = (e) => {
        setSalesGraphList(e.dataPoint.list)
    }

    const salesData = {
        animationEnabled: true,
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        title: {
            fontSize: 15,
            text: "Sales"
        },
        axisX: {
            margin: 10,
            labelPlacement: "inside",
            tickPlacement: "inside"
        },
        axisY: {
            // title: "(in USD)",
            prefix: "PHP"
        },
        data: [{
            click: onClickDaily,
            type: "column",
            yValueFormatString: "PHP##,###.##",
            dataPoints: salesgraph
        }]
    }



    const [analytics, setAnalytics] = useState([])
    useMemo(async()=>{        
        var formData = new FormData();
        formData.append("analytics", 1);
        formData.append("user_id", puc_id);
        await axios({
            method: "post",
            // url: 'http://localhost/drinkgood/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setAnalytics(res.data)
        })
    }, [ENV, puc_id, tabs]);

    const analyticsCharts = {
        animationEnabled: true,
        // title: {
        //     text: "Weekly Sales"
        // },
        // axisY: {
        //     title: "Number of Customers"
        // },
        toolTip: {
            shared: true
        },
        axisY: {
            // title: "(in USD)",
            prefix: "PHP"
        },
        data: [{
            type: "column",
            name: "Activation",
            showInLegend: true,
            dataPoints: analytics[0]?.activation
        },
        {
            type: "column",
            name: "Matrix",
            showInLegend: true,
            dataPoints: analytics[0]?.matrix
        }]
    }

    const Shipout = async (item, status, setInventoryTabs) => {
        
        // console.log({...item, status})

        // return;
        var formData = new FormData();
        formData.append("orderProcess", 1);
        formData.append("item", JSON.stringify({...item, status}));
        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/sample.php',
            // url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setOrderlist([...res.data])
        })
    }
    

    return (
        <div className="wrapper">
            <AdminHeader />
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {
                            puc_id ?
                                <div className="row">
                                    <div className='col-sm-2'>

                                    </div>
                                    <div className='col-sm-8'>
                                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                            <div>
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 15, alignItems: "center"}}>
                                                    <span onClick={()=>{
                                                        history.goBack()
                                                        setTabs(prev => ({ ...prev, tabs: "analytics" }))
                                                        }} style={{display: "flex", cursor: "pointer", justifyItems: "center", alignItems: "center", gap: 5}}>
                                                        <span className='pi pi-arrow-left'></span>
                                                        Back
                                                    </span>
                                                    <span style={{ fontSize: 35, fontWeight: "bolder" }}>Analytics</span>
                                                </div>
                                                <div style={{ borderRadius: 10, flex: 1, padding: 20, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                        <div style={{ flex: 1 }}>
                                                            <div style={{ padding: "5px 5px", flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
                                                                <span style={{ fontSize: 25, fontWeight: "bolder" }}>
                                                                    {[...useraccountlist].filter((i)=>Number(i.user_id) === Number(puc_id)).map((i)=>i.fullname).slice(0)}
                                                                </span>
                                                                <span style={{ fontSize: 18, fontWeight: "bolder" }}>
                                                                    ID: {puc_id}
                                                                </span>
                                                            </div>
                                                            <div style={{ padding: "5px 5px", flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <span>Province</span>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <span>City / Municipality</span>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <span>Barangay</span>
                                                                </div>
                                                            </div>
                                                            <div style={{ padding: "5px 5px", flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <span>Address</span>
                                                                </div>
                                                            </div>
                                                            <div style={{ flex: 1, padding: "5px 5px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
                                                                    <span style={{ fontSize: 20, fontWeight: "bolder" }}>
                                                                        PHP {Number([...salesgraph].filter((i)=>i.label === i.today).reduce((amount, item) => amount = Number(item?.y) + amount, 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        {/* {JSON.stringify(salesgraph)} */}
                                                                    </span>
                                                                    <span>Today Sales</span>
                                                                </div>
                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
                                                                    <span style={{ fontSize: 20, fontWeight: "bolder" }}>
                                                                        PHP {Number([...salesgraph].reduce((amount, item) => amount = Number(item?.y) + amount, 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                    </span>
                                                                    <span>Monthly Sales</span>
                                                                </div>
                                                            </div>
                                                            <div style={{ flex: 1, padding: "5px 5px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
                                                                    <span style={{ fontSize: 20, fontWeight: "bolder" }}>
                                                                        PHP {Number(analytics[0]?.activation.filter((i)=>i).reduce((amount, item) => amount = Number(item?.y) + amount, 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        {/* {JSON.stringify(analytics[0].activation)} */}
                                                                    </span>
                                                                    <span>Entry Activation</span>
                                                                </div>
                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
                                                                    <CreateNewOrder puc_id={puc_id} setTabs={setTabs} />
                                                                    {/* <button className='btn btn-info btn-sm'>Create New Delivery</button> */}
                                                                    {/* <button className='btn btn-info btn-sm'>Create New Delivery</button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                            {/* <div style={{ flex: 1, padding: "5px 0px" }}>
                                                                <span>Top List</span>
                                                            </div>
                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 5 }}>
                                                                <div style={{ flex: 1, border: "1px solid #ddd", padding: 5, borderRadius: 5, boxShadow: "0px 3px 4px grey" }}>1</div>
                                                                <div style={{ flex: 1, border: "1px solid #ddd", padding: 5, borderRadius: 5, boxShadow: "0px 3px 4px grey" }}>2</div>
                                                                <div style={{ flex: 1, border: "1px solid #ddd", padding: 5, borderRadius: 5, boxShadow: "0px 3px 4px grey" }}>3</div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ flex: 1 }}>
                                                    <div style={{ padding: "5px 5px" }}>
                                                        <span style={{ fontSize: 20, fontWeight: "bolder" }}>Inventory Codes</span>
                                                    </div>
                                                    <div style={{ padding: "5px 5px", flex: 1, display: "flex", flexDirection: "column", gap: 5 }}>
                                                        <span style={{ fontWeight: "normal", textDecoration: "underline" }}>Activation Codes</span>
                                                        <span style={{ fontWeight: "normal", textDecoration: "underline" }}>Matrix Codes</span>
                                                    </div>
                                                </div> */}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ borderRadius: 10, flex: 1, padding: '0px 5px', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                    <div onClick={() => setTabs(prev => ({ ...prev, tabs: "analytics" }))} style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 100, cursor: tabs.tabs === 'analytics' ? "" : "pointer", borderBottom: tabs.tabs === 'analytics' ? "1px solid rgb(81, 148, 219)" : "" }}>
                                                        <span>Analytics</span>
                                                    </div>
                                                    <div onClick={() => setTabs(prev => ({ ...prev, tabs: "sales" }))} style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 100, cursor: tabs.tabs === 'sales' ? "" : "pointer", borderBottom: tabs.tabs === 'sales' ? "1px solid rgb(81, 148, 219)" : "" }}>
                                                        <span>Sales</span>
                                                    </div>
                                                    <div onClick={() => setTabs(prev => ({ ...prev, tabs: "inventory" }))} style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 100, cursor: tabs.tabs === 'inventory' ? "" : "pointer", borderBottom: tabs.tabs === 'inventory' ? "1px solid rgb(81, 148, 219)" : "" }}>
                                                        <span>Inventory</span>
                                                    </div>
                                                    <div onClick={() => setTabs(prev => ({ ...prev, tabs: "orders" }))} style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 130, cursor: tabs.tabs === 'orders' ? "" : "pointer", borderBottom: tabs.tabs === 'orders' ? "1px solid rgb(81, 148, 219)" : "" }}>
                                                        <span>Order History</span>
                                                    </div>
                                                    <div onClick={() => setTabs(prev => ({ ...prev, tabs: "scope" }))} style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 130, cursor: tabs.tabs === 'scope' ? "" : "pointer", borderBottom: tabs.tabs === 'scope' ? "1px solid rgb(81, 148, 219)" : "" }}>
                                                        <span>Scope of Area</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                tabs.tabs === 'analytics' ?
                                                    <div>
                                                        <div style={{ borderRadius: 10, flex: 1, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                            <div style={{ padding: '15px 20px', borderBottom: "1px solid #ddd" }}>
                                                                <span style={{ fontWeight: "bolder" }}>Chart</span>
                                                            </div>
                                                            <div style={{ padding: 15, minHeight: 300, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                {/* <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 5 }}>
                                                                    <button className='btn btn-info btn-sm btn-block'>Activation</button>
                                                                    <button className='btn btn-info btn-sm btn-block'>Matrix</button>
                                                                </div> */}
                                                                <div style={{ flex: 1 }}>
                                                                    <CanvasJSChart options={analyticsCharts} containerProps={{ width: '100%', height: '300px' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                tabs.tabs === 'sales' ?
                                                    <div>
                                                        <div style={{ borderRadius: 10, flex: 1, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                            <div style={{ padding: '12px 20px', borderBottom: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <span style={{ fontWeight: "bolder" }}>Sales</span>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 5 }}>
                                                                    <button style={{ fontWeight: "normal" }} className='btn btn-info btn-sm'>Weekly</button>
                                                                    <button style={{ fontWeight: "normal" }} className='btn btn-info btn-sm'>Monthly</button>
                                                                    <button style={{ fontWeight: "normal" }} className='btn btn-info btn-sm'>Yearly</button>
                                                                </div>
                                                            </div>
                                                            <div style={{ padding: 15, minHeight: 100 }}>
                                                                <CanvasJSChart options={salesData} containerProps={{ width: '100%', height: '300px' }} />
                                                                {
                                                                    salesgraphlist?.length > 0 ?
                                                                        <table className='table table-bordered'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th colSpan={5}>
                                                                                        <div style={{flex: 1, display: "flex", flexDirection: "row", gap: 50, justifyContent: "flex-end", alignItems: "center"}}>
                                                                                            <span>Date: {[...salesgraphlist].filter((i)=>i)[0].date_used.split(' ')[0]}</span>
                                                                                            <span>Total: {[...salesgraphlist].reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Account ID</th>
                                                                                    <th>Name</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Status</th>
                                                                                    <th>Date Time</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    [...salesgraphlist].map((item, key) => {
                                                                                        return (
                                                                                            <tr key={key}>
                                                                                                <td>{item.user_id}</td>
                                                                                                <td></td>
                                                                                                <td>{item.amount}</td>
                                                                                                <td></td>
                                                                                                <td>{item.date_used}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                tabs.tabs === 'scope' ?
                                                    <div>
                                                        <div style={{ borderRadius: 10, flex: 1, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                            <div style={{ padding: '15px 20px', borderBottom: "1px solid #ddd" }}>
                                                                <span style={{ fontWeight: "bolder" }}>Scope of Members</span>
                                                            </div>
                                                            <div style={{ padding: 15, minHeight: 100 }}>
                                                                <table className='table table-bordered'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Account ID</th>
                                                                            <th>Name</th>
                                                                            <th>Contact</th>
                                                                            <th>Address</th>
                                                                            <th>Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                tabs.tabs === 'orders' ?
                                                    <div>
                                                        <div style={{ borderRadius: 10, flex: 1, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                            <div style={{ padding: '15px 20px', borderBottom: "1px solid #ddd" }}>
                                                                <span style={{ fontWeight: "bolder" }}>Orders</span>
                                                            </div>
                                                            <div style={{ padding: 5, minHeight: 100 }}>
                                                                <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 10}}>
                                                                    {
                                                                        [...orderlist].map((item, key) => {
                                                                            return (
                                                                                <div key={key} style={{borderRadius: 5, border: "1px solid #ddd", padding: 10}}>
                                                                                    <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                                                            <span>ORDER ID:</span>
                                                                                            <span style={{fontWeight: "bolder"}}>{item.order_id}</span>
                                                                                        </div>
                                                                                        <div style={{display: "flex", flexDirection: "row", gap: 5}}>
                                                                                            <button className='btn btn-info btn-sm'>Print Order Receipt</button>
                                                                                            {
                                                                                                item.status === 'completed' ?
                                                                                                // <button className='btn btn-success btn-sm'>Completed Order</button>
                                                                                                null
                                                                                                    : item.status === 'received' ?
                                                                                                    <button className='btn btn-success btn-sm' onClick={()=>Shipout(item, 'completed', setInventoryTabs)}>Complete Order</button>
                                                                                                        : item.status === 'shipped' ?
                                                                                                            <button className='btn btn-warning btn-sm'>Waiting for Receive</button>
                                                                                                            : <button className='btn btn-warning btn-sm' onClick={()=>Shipout(item, 'shipped', setInventoryTabs)}>Ship Out</button>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{flex: 1 ,display: "flex", flexDirection: "column", gap: 10}}>
                                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Types of Entry</span>
                                                                                                <span>{item.codetype}</span>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Base Unit</span>
                                                                                                <span>{item.base_unit}</span>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Default Price</span>
                                                                                                <span>{item.default_price}</span>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Qty of Code</span>
                                                                                                <span>{item.qty_codes}</span>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Qty for Each Code</span>
                                                                                                <span>{item.number_unit}</span>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Total Bottle</span>
                                                                                                <span>{Number(item.qty_codes*item.number_unit)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Source of Stock</span>
                                                                                                <span>{item.source_stock}</span>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Type</span>
                                                                                                <span>{item.type}</span>
                                                                                            </div>
                                                                                            {
                                                                                                item.type === 'Pickup' ?
                                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                    <span style={{ fontWeight: "bolder" }}>Schedule for Pickup</span>
                                                                                                    <span>0000-00-00</span>
                                                                                                </div>
                                                                                                : 
                                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                    <span style={{ fontWeight: "bolder" }}>Fee</span>
                                                                                                    <span>{item.delivery_fee}</span>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                            {
                                                                                                item.type === 'Delivery' ?
                                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                    <span style={{ fontWeight: "bolder" }}>Driver / Helper</span>
                                                                                                    <span>{item.driver_helper}</span>
                                                                                                </div>
                                                                                                : null
                                                                                            }
                                                                                            {
                                                                                                item.type === 'Delivery' ?
                                                                                                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                    <span style={{ fontWeight: "bolder" }}>Track No. / Plate Number</span>
                                                                                                    <span>00000000</span>
                                                                                                </div>
                                                                                                : null
                                                                                            }
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Order Status</span>
                                                                                                <span>
                                                                                                    {
                                                                                                        item.status === 'shipped' ?
                                                                                                            <>Ship Out</>
                                                                                                        : item.status === 'received' ?
                                                                                                            <>Received</>
                                                                                                        : item.status === 'completed' ?
                                                                                                            <>Complete</>
                                                                                                        : 
                                                                                                        <>Pending</>
                                                                                                    }
                                                                                                </span>
                                                                                                <span>{item.date_received}</span>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                                                                                <span style={{ fontWeight: "bolder" }}>Total</span>
                                                                                                <span>{Number(item.qty_codes*item.default_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <hr />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                tabs.tabs === 'inventory' ?
                                                    <div>
                                                        <div style={{ borderRadius: 10, flex: 1, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                                            <div style={{ padding: '12px 20px', borderBottom: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <span style={{ fontWeight: "bolder" }}>Inventory</span>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 5 }}>
                                                                    <button onClick={() => setInventoryTabs(prev => ({ ...prev, tabs: "activation" }))} style={{ fontWeight: "normal" }} className='btn btn-info btn-sm'>Activation</button>
                                                                    <button onClick={() => setInventoryTabs(prev => ({ ...prev, tabs: "matrix" }))} style={{ fontWeight: "normal" }} className='btn btn-info btn-sm'>Matrix</button>
                                                                </div>
                                                            </div>
                                                            <div style={{ padding: 15, minHeight: 100 }}>
                                                                {

                                                                    inventorytabs.tabs === 'activation' ?
                                                                        <table className='table table-bordered'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th colSpan={5} style={{ backgroundColor: "#ddd" }}>
                                                                                        Activation
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    {/* <th>Code</th> */}
                                                                                    <th>Amount</th>
                                                                                    <th>Type</th>
                                                                                    <th>Date Created</th>
                                                                                    <th>Created By</th>
                                                                                    {/* <th>Profile</th> */}
                                                                                    <th>Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    {/* <td>00000000</td> */}
                                                                                    <td>0.00</td>
                                                                                    <td>2 Container</td>
                                                                                    <td>0000-00-00</td>
                                                                                    <td>john doe</td>
                                                                                    {/* <td>Member / Non Member</td> */}
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        : null
                                                                }
                                                                {

                                                                    inventorytabs.tabs === 'matrix' ?
                                                                        <table className='table table-bordered'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th colSpan={5} style={{ backgroundColor: "#ddd" }}>
                                                                                        Matrix
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    {/* <th>Code</th> */}
                                                                                    <th>Amount</th>
                                                                                    <th>Type</th>
                                                                                    <th>Date Created</th>
                                                                                    <th>Created By</th>
                                                                                    {/* <th>Profile</th> */}
                                                                                    <th>Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    {/* <td>00000000</td> */}
                                                                                    <td>0.00</td>
                                                                                    <td>2 Container</td>
                                                                                    <td>0000-00-00</td>
                                                                                    <td>john doe</td>
                                                                                    {/* <td>Member / Non Member</td> */}
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>

                                    </div>
                                    <div className='col-sm-2'>

                                    </div>
                                </div>
                                :
                                <div className="row" style={{ display: 'block' }}>
                                    <div className="col-sm-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <h1 className="m-0">Pick-Up Center</h1>
                                        <div>
                                            <span>
                                                <input type='text' onChange={(e) => setSearch(e.target.value)} style={{ width: 300, height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 5 }} placeholder='Search' />
                                            </span>
                                            <AddPickupCenter />
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                            {
                                                [...puclist].filter((i) => i?.fullname.toLowerCase().includes(search.trim().toLowerCase()) || i?.user_id.includes(search)).map((item, key) => {
                                                    return (
                                                        <Link to={`/administrator/pickupcenter/${item.user_id}`}>
                                                            <div key={key} style={{ width: "100%", border: "1px solid #ddd", padding: 5, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <span>Account ID: <span style={{ fontWeight: "bolder" }}>{item.user_id}</span></span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <span>Name: <span style={{ fontWeight: "bolder" }}>{item.fullname}</span></span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <span>Province: <span style={{ fontWeight: "bolder" }}>{[...provlist].filter((i) => i.id === item.provCode).map((item) => item.provDesc).slice(0)}</span></span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <span>City / Municipality: <span style={{ fontWeight: "bolder" }}>{[...citylist].filter((i) => i.id === item.citymunCode).map((item) => item.citymunDesc).slice(0)}</span></span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <span>Barangay: <span style={{ fontWeight: "bolder" }}>{[...brgylist].filter((i) => i.id === item.brgyCode).map((item) => item.brgyDesc).slice(0)}</span></span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <span>Address: <span style={{ fontWeight: "bolder" }}>{item.address}</span></span>
                                                                    </div>
                                                                </div>
                                                                <div style={{ flex: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div style={{ flex: 1 }}>
                                                                        <div style={{ fontWeight: "bolder", fontSize: 20 }}>Inventory</div>
                                                                        <div style={{ flex: 1 }}>
                                                                            <span>Available Activation Code: {[...activationcodelist].filter((i) => i.owner_id === item.user_id && i.status === 'open').map((i) => i).length}</span>
                                                                        </div>
                                                                        <div style={{ flex: 1 }}>
                                                                            <span>Available Matrix Code: {[...puccodelist].filter((i) => i.puc_id === item.user_id).map((i) => i).length}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: 1 }}>
                                                                        <div style={{ fontWeight: "bolder", fontSize: 20 }}>Insight</div>
                                                                        <div style={{ flex: 1 }}>
                                                                            <span>Activation: {[...activationcodelist].filter((i) => i.owner_id === item.user_id && i.status === 'open').map((i) => i).length}</span>
                                                                        </div>
                                                                        <div style={{ flex: 1 }}>
                                                                            <span>Matrix: {[...puccodelist].filter((i) => i.puc_id === item.user_id).map((i) => i).length}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: 1 }}>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    )
                                                }).slice(0, 10)
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );



}

export default AdminPickupCenter;
