import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom/cjs/react-router-dom';
import ENV from '../../ENV';

const Production = () => {
    const { category } = useParams();


    const [list, setList] = useState([]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("getInventory", 1);
        await axios({
            method: "POST",
            url: ENV.DOMAIN + 'sample.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data.rates.PHP)
            // console.log(Number(3880/res.data.rates.PHP).toFixed(2))
            setList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, category]);


    return (
        <div className="row">
            <div className='col-sm-8'>
                <div style={{ display: "flex", flexDirection: "column", gap: 10, backgroundColor: "#fff", padding: 5 }}>
                    {
                        category ?
                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                {/* <div>
                                    <span>Container</span>
                                </div> */}
                                {/* <div style={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>
                                    <div style={{flex: 1, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>All</div>
                                    <div style={{flex: 1, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>Refilled</div>
                                    <div style={{flex: 1, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>Pickup</div>
                                    <div style={{flex: 1, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>Deliver</div>
                                    <div style={{flex: 1, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>Add Empty Bottle</div>
                                    <div style={{flex: 1, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>Returned Empty Bottle</div>
                                    <div style={{flex: 1, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>Sample Bottle</div>
                                </div> */}
                                <div style={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10}}>
                                    {/* <div style={{width: 150, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>New Stock</div> */}
                                    {/* <div style={{width: 150, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>Empty Bottles</div> */}
                                    <div style={{width: 150, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>
                                        Remaining Stock:    {
                                                                [...list].filter((i)=>String(i.type) === 'Refilled').reduce((amount, item) => amount = Number(item.qty) + amount, 0)-[...list].filter((i)=>String(i.type) === 'Matrix').reduce((amount, item) => amount = Number(item.qty) + amount, 0)
                                                            }
                                    </div>
                                    <div style={{width: 150, height: 50, borderRadius: 5, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", padding: 5}}>
                                        Total Matrix:   {
                                                            [...list].filter((i)=>String(i.type) === 'Matrix').reduce((amount, item) => amount = Number(item.qty) + amount, 0)
                                                        }
                                    </div>
                                </div>
                                <div>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Quantity</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                [...list].filter((i)=>i.category === category).map((item, i)=>{
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.type}</td>
                                                            <td>
                                                                {
                                                                    item.type === 'Matrix' ? 
                                                                        <span style={{color: "red"}}>{`-${item.qty}`}</span>
                                                                    : 
                                                                    item.type === 'Refilled' ? 
                                                                    <span style={{color: "green"}}>{`+${item.qty}`}</span>
                                                                    : 
                                                                    item.type === 'Deliver' ? 
                                                                    <span style={{color: "red"}}>{`-${item.qty}`}</span>
                                                                    : 
                                                                    item.type === 'Return Empty Bottle' ? 
                                                                    <span style={{color: "green"}}>{`+${item.qty}`}</span>
                                                                    : 
                                                                    item.type === 'Pickup' ? 
                                                                    <span style={{color: "red"}}>{`-${item.qty}`}</span>
                                                                    : item.qty
                                                                }
                                                            </td>
                                                            <td>{item.date_time}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                            :
                            <div>
                                {/* <div style={{ width: "100%", border: "1px solid #ddd", padding: 5, backgroundColor: "#fff" }}>
                                <div>Date & Time</div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span>Category</span>
                                        <span>Container</span>
                                        <span>Gallon</span>
                                        <span>Liter</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span>Qty</span>
                                        <span>0</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span>Type</span>
                                        <span>Trasfer</span>
                                        <span>Refilled</span>
                                        <span>Pickup</span>
                                        <span>Deliver</span>
                                        <span>Return Empty Bottle</span>
                                    </div>
                                </div>
                            </div> */}
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Catetory</th>
                                            <th>Type</th>
                                            <th>Quantity</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            [...list].map((item, i)=>{
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.category}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.qty}</td>
                                                        <td>{item.date_time}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                    }
                </div>
            </div>
            <div className='col-sm-4' style={{display: "flex", flexDirection: "column", gap: 10}}>
                <div style={{ flex: 1, width: "100%", border: "1px solid #ddd", padding: 5, backgroundColor: "#fff" }}>
                    <div style={{padding: "10px 0px"}}>
                        <span style={{fontSize: 20}}>Category</span>
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
                        <Link to="/administrator/inventory/production/Container">
                            <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", borderRadius: 5, padding: 5 }}>
                                <span>Container</span>
                                <span>0</span>
                            </div>
                        </Link>
                        <Link to="/administrator/inventory/production/Gallon">
                            <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", borderRadius: 5, padding: 5 }}>
                                <span>Gallon</span>
                                <span>0</span>
                            </div>
                        </Link>
                        <Link to="/administrator/inventory/production/Liter">
                            <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", borderRadius: 5, padding: 5 }}>
                                <span>Liter</span>
                                <span>0</span>
                            </div>
                        </Link>
                    </div>
                </div>
                {
                    category ?
                        <div style={{ flex: 1, width: "100%", border: "1px solid #ddd", padding: 5, backgroundColor: "#fff" }}>
                            <div style={{padding: "10px 0px"}}>
                                <span style={{fontSize: 20}}>Pickup Centers</span>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
                                {/* <Link to="/administrator/inventory/production/Container/pickup_center">
                                    <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", borderRadius: 5, padding: 5 }}>
                                        <span>Container</span>
                                        <span>0</span>
                                    </div>
                                </Link>
                                <Link to="/administrator/inventory/production/Gallon/pickup_center">
                                    <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", borderRadius: 5, padding: 5 }}>
                                        <span>Gallon</span>
                                        <span>0</span>
                                    </div>
                                </Link>
                                <Link to="/administrator/inventory/production/Liter/pickup_center">
                                    <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", borderRadius: 5, padding: 5 }}>
                                        <span>Liter</span>
                                        <span>0</span>
                                    </div>
                                </Link> */}
                            </div>
                        </div>
                    : null
                }
            </div>
        </div>
    );
}

export default Production;
